/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React, { Fragment } from "react";
import { useScreenSizeStore } from "stores";

type Props = {
  capabilityName?: string;
  children: React.ReactNode | string;
};

const FormHeader: React.FC<Props> = ({ children, capabilityName }: any) => {
  const { screenIs } = useScreenSizeStore();

  return (
    <Fragment>
      <div
        css={{
          margin: screenIs({
            default: "30px 40px 15px 40px",
            mobile: "25px 20px 15px 20px",
          }),
        }}
      >
        <span
          css={{
            fontWeight: 800,
            fontSize: "12px",
            lineHeight: "15px",
            letterSpacing: "0.5px",
            textTransform: "uppercase",
            color: "#228192",
          }}
        >
          {capabilityName}
        </span>
        {children}
      </div>
      <hr
        css={{
          border: "1px solid #D5D5D5",
        }}
      />
    </Fragment>
  );
};

export default FormHeader;
