/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { useEffect } from "react";
import Paragraph from "components/Paragraph";
import { useSummaryStore } from "stores";
import { numberWithCommas } from "utils/formatters";
import tenantConfig from "tenants";

type Props = {
  numberOfSearchResults: number;
  className?: string;
};

const dataPointStyle = css({ color: "#228192", fontWeight: "bold" });

const SearchSummary: React.FC<Props> = ({
  numberOfSearchResults,
  className,
}) => {
  const getSummary = useSummaryStore((state: any) => state.getSummary);
  const totalCount = useSummaryStore((state: any) => state.totalCount);
  const theme = useTheme();

  useEffect(() => {
    getSummary();
  }, [getSummary]);

  return totalCount ? (
    <Paragraph css={{ margin: 0, width: "100%" }} className={className}>
      Showing{" "}
      <span css={{ color: theme.mainColor, fontWeight: "bold" }}>
        {numberOfSearchResults} matching{" "}
        {tenantConfig.text.usePlatfrom ? "platforms" : "facilities"}
      </span>{" "}
      (of{" "}
      <span css={{ color: theme.mainColor, fontWeight: "bold" }}>
        {numberWithCommas(totalCount.facilitiesCount)}
      </span>{" "}
      in {tenantConfig.text.searchingIn})
    </Paragraph>
  ) : null;
};

export default SearchSummary;
