/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React from "react";
import { useTransition } from "react-spring";

import { animated } from "react-spring";

import { useNotificationsStore, useScreenSizeStore } from "stores";
import { NOTIFICATION_SPACING } from "stores/notifications";

import Notification from "./Notification";
import { addFlex, addSize } from "utils";
import { shallow } from "zustand/shallow";

type Props = {};

const NotificationsOverlay: React.FC<Props> = () => {
  const [notificationIds, notificationItems] = useNotificationsStore(
    (state: any) => [state.notificationIds, state.notificationItems],
    shallow
  );
  const { screenIs } = useScreenSizeStore();

  let height = 0;
  const editedItems = notificationIds.map((notificationId: any, index: any) => {
    const theItem = notificationItems[notificationId];

    const notificationYOffset = theItem.measuredHeight + NOTIFICATION_SPACING;

    return {
      ...theItem,
      y: (height += notificationYOffset) - notificationYOffset,
      index: index,
    };
  });

  const transitions = useTransition(editedItems, {
    key: (anItem: any) => anItem.id,
    from: { height: 0, opacity: 0 },
    leave: (theProps: any) => ({ height: 0, opacity: 0, y: theProps.y - 5 }),
    enter: (theProps: any) => ({
      y: theProps.y + 5,
      opacity: 1,
    }),
    update: ({ y }: any) => ({ y }),
  });

  return (
    <div
      css={{
        ...addFlex({ x: "right", y: "top" }),
        ...addSize({ width: "100%", height: "100vh" }),
        position: "fixed",
        top: 0,
        left: 0,
        overflow: "hidden",
        zIndex: 100,
        pointerEvents: "none",
      }}
    >
      <div
        css={{
          position: "relative",
          width: screenIs({ default: "290px", mobile: "calc(100% - 20px)" }),
          marginTop: screenIs({ default: "25px", mobile: "15px" }),
          marginRight: screenIs({ default: "25px", mobile: "15px" }),
          marginLeft: screenIs({ default: "0px", mobile: "15px" }),
        }}
      >
        {transitions((loopedProps, theItem) => {
          return (
            <animated.div
              css={{
                position: "absolute",
                willChange: "transform, opacity",
                width: "100%",
              }}
              style={{ ...loopedProps }}
            >
              <Notification notificationItem={theItem} />
            </animated.div>
          );
        })}
      </div>
    </div>
  );
};

export default NotificationsOverlay;
