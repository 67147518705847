/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React from "react";
import { useScreenSizeStore, useModalStore, useModalSecondStore } from "stores";
import { shallow } from "zustand/shallow";
import Paragraph from "components/Paragraph";
import BasicButton from "components/BasicButton";
import LinkUnderline from "components/LinkUnderline";
import { addPadding } from "utils";
import LoadingSpinner from "components/LoadingSpinner";

type Props = {
  submitText: string;
  isSubmitting?: boolean;
  onCancelButton?: () => void;
  submitDisabled?: boolean;
  cancelText?: string;
  notSureCloseOption?: boolean;
  disableCancelButton?: boolean;
};

const FormButtons: React.FC<Props> = ({
  submitText,
  isSubmitting = false,
  onCancelButton,
  submitDisabled,
  cancelText = "Cancel",
  disableCancelButton = false,
  notSureCloseOption = false,
}) => {
  const [closeModal] = useModalStore(
    (state: any) => [state.closeModal],
    shallow
  );
  const [closeModalSecond, modalSecondAnimationState] = useModalSecondStore(
    (state: any) => [state.closeModal, state.animationState],
    shallow
  );
  const { isMobile } = useScreenSizeStore();
  const theme = useTheme();

  function closeModalActionHandler() {
    if (onCancelButton) {
      onCancelButton();
    }

    if (modalSecondAnimationState !== "closed") {
      closeModalSecond();
    } else {
      closeModal();
    }
  }
  return (
    <div
      css={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection:
          isMobile && !disableCancelButton ? "column-reverse" : "row",
        margin: "20px 0",
      }}
    >
      {/* @ts-ignore */}
      {!notSureCloseOption && (
        <Paragraph
          type={`small`}
          css={{
            margin: isMobile ? "5px 0" : "0",
          }}
        ></Paragraph>
      )}

      {notSureCloseOption && (
        <Paragraph
          bold
          css={{
            margin: isMobile ? "10px 0 0 0" : "0",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LinkUnderline
            label={`Close form`}
            onClick={() => closeModalActionHandler()}
            css={{ ...addPadding({ vertical: "13.5px", horizontal: "15px" }) }}
            innerSpanCss={{
              width: "100%",
            }}
          >
            Not sure
          </LinkUnderline>
        </Paragraph>
      )}

      <div
        css={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {!disableCancelButton && (
          <BasicButton
            label={`Close from`}
            onClick={() => closeModalActionHandler()}
            css={{
              border: `2px solid ${theme.mainColor}`,
              height: "50px",
              width: "160px",
              borderRadius: "4px",
              marginRight: "15px",
            }}
            innerCss={{
              whiteSpace: "nowrap",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "bold",
              color: theme.mainColor,
            }}
          >
            {cancelText}
          </BasicButton>
        )}
        <BasicButton
          isSubmitButton
          disabled={submitDisabled || isSubmitting}
          label={`Submit enquiry`}
          css={{
            transition: "opacity 0.35s ease-in-out",
            opacity: submitDisabled ? 0.5 : 1,
            border: isSubmitting ? "none" : `2px solid ${theme.mainColor}`,
            backgroundColor: isSubmitting ? "#e8e8e8" : theme.mainColor,
            height: "50px",
            width: "160px",
            borderRadius: "4px",
          }}
          innerCss={{
            whiteSpace: "nowrap",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
            color: "white",
          }}
        >
          {submitText}
          <div
            css={{
              position: "absolute",
              top: "0px",
              right: "10px",
              height: "100%",
              width: "24px",
            }}
          >
            <LoadingSpinner
              isLoading={isSubmitting}
              size={24}
              color="#e8e8e8"
              backgroundColor={"#e8e8e8"}
            />
          </div>
        </BasicButton>
      </div>
    </div>
  );
};

export default FormButtons;
