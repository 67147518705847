import { create } from "zustand";
import { ModalStoreState, ModalStore, ModalAnimationState } from "./types";

const initialState: ModalStoreState = {
  animationState: "closed",
  modalType: "side",
  modalContentName: "questions",
  modalContentProps: undefined,
  savedBodyScrolltop: 0,
};

export default function makeModalStore() {
  return create<ModalStore>((set: any, get: any) => ({
    ...initialState,
    setAnimationState: (newAnimationState: ModalAnimationState) =>
      set({ animationState: newAnimationState }),
    openModal: ({ modalType, modalContentName, modalContentProps }) => {
      const { animationState } = get();
      let newAnimationState = animationState;

      switch (animationState) {
        case "closed":
          newAnimationState = "beforeOpen";
          break;
        case "closing":
          newAnimationState = "opening";
          break;
      }

      if (newAnimationState !== animationState) {
        set(() => {
          const previousScrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            0;
          requestAnimationFrame(() => {
            document.documentElement.scrollTop = 0;
            requestAnimationFrame(() => {
              requestAnimationFrame(() => {
                if (document.body !== null) {
                  document.body.className += " modalShowing";
                }
              });
            });
          });

          return {
            savedBodyScrolltop: previousScrollTop,
            animationState: newAnimationState,
            modalType,
            modalContentName,
            modalContentProps,
          };
        });
      }
    },
    closeModal: () => {
      const { animationState } = get();
      let newAnimationState = animationState;

      switch (animationState) {
        case "opened":
          newAnimationState = "beforeClose";
          break;
        case "opening":
          newAnimationState = "closing";
          break;
      }

      if (newAnimationState !== animationState) {
        set(() => {
          requestAnimationFrame(() => {
            if (document.body !== null) {
              document.body.className = document.body.className.replace(
                " modalShowing",
                ""
              );
            }
            document.documentElement.scrollTop = get().savedBodyScrolltop;
          });
          return { animationState: newAnimationState };
        });
      }
    },
  }));
}
