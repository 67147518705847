import { create } from "zustand";
import { ProgramType } from "utils/types";
import getProgramType from "services/api/getProgramType";

type ProgramTypeStoreState = {
  programType: ProgramType | undefined | null;
  isLoadingProgramType: boolean;
};

type ProgramTypeStore = ProgramTypeStoreState & {
  setProgramType: (programType: ProgramType | null) => void;
  resetProgramType: () => void;
  setIsLoadingProgramType: (newValue: boolean) => void;
  resetIsLoadingProgramType: () => void;
  fetchProgramType: (
    pathRoute: string,
    expectedRoute: string,
    programTypeId?: string | undefined | null
  ) => void;
};

const useProgramTypeStore = create<ProgramTypeStore>((set: any, get: any) => ({
  programType: undefined,
  isLoadingProgramType: false,
  setProgramType: (programType) => set(() => ({ programType })),
  resetProgramType: () => set({ programType: undefined }),
  setIsLoadingProgramType: (newValue: any) => {
    set((state: any) => ({
      ...state,
      isLoadingProgramType: newValue,
    }));
  },
  resetIsLoadingProgramType: () => set({ isLoadingProgramType: undefined }),
  fetchProgramType: async (
    pathRoute: string,
    expectedRoute: string,
    programTypeId?: string | undefined | null
  ) => {
    if (pathRoute !== expectedRoute) return;
    if (!programTypeId) return;
    if (get().isLoadingProgramType) return;
    get().resetProgramType();
    get().setIsLoadingProgramType(true);
    try {
      const programTypeResponse = await getProgramType(programTypeId);
      await get().setProgramType(programTypeResponse.data.getProgramType);

      get().setIsLoadingProgramType(false);
    } catch (error) {
      console.error(error);
    }
  },
}));

export { useProgramTypeStore };
