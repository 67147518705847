/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import React, { useEffect, useState } from "react";
import Paragraph from "components/Paragraph";
import { shallow } from "zustand/shallow";
import { useScreenSizeStore, useSearchBarStore } from "stores";
import { addFlex, addMargin, addSize } from "utils";
import DescriptionColumn from "components/DescriptionColumn";
import HomepageBanner from "components/HomepageBanner";

import arinMedalImage from "assets/images/arinMedalSmall.png";
import arinAmbitionImage from "assets/images/arinAmbitionSmall.png";
import arinFacilityImage from "assets/images/arinFacilityIcon.png";
import ncrisImage from "assets/images/ncrisLogo.svg";
import { TenantConfig } from "../../services/api/tenantConfig";
import getTenantConfig from "../../services/api/tenantConfig";
import PATHS from "../../utils/routes";
import localTenantConfig from "../../tenants";
import JobSearch from "icons/JobSearch";
import Search from "icons/Search";
import Transparency from "icons/Transparency";
import Contact from "icons/Contact";
import Calendar from "icons/Calendar";

const ncrisIsNew = true;

type Props = {};

const HomeContent: React.FC<Props> = () => {
  const { screenIs } = useScreenSizeStore();

  const [isFocused] = useSearchBarStore(
    (state: any) => [state.isFocused],
    shallow
  );

  const [tenantConfig, setTenantConfig] = useState(null as null | TenantConfig);
  useEffect(() => {
    async function fetchTenantConfig() {
      const res = await getTenantConfig();
      setTenantConfig(res.data.getTenantConfig);
    }
    fetchTenantConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      css={{
        position: "relative",
        top: 0,
        left: 0,
        width: "100%",
        ...addFlex({ x: "left", y: "top", direction: "down" }),
        opacity: isFocused ? 0.35 : 1,
        transition: "opacity .5s",
      }}
    >
      <h4
        css={{
          ...addMargin({
            top: screenIs({
              default: "80px",
              tablet: "62px",
              mobile: "30px",
            }),
            bottom: screenIs({
              default: "20px",
              tablet: "17px",
              mobile: "15px",
            }),
          }),
        }}
      >
        What does Monash Capability Finder do?
      </h4>
      <Paragraph type="intro" css={{ width: "100%" }}>
        Monash Capability Finder allows you to search and explore our extensive
        range of cutting-edge research equipment and specialised scientific
        services available at Monash University. Whether you’re a researcher,
        industry R&D, or student, this platform connects you with the resources
        you need.
      </Paragraph>
      <div
        css={{
          ...addSize({ width: "100%", height: "1px" }),
          background: screenIs({ default: "#D5D5D5", tablet: "#EEF4F6" }),
          marginTop: screenIs({
            default: "55px",
            tablet: "40px",
            mobile: "30px",
          }),
        }}
      ></div>
      <div
        css={{
          display: "flex",
          flexDirection: screenIs({
            default: "row",
            tablet: "column",
            mobile: "column",
          }),
          gap: "10px",
        }}
      >
        <DescriptionColumn
          icon={<JobSearch />}
          headline={`Search and Explore`}
          text={`A powerful search functionality that allows users to find specific equipment or services based on keywords.`}
          learnMore={{
            label: "Learn more about Arin",
            linkText: "Learn more",
            href: "/about",
            type: "hyperLink",
          }}
        />
        <DescriptionColumn
          icon={<Transparency />}
          headline={`Find`}
          text={`Detailed profiles for each capability, including descriptions, specifications, and contact information.`}
          learnMore={{
            label: "find out how you can train Arin",
            linkText: "Train Arin!",
            href: "/about",
            type: "hyperLink",
          }}
        />
        <DescriptionColumn
          icon={<Contact />}
          headline={`Contact`}
          text={`Direct communication channels to reach out to facility managers or service providers.`}
          learnMore={{
            label: "contact ARIN and add your facility",
            linkText: "List your facility on Arin!",
            href: "info@arin.org.au",
            type: "mailTo",
          }}
        />
        <DescriptionColumn
          icon={<Calendar />}
          headline={`Book`}
          text={`If applicable, a booking system for scheduling equipment usage or service appointments.`}
          learnMore={{
            label: "contact ARIN and add your facility",
            linkText: "List your facility on Arin!",
            href: "info@arin.org.au",
            type: "mailTo",
          }}
        />
      </div>

      {tenantConfig?.programs?.enabled &&
      tenantConfig?.programs?.ncrisInHomePage ? (
        <HomepageBanner
          isNew={ncrisIsNew}
          image={ncrisImage}
          headline={`NCRIS facilities are now available on Arin.`}
          text={`NCRIS is a national network of world-class research infrastructure projects that support high-quality research that will drive greater innovation in the Australian research sector.
    NCRIS supported facilities and programs help better connect researchers to high quality research outcomes, faster.`}
          learnMore={{
            label: "Learn more",
            linkText: "Learn more",
            href: PATHS.programType.replace(
              ":id",
              localTenantConfig.ncrisProgramTypeId || ""
            ),
            type: "hyperLink",
          }}
        />
      ) : null}
    </div>
  );
};

export default HomeContent;
