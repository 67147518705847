/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React from "react";
import { ProgramContact } from "../../utils/types";
import { addMargin } from "../../utils";
import IconRow from "../IconRow";
import addressIcon from "assets/images/address.png";
import roleIcon from "assets/images/role.png";
import phoneIcon from "assets/images/phone.png";
import emailIcon from "assets/images/email.png";
import websiteIcon from "assets/images/website.png";

type Props = {
  firstLine?: boolean;
  contact: ProgramContact;
};

const KeyContactRow: React.FC<Props> = ({ firstLine, contact }) => {
  return contact ? (
    <div
      css={{
        ...addMargin({ horizontal: "28px", vertical: "20px" }),
        borderTop: !firstLine ? "1px solid #D4D4D4" : "auto",
      }}
    >
      {contact.name ? (
        <div
          css={{
            fontWeight: "bold",
            fontSize: "18px",
            ...addMargin({ top: "15px " }),
          }}
        >
          {contact.name}
        </div>
      ) : null}
      <IconRow icon={roleIcon} content={contact.role} />
      <IconRow icon={addressIcon} content={contact.address} />
      <IconRow icon={emailIcon} content={contact.email} type={"email"} />
      <IconRow icon={phoneIcon} content={contact.phone} type={"phone"} />
      <IconRow icon={websiteIcon} content={contact.url} type={"url"} />
    </div>
  ) : null;
};

export default KeyContactRow;
