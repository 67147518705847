import { create } from "zustand";
import getSummary from "services/api/summary";
import { Summary } from "utils/types";

type TotalCount = Pick<
  Summary,
  "equipmentCount" | "facilitiesCount" | "servicesCount"
>;

function getSummaryTotals(summary: Summary[]): TotalCount {
  const totalCount: TotalCount = {
    equipmentCount: 0,
    facilitiesCount: 0,
    servicesCount: 0,
  };
  summary.forEach((facility) => {
    totalCount.equipmentCount += facility.equipmentCount;
    totalCount.facilitiesCount += facility.facilitiesCount;
    totalCount.servicesCount += facility.servicesCount;
  });
  return totalCount;
}

type SummaryStoreState = {
  summary: Summary[] | undefined;
  totalCount: TotalCount | null;
};

type SummaryStore = SummaryStoreState & {
  setSummary: (summary: Summary[]) => void;
  getSummary(): Promise<void>;
  setTotalCount: (totalCount: TotalCount) => void;
  fetchSummary(): Promise<void>;
};

const useSummaryStore = create<SummaryStore>((set: any, get: any) => ({
  totalCount: null,
  setTotalCount: (totalCount: TotalCount) => set(() => ({ totalCount })),
  summary: undefined,
  setSummary: (summary: Summary[]) => set(() => ({ summary })),
  getSummary: async () => {
    const summaryState = get().summary;
    if (!summaryState) {
      get().fetchSummary();
    }
  },

  fetchSummary: async () => {
    try {
      const summary = await getSummary();
      get().setTotalCount(getSummaryTotals(summary));
      get().setSummary(summary);
    } catch (error) {
      console.error(error);
    }
  },
}));

export { useSummaryStore };
