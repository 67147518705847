/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React, { useEffect, useCallback, useState, Fragment } from "react";
import { useLocation, useHistory } from "react-router-dom";
import scrollIntoView from "scroll-into-view-if-needed";
import { Equipment, Service, SearchResultResponse } from "utils/types";
import PATHS, { parseAndValidateHash, pathMatchesPattern } from "utils/routes";
import {
  useModalStore,
  useFacilityStore,
  useScreenSizeStore,
  useSearchResultsStore,
  useClickedCapabilitiesStore,
} from "stores";
import ContactCardsHolder from "components/ContactCardsHolder";
import { addMargin, addPadding, addSize } from "utils";
import { shallow } from "zustand/shallow";
import FeedBack from "components/FeedBack";
import { hasFeature } from "services/featureToggle";
import trackUserEvent from "services/api/trackUserEvent";
import { getValidationToken } from "services/validationToken";
import FadeDiv from "components/FadeDiv";
import FacilitySearchResults from "../../components/FacilitySearchResults";
import FacilityProgram from "components/FacilityProgram";
import { TenantConfig } from "../../services/api/tenantConfig";
import getTenantConfig from "../../services/api/tenantConfig";

type Props = {};
const FACILITIES_THAT_NEED_PHONE_NUMBERS_FORMATTED = ["MCRI_FCI"];

function facilityNeedsPhoneNumberFormatted(facilityTitle: string): boolean {
  return (
    FACILITIES_THAT_NEED_PHONE_NUMBERS_FORMATTED.indexOf(facilityTitle) !== -1
  );
}

const FacilityContent: React.FC<Props> = () => {
  const [openModal, closeModal, modalType, animationState] = useModalStore(
    ({ openModal, closeModal, modalType, animationState }) => [
      openModal,
      closeModal,
      modalType,
      animationState,
    ],
    shallow
  );

  const searchResults = useSearchResultsStore(
    (state: any) => state.searchResults
  );
  const { screenIs } = useScreenSizeStore();
  const history = useHistory();
  const { pathname, hash } = useLocation();

  const [
    fetchFacility,
    facility,
    isLoadingFacility,
    resetIsFacilityLoading,
    program,
    programType,
  ] = useFacilityStore(
    ({
      fetchFacility,
      facility,
      isLoadingFacility,
      resetIsFacilityLoading,
      program,
      programType,
    }) => [
      fetchFacility,
      facility,
      isLoadingFacility,
      resetIsFacilityLoading,
      program,
      programType,
    ]
  );

  const [
    addClickedService,
    addClickedEquipment,
    getClickedCapabilities,
    resetClickedCapabilities,
  ] = useClickedCapabilitiesStore(
    ({
      addClickedService,
      addClickedEquipment,
      getClickedCapabilities,
      resetClickedCapabilities,
    }) => [
      addClickedService,
      addClickedEquipment,
      getClickedCapabilities,
      resetClickedCapabilities,
    ]
  );

  const facilityId = pathname.split("/")[2];

  const [matchingSearchResult, setMatchingSearchResult] = useState(
    undefined as SearchResultResponse | undefined
  );

  useEffect(() => {
    async function trackFacilityView() {
      if (facility) {
        trackUserEvent({
          type: "FacilityView",
          institutionId: facility.institutionId || "",
          facilityId: facility.id,
          captcha: (await getValidationToken(`FacilityView`)) || "",
        });
      }
    }
    trackFacilityView();
  }, [facility]);

  useEffect(() => {
    const matchingResult = searchResults[facilityId];
    if (matchingResult) {
      setMatchingSearchResult(matchingResult);
    }
    fetchFacility(facilityId);
  }, [facilityId, fetchFacility, searchResults]);

  useEffect(() => {
    if (isLoadingFacility === false && facility === null) {
      if (pathMatchesPattern(PATHS.facility, pathname)) {
        history.push("/not-found");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility, isLoadingFacility]);

  useEffect(() => {
    // scroll to the top when opening the facility page
    scrollIntoView(document.body, {
      scrollMode: "if-needed",
      block: "start",
      behavior: "smooth",
    });
    return () => {
      resetIsFacilityLoading();
      resetClickedCapabilities();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [tenantConfig, setTenantConfig] = useState(null as null | TenantConfig);
  useEffect(() => {
    async function fetchTenantConfig() {
      const res = await getTenantConfig();
      setTenantConfig(res.data.getTenantConfig);
    }
    fetchTenantConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEquipmentOrFacilityFromHash = useCallback(
    ({
      id,
      type,
    }: {
      [key: string]: string;
    }): Service | Equipment | undefined => {
      if (facility === undefined || facility === null || type === undefined)
        return undefined;
      const activeCapability =
        type === "service" ? facility.services : facility.equipment;

      return (
        activeCapability.find((capability) => capability.id === id) || undefined
      );
    },
    [facility]
  );

  const matchingCapabilitiesIDs = useCallback((): string[] => {
    return matchingSearchResult !== undefined
      ? [
          ...matchingSearchResult.matches.equipment.map(
            (equipment) => equipment.id
          ),
          ...matchingSearchResult.matches.services.map(
            (services) => services.id
          ),
        ]
      : [];
  }, [matchingSearchResult]);

  // open the modal if the hash changes
  useEffect(() => {
    const parsedHash = parseAndValidateHash(hash);
    if (parsedHash) {
      const modalContentProps = getEquipmentOrFacilityFromHash(parsedHash);
      if (!!modalContentProps) {
        const IsMatchingSearchTerm = !!modalContentProps
          ? matchingCapabilitiesIDs().indexOf(modalContentProps.id) !== -1
          : false;

        parsedHash.type === "equipment"
          ? addClickedEquipment(
              modalContentProps as Equipment,
              IsMatchingSearchTerm
            )
          : addClickedService(
              modalContentProps as Service,
              IsMatchingSearchTerm
            );

        openModal({
          modalContentProps: {
            ...modalContentProps, // Facility or Equipment properties added as props
            detailsType: parsedHash.type,
          },
          modalContentName: "details",
          modalType: "side",
        });
      }
    } else {
      if (modalType === "side") {
        closeModal();
      }
    }
  }, [
    closeModal,
    hash,
    openModal,
    facility,
    getEquipmentOrFacilityFromHash,
    modalType,
    addClickedEquipment,
    addClickedService,
    getClickedCapabilities,
    matchingCapabilitiesIDs,
    matchingSearchResult,
  ]);

  // remove the hash from the url when the modals closed
  useEffect(() => {
    if (modalType === "side") {
      if (["closing"].includes(animationState)) {
        history.push(pathname);
      }
    }
  }, [animationState, closeModal, history, modalType, pathname]);

  return (
    <FadeDiv
      isVisible={!!facility}
      css={{
        position: "relative",
        width: "100%",
        display: "flex",
        flexDirection: screenIs({
          default: "row",
          tablet: "column",
          mobile: "column",
        }),
        justifyContent: "space-between",
        ...addPadding({ vertical: "32px", horizontal: "0" }),
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <FacilitySearchResults
          searchResult={matchingSearchResult}
          facility={facility}
        />

        {tenantConfig?.programs?.enabled &&
          tenantConfig?.programs?.inFacilityPage &&
          program &&
          programType && (
            <FacilityProgram program={program} programType={programType} />
          )}
      </div>

      <div
        css={{
          ...addMargin({
            left: screenIs({ desktop: "24px", default: "0" }),
          }),
          ...addSize({
            width: {
              min: screenIs({ desktop: "392px", default: "0" }),
              max: screenIs({ desktop: "392px", default: "100vw" }),
            },
          }),
          transform: screenIs({
            desktop: "translate(0, -125px)",
            default: undefined,
          }),
        }}
      >
        {facility && (
          <Fragment>
            <ContactCardsHolder
              contacts={facility.contacts}
              shouldFormatPhoneNumbers={facilityNeedsPhoneNumberFormatted(
                facility.title
              )}
            />
            {hasFeature("submitEnquiry") && (
              <FeedBack
                headline={`Provide feedback on this page`}
                buttonText={`Submit feedback`}
                feedbackTypeOptions={[
                  "profileIncomplete" as const,
                  "doesNotMatchSearch" as const,
                  "other" as const,
                ]}
              />
            )}
          </Fragment>
        )}
      </div>
    </FadeDiv>
  );
};
export default FacilityContent;
