/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx } from "@emotion/react";

import { getAnalyticsPublisher } from "services/analytics";

import { AllIconNames } from "icons";
import BasicIcon from "components/BasicIcon";
import { addFlex, addMargin, addPadding } from "utils";
import Paragraph from "components/Paragraph";
import HyperLink from "components/HyperLink";
import {
  userOrganisationStore,
  useFacilityStore,
  useSearchResultsStore,
  useClickedCapabilitiesStore,
} from "stores";
import { Facility, OrganisationTypes } from "utils/types";
import trackUserEvent from "services/api/trackUserEvent";
import { ORGANISATION_TYPES } from "utils/constants";
import { getValidationToken } from "services/validationToken";
import useStackedModals from "stores/modals";
import { FacilityStore } from "stores/facility";
import { logEvent } from "firebase/analytics";
import { SearchResultsStore } from "stores/searchResults";

type Props = {
  icon: AllIconNames;
  showContacts: (setShowContacts: boolean) => void;
  contactId: string;
  isHidden?: boolean;
  children: any;
};

async function trackShowContacts(
  facility: Facility | undefined | null,
  contactId: string,
  providedOrganisationType: OrganisationTypes | undefined
) {
  trackUserEvent({
    type: "ContactReveal",
    facilityId: facility?.id || ``,
    institutionId: facility?.institutionId || ``,
    userType: providedOrganisationType || ORGANISATION_TYPES.none,
    entityId: contactId,
    captcha: (await getValidationToken(`ContactReveal`)) || "",
  });

  logEvent(getAnalyticsPublisher(), `showContacts`, {
    facilityId: facility?.id || `noId`,
    providedOrganisationType:
      providedOrganisationType || `noProvidedOrganisationType`,
    hasProvidedOrganisationType: !!providedOrganisationType,
  });
}

const ContactCardRow: React.FC<Props> = ({
  children,
  icon,
  showContacts,
  contactId,
  isHidden,
}) => {
  const { openNewModal } = useStackedModals();

  const providedOrganisationType = userOrganisationStore(
    (state: any) => state.providedOrganisationType
  );

  const facility = useFacilityStore((state: FacilityStore) => state.facility);
  const searchTerm = useSearchResultsStore(
    (state: SearchResultsStore) => state.searchTerm
  );

  const [hasClickedMisMatchedCapabilities] = useClickedCapabilitiesStore(
    ({ hasClickedMisMatchedCapabilities }: any) => [
      hasClickedMisMatchedCapabilities,
    ]
  );

  return (
    <div
      css={{
        label: "contact-card-row",
        width: "100%",
        ...addFlex({ x: "edges", y: "center" }),
        ...addPadding({ vertical: "5px", right: "10px" }),
      }}
    >
      <div
        css={{
          width: "100%",
          ...addFlex({ x: "left", y: "center" }),
        }}
      >
        <div
          css={{
            ...addMargin({ right: "10px" }),
          }}
        >
          <BasicIcon name={icon} size={18} color="#3D3D3D" />
        </div>
        {typeof children === "string" ? (
          <Paragraph type="small">{children}</Paragraph>
        ) : (
          children
        )}
      </div>
      {isHidden && (
        <div
          css={{
            ...addFlex({ x: "left", y: "center" }), // flex fixes issue on IE
            flexShrink: 0,
            marginRight: "10%",
          }}
        >
          <HyperLink
            label={`open unmask journey`}
            onClick={() => {
              trackShowContacts(facility, contactId, providedOrganisationType);

              if (providedOrganisationType) {
                showContacts(true);
                return;
              }

              if (!!searchTerm && hasClickedMisMatchedCapabilities()) {
                openNewModal({
                  modalContentProps: {
                    formName: "searchTermToTag",
                    nextAction: "questions",
                  },
                  modalContentName: "sendableForm",
                  modalType: "popup",
                });
              } else {
                openNewModal({
                  modalContentProps: {},
                  modalContentName: "questions",
                  modalType: "popup",
                });
              }
            }}
          >
            Show
          </HyperLink>
        </div>
      )}
    </div>
  );
};

export default ContactCardRow;
