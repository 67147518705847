/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color?: string;
  width?: string;
};

const JobSearch: React.FC<Props> = ({ color = "#D5D5D5", width = "80px" }) => {
  return (
    <svg
      width="63"
      height="63"
      viewBox="0 0 63 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7811_4432)">
        <path
          d="M11 22C10.448 22 10 22.6716 10 23.4997C10 24.3278 10.448 25 11 25C11.552 25 12 24.3278 12 23.4997C12 22.6716 11.552 22 11 22Z"
          fill="#006DAE"
        />
        <path
          d="M45.6408 36.9408C45.4106 36.7101 45.0972 36.5803 44.7708 36.5803C44.7583 36.5827 44.2854 36.5563 43.9008 36.9408L43.0313 37.8103L40.2041 34.9836C46.443 26.2382 45.4942 14.1743 37.811 6.49151C29.1563 -2.16312 15.1472 -2.16456 6.49153 6.49151C-2.16312 15.1457 -2.16457 29.1552 6.49153 37.8108C14.1556 45.4753 26.2147 46.4587 34.9833 40.204L37.8105 43.0312L36.941 43.9007C36.4608 44.3809 36.4603 45.1605 36.941 45.6411L52.4969 61.1955C54.9006 63.6007 58.7915 63.6021 61.1957 61.196C63.6009 58.7927 63.6019 54.9014 61.1957 52.4971L45.6408 36.9408ZM39.5504 41.2912L36.916 38.6568C37.2222 38.3823 37.5216 38.1006 37.811 37.8108C38.1008 37.5215 38.3825 37.222 38.6569 36.9163L41.2914 39.5508L39.5504 41.2912ZM8.2315 36.0709C0.538152 28.378 0.537191 15.9253 8.2315 8.23147C15.9244 0.53815 28.3772 0.537189 36.071 8.23147C43.747 15.907 43.786 28.3569 36.071 36.0709C28.269 43.8738 15.8196 43.6594 8.2315 36.0709ZM44.7708 39.5508L48.2503 43.0307L43.0309 48.2501L39.5509 44.7707L44.7708 39.5508ZM59.4558 59.456C58.0138 60.8994 55.6802 60.8999 54.2373 59.456L44.7708 49.9901L49.9903 44.7707L59.4563 54.2376C60.8992 55.6791 60.9006 58.0126 59.4558 59.456Z"
          fill="#006DAE"
        />
        <path
          d="M34.0269 9.98107C27.383 3.3417 16.6195 3.3379 9.97265 9.98012C3.34245 16.6072 3.34245 27.3903 9.97265 34.0178C16.6204 40.6605 27.3778 40.661 34.026 34.0178C40.6576 27.3903 40.6581 16.6076 34.0269 9.98107ZM32.3079 32.3005C26.6103 37.9941 17.3897 37.9955 11.6907 32.3009C6.00782 26.6201 6.00782 17.3774 11.6907 11.6965C17.3855 6.00617 26.6122 6.00475 32.3089 11.6979C37.9927 17.3779 37.9922 26.6201 32.3079 32.3005Z"
          fill="#006DAE"
        />
        <path
          d="M21.7638 10C18.4599 10 15.3559 11.2853 13.0236 13.6188C11.6715 14.9707 10.6762 16.5678 10.0659 18.3668C9.84664 19.0129 10.1924 19.7149 10.839 19.9341C11.4865 20.1538 12.1877 19.8061 12.4069 19.1611C12.8941 17.7247 13.6899 16.4481 14.7716 15.3661C16.6375 13.5 19.1205 12.4721 21.7638 12.4721C22.4466 12.4721 23 11.9187 23 11.236C23 10.5533 22.4466 10 21.7638 10Z"
          fill="#006DAE"
        />
      </g>
      <defs>
        <clipPath id="clip0_7811_4432">
          <rect width="63" height="63" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default JobSearch;
