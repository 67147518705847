/** @jsxImportSource @emotion/react */
import React, { useCallback } from "react";
import FacilitiesFilter from "components/FacilitiesFilter";
import FeedBack from "components/FeedBack";
import { SearchResultResponse } from "utils/types";
import { hasFeature } from "services/featureToggle";
import localTenantConfig from "tenants";
import { ScreenSizeHelperOptions } from "stores/screenSize";

type SearchContentLeftBar = {
  hasFacilityResults: Boolean;
  editedSearchResults: any[];
  activeInstitutionIds: string[];
  searchResultIds: string[];
  searchResults: { [facilityId: string]: SearchResultResponse };
  setActiveInstitutionIds: any;
  FEEDBACK_TYPE_OPTIONS: any[];
  screenIs: <T_ValueType>(
    options: ScreenSizeHelperOptions<T_ValueType>
  ) => T_ValueType;
  isDesktop: boolean;
};

export default function Leftbar({
  hasFacilityResults,
  editedSearchResults,
  activeInstitutionIds,
  searchResultIds,
  searchResults,
  setActiveInstitutionIds,
  FEEDBACK_TYPE_OPTIONS,
  isDesktop,
  screenIs,
}: SearchContentLeftBar) {
  const toggleActiveInstitution = useCallback(
    (toggledId: string, newIsActiveValue: boolean) => {
      const isCurrentlyActive = activeInstitutionIds.includes(toggledId);

      if (isCurrentlyActive && newIsActiveValue === false) {
        setActiveInstitutionIds(
          activeInstitutionIds.filter((id: any) => id !== toggledId)
        );
      }

      if (!isCurrentlyActive && newIsActiveValue === true) {
        setActiveInstitutionIds([...activeInstitutionIds, toggledId]);
      }
    },
    [activeInstitutionIds, setActiveInstitutionIds]
  );

  return (
    <div
      style={{
        width: screenIs({
          default: "100%",
          desktop: "392px",
        }),
        marginRight: screenIs({
          default: "32px",
        }),
      }}
    >
      {process.env.REACT_APP_TENANT !== "monashUni" && hasFacilityResults && (
        <FacilitiesFilter
          activeInstitutionIds={activeInstitutionIds}
          toggleActiveInstitution={toggleActiveInstitution}
          searchResultIds={searchResultIds}
          facilityCollection={searchResults}
        ></FacilitiesFilter>
      )}

      {isDesktop &&
        hasFeature("submitEnquiry") &&
        editedSearchResults.length > 0 && (
          <FeedBack
            headline={localTenantConfig.text.feedback.heading}
            buttonText={`Submit feedback`}
            feedbackTypeOptions={FEEDBACK_TYPE_OPTIONS}
          />
        )}
    </div>
  );
}
