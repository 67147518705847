/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

import SearchContent from "./SearchContent";
import { animated, useTransition } from "react-spring";
import ResultContent from "./ResultContent";
import DisclaimerContent from "./DisclaimerContent";
import AboutArin from "./AboutArin";
import AdminReportContent from "./AdminReportContent";
import ProgramTypeContent from "./ProgramTypeContent";
import ProgramContent from "./ProgramContent";
import AutoHeightAnimator from "../AutoHeightAnimator";
import { useScreenSizeStore } from "stores";
import { getRouteInfo } from "utils/routes";
import { PositionStyle } from "utils/styles";
import { useProgramStore } from "../../stores/program";
import researchCapabilityFinderBanner from "../../assets/images/monashCapabilityFinderBanner.png";

type Props = {};

type TopBoxContentType =
  | "search"
  | "result"
  | "noSearch"
  | "hidden"
  | "about"
  | "adminReport"
  | "programType"
  | "program";

function getTopBoxContentType(pathname: string): TopBoxContentType {
  const routeInfo = getRouteInfo(pathname);
  const routeName = getRouteInfo(pathname).name || "";
  const isValidRoute = !!routeInfo.name;

  if (!isValidRoute) {
    return "hidden";
  }
  if (["index", "search"].includes(routeName)) {
    return "search";
  }
  if (routeName === "facility") {
    return "result";
  }
  if (routeName === "about") {
    return "about";
  }
  if (routeName === "adminReport") {
    return "adminReport";
  }
  if (
    ["privacy", "cookies", "facilities", "termsOfService"].includes(routeName)
  ) {
    return "noSearch";
  }
  if (["programType", "pt"].includes(routeName)) {
    return "programType";
  }
  if (routeName === "program") {
    return "program";
  }

  return "hidden";
}

const TopFocusBox: React.FC<Props> = () => {
  const location = useLocation();
  const { isMobile, isDesktop, isWideDesktop, screenIs } = useScreenSizeStore();
  const theme = useTheme();

  const transitions = useTransition(location, {
    // @ts-ignore
    key: (location) => getTopBoxContentType(location.pathname),
    from: {
      opacity: 0,
      position: "absolute" as PositionStyle,
      top: 0,
      left: 0,
    },
    enter: { opacity: 1, position: "relative" as PositionStyle },
    leave: {
      opacity: 0,
      position: "absolute" as PositionStyle,
      top: 0,
      left: 0,
    },
  });

  const { pathname } = location;
  const { program } = useProgramStore();
  const [bgImage, setBgImage] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (getTopBoxContentType(pathname) === "program") {
      setBgImage(program?.program.banner);
    }
    return () => {
      setBgImage(undefined);
    };
  }, [pathname, program]);

  return (
    <div
      css={{
        label: "TopFocusBox",
        contain: "content",
        width: "100%",
        background: theme.topFocusBoxBackground,
        borderRadius: isMobile ? "0px" : "4px",
        overflow: "hidden",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundPosition: "right",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {bgImage && isDesktop && (
        <div
          css={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "75%",
            height: "100%",
            background: `url('${bgImage}')`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top right",
            backgroundSize: screenIs({
              default: "cover",
              mobile: "auto 350px",
              tablet: "auto 350px",
            }),
          }}
        >
          <div
            css={{
              position: "absolute",
              top: 0,
              right: 0,
              background:
                "linear-gradient(90deg, #7CD1E0 30%, rgba(255, 255, 255, 0) 100%)",
              width: "100%",
              height: "100%",
              backgroundSize: "cover",
            }}
          ></div>
        </div>
      )}
      <div
        css={{
          position: "relative",
          width: "100%",
          flexGrow: 1,
          maxWidth: isWideDesktop ? "1800px" : "1440px",
        }}
      >
        <AutoHeightAnimator
          manualUpdateKey={location.pathname}
          delay={150}
          initialHeight={200}
        >
          {transitions((props, currentLocation) => {
            const topBoxContentType = getTopBoxContentType(
              currentLocation.pathname
            );
            return (
              <animated.div
                css={{ width: "100%", height: "100%" }}
                style={props}
              >
                {topBoxContentType === "search" && <SearchContent />}
                {topBoxContentType === "result" && <ResultContent />}
                {topBoxContentType === "noSearch" && <DisclaimerContent />}
                {topBoxContentType === "about" && <AboutArin />}
                {topBoxContentType === "adminReport" && <AdminReportContent />}
                {topBoxContentType === "programType" && <ProgramTypeContent />}
                {topBoxContentType === "program" && <ProgramContent />}
                {topBoxContentType === "hidden" &&
                  // height 1px so AutoHeightAnimator doesn't default to initialHeight (which is ~200px)
                  null}
              </animated.div>
            );
          })}
        </AutoHeightAnimator>
      </div>
    </div>
  );
};

export default TopFocusBox;
