import { create } from "zustand";
import Cookies from "js-cookie";
import { USER_ORGANISATION_COOKIE } from "utils/constants";
import { OrganisationTypes } from "utils/types";

// NOTE: this store might be renamed to something like userQuestionsStore
type UserOrganisationStoreState = {
  providedOrganisationType: OrganisationTypes | undefined;
  showContactInfo: boolean;
};

type UserOrganisationStore = UserOrganisationStoreState & {
  setUserOrganisationType: (
    providedOrganisationType: OrganisationTypes
  ) => void;
  setShowContactInfo: (showContactInfo: boolean) => void;
};

const userOrganisationStore = create<UserOrganisationStore>(
  (set: any, get: any) => ({
    showContactInfo: false,
    setShowContactInfo: (showContactInfo: boolean) => set({ showContactInfo }),
    providedOrganisationType:
      (Cookies.get(USER_ORGANISATION_COOKIE.key) as OrganisationTypes) ||
      undefined,
    setUserOrganisationType: (providedOrganisationType: OrganisationTypes) =>
      set(() => {
        Cookies.set(USER_ORGANISATION_COOKIE.key, providedOrganisationType, {
          expires: USER_ORGANISATION_COOKIE.expiry,
        });
        return {
          providedOrganisationType,
          showContactInfo: true,
        };
      }),
  })
);

export { userOrganisationStore };
