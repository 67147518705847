/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  useSearchResultsStore,
  useScreenSizeStore,
  useLocationStore,
} from "stores";
import SearchResult from "components/SearchResult";
import NoSearchResults from "components/NoSearchResults";
import AllResultsFilteredOut from "components/AllResultsFilteredOut";
import SearchSummary from "components/SearchSummary";
import FeedBack from "components/FeedBack";
import LoadingSpinner from "components/LoadingSpinner";
import SelectedProgram from "components/SelectedProgram";
import SortByMenu from "components/SortByMenu";
import { useTransition, animated } from "react-spring";
import { addSize, addPadding } from "utils";
import { shallow } from "zustand/shallow";
import PATHS from "utils/routes";
import scrollIntoView from "scroll-into-view-if-needed";
import { hasFeature } from "services/featureToggle";
import localTenantConfig from "tenants";
import { TenantConfig } from "../../services/api/tenantConfig";
import getTenantConfig from "../../services/api/tenantConfig";
import ArinConfusedImage from "assets/images/arinConfused.png";
import FeedBackFilter from "./feedbackFilter";

type Props = {};
export type SortSearchResultsType = "Relevance" | "Postcode";

const SEARCH_RESULT_SPACING = 20;
const NO_RESULTS_DEFAULT_HEIGHT = 134;

const FEEDBACK_TYPE_OPTIONS = [
  "notRelevant" as const,
  "insufficient" as const,
  "other" as const,
];

const SearchContent: React.FC<Props> = () => {
  const { screenIs, isWideDesktop, isDesktop, isMobile } = useScreenSizeStore();
  const [sortBy, setSortBy] = useState<SortSearchResultsType>("Relevance");
  const history = useHistory();
  let totalResultsHeight = 0;

  const [
    lastSearchFailed,
    searchResults,
    searchResultIds,
    searchResultsMeta,
    searchTerm,
    isLoadingResults,
    sortSearchResultsByDistance,
    sortSearchResultsByScore,
    selectedProgram,
  ] = useSearchResultsStore(
    (state: any) => [
      state.lastSearchFailed,
      state.searchResults,
      state.searchResultIds,
      state.searchResultsMeta,
      state.searchTerm,
      state.isLoadingResults,
      state.sortSearchResultsByDistance,
      state.sortSearchResultsByScore,
      state.selectedProgram,
    ],
    shallow
  );

  const hasFacilityResults = searchResultIds.length > 0;
  const [geoLocation, isLoadingLocation] = useLocationStore((state: any) => [
    state.geoLocation,
    state.isLoadingLocation,
  ]);

  useEffect(() => {
    if (sortBy === "Postcode" && geoLocation !== null) {
      sortSearchResultsByDistance(geoLocation);
    } else {
      sortSearchResultsByScore();
    }
  }, [
    geoLocation,
    sortBy,
    sortSearchResultsByDistance,
    sortSearchResultsByScore,
  ]);

  const { current: localContext } = useRef({
    latestIncorrectSearchTerm: "",
    latestEditedSearchResultsLength: 0,
  });

  const [activeInstitutionIds, setActiveInstitutionIds] = useState<string[]>(
    []
  );

  const editedSearchResults = searchResultIds
    .filter((loopedResultId: any) =>
      activeInstitutionIds.includes(
        searchResults[loopedResultId].institution.id
      )
    )
    .map((resultId: any, index: any) => {
      const itemMetaInfo = searchResultsMeta[resultId];
      const theItem = searchResults[resultId];
      const notificationYOffset =
        itemMetaInfo.measuredHeight + SEARCH_RESULT_SPACING;

      return {
        ...theItem,
        ...itemMetaInfo,
        y: (totalResultsHeight += notificationYOffset) - notificationYOffset,
        index: index,
      };
    });

  const transitions = useTransition(editedSearchResults, {
    key: (anItem: any) => anItem.facility.id,
    from: { opacity: 0 },
    enter: (theProps: any) => ({
      y: theProps.y + 5,
      opacity: 1,
    }),
    leave: (theProps: any) => ({ opacity: 0, y: theProps.y + 5 }),
    update: ({ y }: any) => ({ y }),
  });

  const [allResultsFilteredOut, setAllResultsFilteredOut] = useState(false);

  const [tenantConfig, setTenantConfig] = useState(null as null | TenantConfig);
  useEffect(() => {
    async function fetchTenantConfig() {
      const res = await getTenantConfig();
      setTenantConfig(res.data.getTenantConfig);
    }
    fetchTenantConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // scroll to the top when opening the search page
    scrollIntoView(document.body, {
      scrollMode: "if-needed",
      block: "start",
      behavior: "smooth",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActiveInstitutionIds(
      searchResultIds.map(
        (searchResultId: any) => searchResults[searchResultId].institution.id
      )
    );
  }, [searchResultIds, searchResults]);

  useEffect(() => {
    setAllResultsFilteredOut(!activeInstitutionIds.length && !isLoadingResults);
  }, [activeInstitutionIds, isLoadingResults]);

  useEffect(() => {
    if (lastSearchFailed) {
      history.push(PATHS.index);
    }
  }, [history, lastSearchFailed]);

  const showNoResults =
    !hasFacilityResults &&
    !isLoadingResults &&
    (!tenantConfig?.programs?.enabled ||
      !tenantConfig?.programs?.inSearchResults ||
      !selectedProgram);

  if (showNoResults) {
    localContext.latestIncorrectSearchTerm = searchTerm || "";
  }

  if (!allResultsFilteredOut && editedSearchResults.length !== 0) {
    localContext.latestEditedSearchResultsLength =
      editedSearchResults.length || 0;
  }

  return (
    <div
      key="searchContent"
      css={{
        label: "SearchContent-outerContainer",
        position: "relative",
        width: "100%",
      }}
    >
      <div
        css={{
          position: "relative",
          width: "100%",
          opacity: 1,
          transition: "opacity 0.3s ease-in-out",
          display: "flex",
          flexDirection: screenIs({
            default: "column",
            desktop:
              process.env.REACT_APP_TENANT === "monashUni"
                ? "row-reverse"
                : "row",
          }),
          justifyContent: "space-between",
          padding: "32px 0",
        }}
      >
        {hasFacilityResults ? (
          <FeedBackFilter
            hasFacilityResults={hasFacilityResults}
            editedSearchResults={editedSearchResults}
            activeInstitutionIds={activeInstitutionIds}
            searchResultIds={searchResultIds}
            searchResults={searchResults}
            isDesktop={isDesktop}
            screenIs={screenIs}
            setActiveInstitutionIds={setActiveInstitutionIds}
            FEEDBACK_TYPE_OPTIONS={FEEDBACK_TYPE_OPTIONS}
          />
        ) : null}

        <div
          css={{
            position: "relative",
            width: screenIs({
              default: "100%",
              desktop: isWideDesktop
                ? "1155px"
                : editedSearchResults.length > 0
                ? "800px"
                : "85%",
            }),
            margin: editedSearchResults.length == 0 ? "0 auto" : "0",
          }}
        >
          {tenantConfig?.programs?.enabled &&
          tenantConfig?.programs?.inSearchResults ? (
            <SelectedProgram programMeta={selectedProgram} />
          ) : null}
          {editedSearchResults.length > 0 ? (
            <div
              css={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <SearchSummary
                numberOfSearchResults={
                  localContext.latestEditedSearchResultsLength
                }
                aria-hidden={!!editedSearchResults.length ? "true" : "false"}
                css={{
                  opacity: !!editedSearchResults.length ? 1 : 0,
                  transition: "opacity 0.15s ease-in-out",
                }}
              />
              {hasFeature("sortOptions") &&
                process.env.REACT_APP_TENANT === "arin" &&
                hasFacilityResults && (
                  <SortByMenu
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                    isLoadingLocation={isLoadingLocation}
                  />
                )}
            </div>
          ) : null}
          <div
            css={{
              position: "relative",
              ...addSize({
                width: "100%",
                height: {
                  min: `${totalResultsHeight || NO_RESULTS_DEFAULT_HEIGHT}px`,
                },
              }),
              ...addPadding({ top: "20px" }),
            }}
          >
            {!hasFacilityResults && !isLoadingResults && (
              <div
                css={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  marginBottom: "50px",
                  justifyContent: isMobile ? "center" : "auto",
                  alignItems: isMobile ? "center" : "auto",
                }}
              >
                <img
                  src={ArinConfusedImage}
                  alt=""
                  css={{
                    width: isMobile ? "90px" : "220px",
                    display: "flex",
                    margin: isMobile ? "10px 0" : "auto",
                  }}
                />
                <NoSearchResults
                  searchTerm={searchTerm}
                  showImage={true}
                  showEnquiryButton={true}
                />
              </div>
            )}

            {hasFacilityResults && (
              <div
                css={{
                  position: "relative",
                  ...addSize({ width: "100%", height: "0px" }),
                }}
              >
                <div
                  css={{
                    position: "absolute",
                    top: "0",
                    left: "50%",
                    width: "100%",
                    transform: "translateX(-50%)",
                    opacity: allResultsFilteredOut ? 1 : 0,
                    transition: "opacity 0.2s ease-in-out",
                  }}
                >
                  <AllResultsFilteredOut />
                </div>
              </div>
            )}

            {transitions((loopedProps, theItem) => {
              const {
                institution,
                facility,
                matches,
                program,
                relationshipType,
              } = theItem;
              return (
                <animated.div
                  css={{
                    position: "absolute",
                    willChange: "transform, opacity",
                    width: "100%",
                  }}
                  style={{ ...loopedProps }}
                >
                  <SearchResult
                    institutionId={institution.id}
                    key={facility.id}
                    institutionName={institution.name || ""}
                    position={theItem.index.toString()}
                    matches={matches}
                    id={facility.id}
                    title={facility.title}
                    location={facility.location}
                    program={
                      tenantConfig?.programs?.enabled &&
                      tenantConfig?.programs?.inSearchResults &&
                      relationshipType === "funded"
                        ? program
                        : null
                    }
                  ></SearchResult>
                </animated.div>
              );
            })}
          </div>
          {!isDesktop &&
            hasFeature("submitEnquiry") &&
            editedSearchResults.length > 0 && (
              <FeedBack
                headline={localTenantConfig.text.feedback.heading}
                buttonText={`Submit feedback`}
                feedbackTypeOptions={FEEDBACK_TYPE_OPTIONS}
                css={{
                  marginTop: "20px",
                }}
              />
            )}
        </div>
      </div>

      {/* <div
        aria-hidden={showNoResults ? "true" : "false"}
        css={{
          label: "absolute-NoResults-holder",
          position: "absolute",
          top: "0",
          left: "50%",
          width: "100%",
          height: "calc(100vh - 390px)",
          pointerEvents: showNoResults ? "all" : "none",
          transform: "translateX(-50%)",
          opacity: showNoResults ? 1 : 0,
          transition: "opacity 0.3s ease-in-out",
        }}
      >
        <div
          css={{
            position: "relative",
            ...addPadding({ vertical: "32px" }),
            height: "100%",
            width: "100%",
          }}
        >
          <NoSearchResults searchTerm={searchTerm} showImage={true} />
        </div>
      </div> */}
      <LoadingSpinner
        height="calc(100vh - 390px)"
        isLoading={isLoadingResults}
      />
    </div>
  );
};

export default SearchContent;
