/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";

import React from "react";
import { animated } from "react-spring";
import { addFlex, addSize } from "utils";
import { useScreenSizeStore } from "stores";

type Props = { visibleAmount: any; children: any };

const PopupModal: React.FC<Props> = React.memo(
  ({ visibleAmount, children }) => {
    const { screenIs } = useScreenSizeStore();
    return (
      <animated.div
        css={{
          label: "popupModal-outer",
          contain: "content",
          ...addSize({
            size: "100%",
            width: { max: "100vw" },
            height: { max: `${window.innerHeight}px` },
          }),
          ...addFlex({ x: "center", y: "center", direction: "down" }),
          position: "absolute",
          right: 0,
          top: 0,
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
          pointerEvents: "none",
        }}
      >
        <animated.div
          css={{
            label: "popup-modal-inner-content",
            ...addFlex({ x: "center", y: "top", direction: "down" }),
            ...addSize({
              width: { max: "calc(100vw - 30px)" },
              height: { max: `${window.innerHeight - 30}px` },
            }),
            position: "relative",
            pointerEvents: "all",
            borderRadius: screenIs({ default: "10px", mobile: "8px" }),
            backgroundColor: "white",
          }}
          style={{
            transform: visibleAmount?.to(
              (visibleAmount: number) =>
                `translateY(${visibleAmount * -20 + 20}px)`
            ),
            opacity: visibleAmount,
            boxShadow: "0px 1px 15px rgba(0,0,0,0.15)",
          }}
        >
          {children}
        </animated.div>
      </animated.div>
    );
  }
);

export default PopupModal;
