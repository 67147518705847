import { ITenant } from "../tenant";
import singascopeTheme from "./theme";
import singascopeImage from "assets/images/singascopeImage.png";
import SingascopeLogoIcon from "icons/imageIcons/SingascopeLogo";

const singascope: ITenant = {
  name: "SingaScope",
  tenantId: "singascope",
  theme: singascopeTheme,
  images: {
    loadingOverlay: singascopeImage,
    logo: SingascopeLogoIcon,
    quickQuestions: singascopeImage,
    notFound: singascopeImage,
  },
  text: {
    documentTitle: "SingaScope | Search for Microscopy resources in Singapore",
    searchingIn: "Singapore",
    loadingFacilityNames: [
      "A*STAR Microscopy Platform",
      "Singapore Microscopy and Bioimage Analysis",
      "NUS Centre for Bioimaging Sciences",
      "NTU Optical Bio-imaging Centre",
      "SingHealth Advanced Bioimaging",
    ],
    futureFacilityNames: [],
    usePlatfrom: true,
    ncrisPrograms: false,
    feedback: {
      title: "help us improve",
      heading: "Provide feedback on this page",
    },
  },
  userTypes: {
    PUBLIC: "Public",
    COMMERCIAL: "Commercial/Industry",
    ACADEMIC: "Academic",
    PRIVATE: "Individual",
  },
  externalNavigation: {
    label: "SingaScope home",
    link: "https://singascope.sg",
  },
};

export default singascope;
