/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";

import React from "react";
import { animated } from "react-spring";
import { useScreenSizeStore } from "stores";
import Div100vh from "react-div-100vh";
import { createStyleSheet } from "utils/styles";

type Props = { visibleAmount: any; children: any };

const SideModal: React.FC<Props> = React.memo(({ visibleAmount, children }) => {
  const { isDesktop } = useScreenSizeStore();

  const contentWidth = isDesktop
    ? Math.min(500, window.innerWidth)
    : window.innerWidth;
  const contentWidthWithPadding = contentWidth + 50;

  return (
    <animated.div
      css={styles.container}
      style={{
        width: contentWidthWithPadding + "px",
        transform: visibleAmount.to(
          (visibleAmount: number) =>
            `translate3d(${
              visibleAmount * -contentWidth + contentWidthWithPadding
            }px, ${0}px, ${0}px)`
        ),
      }}
    >
      <Div100vh
        css={styles.innerContent}
        style={{ height: "100rvh", width: `${contentWidth}px` }}
      >
        {children}
      </Div100vh>
    </animated.div>
  );
});

const styles = createStyleSheet({
  container: {
    label: "sideModal-outer",
    contain: "content",
    display: "flex",
    position: "absolute",
    right: 0,
    top: 0,
    background: "#1D1D1D",
    boxShadow: "0 0 15px rgba(0,0,0,0.25)",
    height: "100vh",
  },
  innerContent: {
    label: "side-modal-inner-content",
    contain: "content",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "absolute",
    left: 0,
    top: 0,
  },
});

export default SideModal;
