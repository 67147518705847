import { gql } from "apollo-boost";
import Api from "services/api";
import { OrganisationTypes } from "utils/types";

export type UserEventTypes =
  | "EquipmentClick"
  | "FacilityView"
  | "ContactReveal"
  | "ServiceClick"
  | "Enquiry";

type SubmitTrackUserEventResponse = {
  trackUserEvent: boolean;
};

type SubmitTrackUserEventType = {
  type: UserEventTypes;
  institutionId: string;
  facilityId: string;
  captcha: string;
  userType?: OrganisationTypes;
  entityId?: string;
};

const trackUserEvent = ({
  type,
  institutionId,
  facilityId,
  userType,
  entityId,
  captcha,
}: SubmitTrackUserEventType) =>
  Api.mutate<SubmitTrackUserEventResponse>({
    variables: {
      userEvent: {
        type,
        institutionId,
        facilityId,
        userType,
        entityId,
      },
      captcha,
    },
    mutation: gql`
      mutation trackUserEvent($userEvent: InputUserEvent!, $captcha: String!) {
        trackUserEvent(userEvent: $userEvent, captcha: $captcha)
      }
    `,
  }).then((result: any) => result.data);

export default trackUserEvent;
