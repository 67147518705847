/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React, { Fragment, useState } from "react";
import keyBy from "lodash.keyby";
import Paragraph from "components/Paragraph";
import CaptchaNotice from "components/CaptchaNotice";
import FormButtons from "components/SendableForm/FormButtons";
import { Facility, Service, Equipment } from "utils/types";
import CheckBox from "components/CheckBox";
import { limitCapabilitiesShown } from "./utils/limitCapabilitiesShown";
import suggestCapabilityTag, {
  SubmissionCapabilityType,
} from "services/api/suggestCapabilityTag";
import { getValidationToken } from "services/validationToken";

type Props = {
  searchTerm: string | undefined;
  facility: Facility | null | undefined;
  missMatchedCapabilities: {
    clickedEquipment: (Service | Equipment)[];
    clickedServices: (Service | Equipment)[];
  };
  onSubmit: () => void;
  onCancelButton: () => void;
};

type ActiveCapabilities = {
  [id: string]: {
    id: string;
    value: boolean;
    type: SubmissionCapabilityType;
  };
};

function submitSearchTermToTag(
  activeCapabilities: ActiveCapabilities,
  value: string,
  captcha: string
) {
  const addTagsTo = Object.values(activeCapabilities)
    .filter(({ value }) => value === true)
    .map(({ type, id }) => ({ type, id }));

  if (addTagsTo.length) {
    suggestCapabilityTag(addTagsTo, [value], captcha);
  }
}

const MultiTerm: React.FC<Props> = ({
  searchTerm,
  facility,
  missMatchedCapabilities,
  onSubmit,
  onCancelButton,
}) => {
  const { clickedEquipment, clickedServices } = missMatchedCapabilities;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formattedCapabilities = [
    ...clickedEquipment.map((equipment: Service | Equipment) => ({
      ...{ type: "EQUIPMENT" as const },
      ...equipment,
    })),
    ...clickedServices.map((service: Service | Equipment) => ({
      ...{ type: "SERVICE" as const },
      ...service,
    })),
  ];

  const capabilitiesToShow = limitCapabilitiesShown(formattedCapabilities);

  const [activeCapabilities, setActiveCapabilities] =
    useState<ActiveCapabilities>(
      // @ts-ignore
      keyBy(
        capabilitiesToShow.map(({ id, type }: any) => ({
          type,
          id,
          value: false,
        })),
        (result: any) => result.id
      )
    );

  function updateActiveCapabilities(idToUpdate: string, value: boolean) {
    setActiveCapabilities(
      // @ts-ignore
      keyBy(
        capabilitiesToShow.map(({ id, type }: any) => ({
          id,
          type,
          value: idToUpdate === id ? value : activeCapabilities[id].value,
        })),
        (result: any) => result.id
      )
    );
  }

  return (
    <Fragment>
      <div css={{ display: "flex", flexDirection: "column" }}>
        {capabilitiesToShow.map((capability) => (
          <div>
            <CheckBox
              css={{
                margin: "10px 0",
              }}
              label={capability.title}
              id={capability.id}
              name={`capability.title ${capability.title}`}
              value={activeCapabilities[capability.id].value}
              whiteSpace={"normal"}
              tickBoxAlignment={"top"}
              onChange={() => {
                updateActiveCapabilities(
                  capability.id,
                  !activeCapabilities[capability.id].value
                );
              }}
            />
          </div>
        ))}
      </div>
      <Paragraph
        css={{
          marginTop: "30px",
          marginBottom: "40px",
        }}
      >
        By associating this equipment with your search term, it will{" "}
        <strong>
          {`improve ${
            facility ? `${facility?.title} ` : ""
          }listing and help others find it more easily in the
            future.`}
        </strong>
      </Paragraph>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setIsSubmitting(true);
          const validationToken =
            (await getValidationToken(`submitContactForm`)) || "";
          submitSearchTermToTag(
            activeCapabilities,
            searchTerm || "",
            validationToken
          );
          onSubmit();
          setIsSubmitting(false);
        }}
      >
        <CaptchaNotice />
        <FormButtons
          submitText={"Next"}
          notSureCloseOption
          disableCancelButton
          submitDisabled={false}
          onCancelButton={onCancelButton}
          isSubmitting={isSubmitting}
        />
      </form>
    </Fragment>
  );
};

export default MultiTerm;
