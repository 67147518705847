import { ITenant } from "../tenant";
import monashUniTheme from "./theme";
import monashUniImage from "assets/images/monashUniImage.png";
import MonashUniLogoIcon from "icons/imageIcons/MonashUniLogo";

const monashUni: ITenant = {
  name: "Monash Research Capability Finder",
  tenantId: "monashUni", // Need tenant ID added to the back end
  theme: monashUniTheme,
  images: {
    loadingOverlay: monashUniImage,
    logo: MonashUniLogoIcon,
    quickQuestions: monashUniImage,
  },
  text: {
    documentTitle: "Monash Research Capability Finder",
    searchingIn: "Monash University",
    loadingFacilityNames: [
      "Monash Antibody Technologies Facility",
      "Monash Biomedical Imaging",
      "Monash Centre for Additive Manufacturing",
      "Monash Histology Platform",
      "Ramaciotti Centre for Cryo-Electron Microscopy",
    ],
    futureFacilityNames: [],
    usePlatfrom: false,
    ncrisPrograms: false,
    feedback: {
      titleTenant: "help improve MONASH RESEARCH CAPABILITY FINDER",
      title: "help us improve",
      heading: "Provide feedback on this page",
    },
  },
  userTypes: {
    PUBLIC: "Public",
    COMMERCIAL: "Commercial/Industry",
    ACADEMIC: "Academic",
    PRIVATE: "Individual",
  },
  externalNavigation: {
    label: "Back to Monash Research Infrastructure page",
    link: "https://www.monash.edu/research/infrastructure",
  },
};

export default monashUni;
