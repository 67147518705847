/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";

import React, {
  useLayoutEffect,
  useEffect,
  Fragment,
  useCallback,
} from "react";
import { useSpring, animated } from "react-spring";
import FocusLock from "react-focus-lock";

import { useModalStore } from "stores";

import {
  ModalAnimationState,
  DetailsProps,
  QuestionsProps,
  MobileNavigationProps,
  SendableFormProps,
  LearnAboutVerifyingProps,
  FormSentMessageProps,
} from "stores/utils/storeMakers/makeModalStore/types";
import SendableForm from "components/SendableForm";
import SideModal from "./SideModal";
import PopupModal from "./PopupModal";
import QuickQuestion from "components/QuickQuestion";
import MobileNavigation from "components/MobileNavigation";
import ItemDetails from "components/ItemDetails";
import { shallow } from "zustand/shallow";
import LearnAboutVerifying from "components/LearnAboutVerifying";
import { createStyleSheet } from "utils/styles";
import FormSentMessage from "components/SendableForm/FormSentMessage";

type Props = { useModalStoreProp: typeof useModalStore };

const visibleAmountByAnimationStateSwitch: {
  [K in ModalAnimationState]: number;
} = {
  beforeOpen: 0,
  opening: 1,
  opened: 1,
  beforeClose: 1,
  closing: 0,
  closed: 0,
};

const springConfig = { mass: 3, tension: 1100, friction: 85 };

const ESCAPE_KEY_CODE = 27;

const modelContent = {
  questions: QuickQuestion,
  details: ItemDetails,
  sendableForm: SendableForm,
  mobileNavigation: MobileNavigation,
};

const Modal: React.FC<Props> = React.memo(({ useModalStoreProp }) => {
  const [
    animationState,
    setAnimationState,
    closeModal,
    openModal,
    modalType,
    modalContentName,
    modalContentProps,
    savedBodyScrolltop,
  ] = useModalStoreProp(
    (state: any) => [
      state.animationState,
      state.setAnimationState,
      state.closeModal,
      state.openModal,
      state.modalType,
      state.modalContentName,
      state.modalContentProps,
      state.savedBodyScrolltop,
    ],
    shallow
  );

  const handleUserKeyPress = useCallback(
    (event: KeyboardEvent) => {
      // if escape key was pressed
      if (event.keyCode === ESCAPE_KEY_CODE) {
        closeModal();
      }
    },
    [closeModal]
  );

  useEffect(() => {
    window.addEventListener("keyup", handleUserKeyPress);

    return () => {
      window.removeEventListener("keyup", handleUserKeyPress);
    };
  });

  const getModalContent = useCallback(() => {
    switch (modalContentName) {
      case "questions":
        return <QuickQuestion {...(modalContentProps as QuestionsProps)} />;
      case "details":
        return <ItemDetails {...(modalContentProps as DetailsProps)} />;
      case "sendableForm":
        return (
          <SendableForm
            {...(modalContentProps as SendableFormProps)}
            closeModal={closeModal}
          />
        );
      case "mobileNavigation":
        return (
          <MobileNavigation {...(modalContentProps as MobileNavigationProps)} />
        );
      case "learnAboutVerifying":
        return (
          <LearnAboutVerifying
            {...(modalContentProps as LearnAboutVerifyingProps)}
          />
        );
      case "formSentMessage":
        return (
          <FormSentMessage {...(modalContentProps as FormSentMessageProps)} />
        );
    }
  }, [closeModal, modalContentName, modalContentProps]);

  const innerContent = getModalContent();

  const springProps = useSpring({
    // @ts-ignore
    visibleAmount: visibleAmountByAnimationStateSwitch[animationState],
    config: springConfig,
    onRest: (props) => {
      if (props.value.visibleAmount > 0.9) setAnimationState("opened");
      if (props.value.visibleAmount < 0.1) setAnimationState("closed");
    },
  });

  useEffect(() => {
    if (animationState === "beforeOpen") setAnimationState("opening");
    if (animationState === "beforeClose") setAnimationState("closing");
  }, [animationState, setAnimationState]);

  const dimmedBackdropSpringStyle = {
    opacity: springProps.visibleAmount,
    cursor: modalType !== "popup" ? "pointer" : undefined,
  };

  const shouldShowFocusTrap = ["opened", "beforeClose", "closing"].includes(
    animationState
  );
  const topOffset = "0";
  return (
    <div
      css={{
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: topOffset,
        overflow: "hidden",
      }}
      style={{
        top:
          modalType === "sideWithTopBar"
            ? "45px"
            : ["closing", "beforeClose", "closed"].includes(animationState)
            ? savedBodyScrolltop
            : 0,
        pointerEvents: shouldShowFocusTrap ? undefined : "none",
      }}
    >
      <div
        css={{
          width: "100vw",
          height: "100vh",
          position: "absolute",
          left: 0,
          top: topOffset,
          overflow: "hidden",
        }}
      >
        {shouldShowFocusTrap && (
          <Fragment>
            <FocusLock returnFocus>
              {modalType !== "popup" ? (
                <animated.button
                  css={styles.dimmedBackdrop}
                  aria-label="cancel modal"
                  onClick={() => {
                    closeModal();
                  }}
                  style={dimmedBackdropSpringStyle}
                ></animated.button>
              ) : (
                <animated.div
                  css={styles.dimmedBackdrop}
                  style={dimmedBackdropSpringStyle}
                ></animated.div>
              )}
              {(modalType === "side" || modalType === "sideWithTopBar") && (
                <SideModal visibleAmount={springProps.visibleAmount}>
                  {innerContent}
                </SideModal>
              )}
              {modalType === "popup" && (
                <PopupModal visibleAmount={springProps.visibleAmount}>
                  {innerContent}
                </PopupModal>
              )}
            </FocusLock>
          </Fragment>
        )}
        {!shouldShowFocusTrap && animationState !== "closed" && (
          <Fragment>
            <animated.button
              css={styles.dimmedBackdrop}
              aria-label="cancel modal"
              style={dimmedBackdropSpringStyle}
            ></animated.button>
            {(modalType === "side" || modalType === "sideWithTopBar") && (
              <SideModal visibleAmount={springProps.visibleAmount}>
                {innerContent}
              </SideModal>
            )}
            {modalType === "popup" && (
              <PopupModal visibleAmount={springProps.visibleAmount}>
                {innerContent}
              </PopupModal>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
});

const styles = createStyleSheet({
  dimmedBackdrop: {
    label: "dimmed-backdrop",
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.25)",
    position: "absolute",
    top: 0,
    left: 0,
    overflow: "hidden",
  },
});

export default Modal;
