import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Global, css } from "@emotion/react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import debounce from "lodash.debounce";
import createdHistory from "utils/history";
import tenantConfig from "tenants";
import TagManager from "react-gtm-module";
import { useScreenSizeStore } from "stores";

if (process.env.REACT_APP_ENV !== "production") {
  const metaTag = document.createElement("meta");
  metaTag.name = "robots";
  metaTag.content = "noindex, nofollow";
  document.head.append(metaTag);
}

document.title = tenantConfig.text.documentTitle;
const fontsLink = document.createElement("link");
fontsLink.href = tenantConfig.theme.fonts.googleFontsLink;
fontsLink.rel = "stylesheet";
fontsLink.id = "Injected";
document.head.append(fontsLink);

const description = document.createElement("meta");
description.name = "description";
const favicon: any = document.getElementById("favicon");

const { REACT_APP_TENANT, REACT_APP_ENV, REACT_APP_GTM_CODE } = process.env;
if (REACT_APP_ENV !== "development" && REACT_APP_GTM_CODE) {
  TagManager.initialize({
    gtmId: REACT_APP_GTM_CODE,
  });
}
switch (REACT_APP_TENANT) {
  case "arin":
    favicon.href = "./favicon-arin.ico";
    description.content =
      "Arin search portal helps users find research equipment from facilities across Australia. Arin connects researchers with the right person to book equipment.";

    break;

  case "singascope":
    favicon.href = "./favicon-singascope.ico";
    description.content =
      "SingaScope search tool is a searchable online database to enable scientists to easily browse and search to identify and locate specialised microscopy capabilities. SingaScope brings together Singapore’s leading microscopy platforms to enable access to excellent instrumentation and expertise. ";
    break;

  case "monashUni":
    favicon.href = "./favicon-monash.ico";
    description.content = "Monash";
    break;
}
document.head.append(description);

// Add screen resize event listener
window.addEventListener(
  "resize",
  debounce(
    () => {
      useScreenSizeStore.getState().updateValues();
    },
    250,
    { leading: false, maxWait: 3000, trailing: true }
  )
);

setTimeout(() => {
  useScreenSizeStore.getState().updateValues();
}, 500);

// Sentry reporting
Sentry.init({
  dsn: "https://7d2347575af24055aae210dcdde93635@o479031.ingest.sentry.io/5548519",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  ignoreErrors: ["ResizeObserver loop limit exceeded"],
});

ReactDOM.render(
  // @ts-ignore
  <Router history={createdHistory}>
    <Global
      styles={css`
        body,
        input,
        h1,
        h2,
        h3,
        h4,
        h5 {
          font-family: ${tenantConfig.theme.fonts.globalFontFamily};
        }
      `}
    />
    <App />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
