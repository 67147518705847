/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { Fragment } from "react";

import ArinLogoIcon from "../../icons/imageIcons/ArinLogo";
import SingascopeLogoIcon from "../../icons/imageIcons/SingascopeLogo";
import MonashUniLogoIcon from "../../icons/imageIcons/MonashUniLogo";
import researchCapabilityFinder from "assets/images/researchCapabilityFinder.png";
import BasicButton from "../BasicButton";
import { useScreenSizeStore, useSearchResultsStore } from "stores";
import { useLocation } from "react-router-dom";
import BasicIcon from "components/BasicIcon";
import PATHS, { pathMatchesPattern } from "utils/routes";
import { addFlex, addPadding, addSize } from "utils";
import TopNavigation from "components/TopNavigation";
import { hasFeature } from "services/featureToggle";
import tenantConfig from "tenants";

type Props = {};

const Header: React.FC<Props> = React.memo(() => {
  const { screenIs, isMobile } = useScreenSizeStore();
  const { pathname } = useLocation();
  const searchTerm = useSearchResultsStore((state: any) => state.searchTerm);
  const theme = useTheme();

  return (
    <div
      css={{
        label: "Header",
        ...addFlex({ x: "edges", y: "center" }),
        ...addSize({
          width: "100%",
          height: screenIs({ default: "60px", mobile: "45px" }),
        }),
      }}
    >
      <div
        css={{
          justifyContent: "space-between",
          paddingLeft: isMobile ? 10 : 0,
          ...addFlex({ x: "edges", y: "center" }),
        }}
      >
        {process.env.REACT_APP_TENANT === "arin" && !isMobile && (
          <BasicButton
            label="go to home page"
            automationId="logo"
            linkTo="/"
            css={{
              label: "logo-holder",
              ...addFlex({ x: "center", y: "center" }),
              height: "60px",
            }}
            innerCss={{
              label: "logo-holder-inner",
              width: "73px",
              ...addPadding({
                default: "8px",
                right: screenIs({ default: "20px", mobile: "10px" }),
                left: screenIs({ default: "0px", mobile: "15px" }),
              }),
            }}
          >
            <ArinLogoIcon />
          </BasicButton>
        )}

        {process.env.REACT_APP_TENANT === "singascope" && (
          <SingascopeLogoIcon isMobile={isMobile} />
        )}
        {process.env.REACT_APP_TENANT === "monashUni" && (
          <MonashUniLogoIcon isMobile={isMobile} />
        )}
        {pathMatchesPattern(PATHS.facility, pathname) && searchTerm && (
          <Fragment>
            <div
              css={{
                ...addSize({
                  width: "1px",
                  height: screenIs({ default: "40px", mobile: "30px" }),
                }),
                backgroundColor: "#EEF4F6",
              }}
            ></div>
            <BasicButton
              label="go to home page"
              linkTo={`/search#${encodeURI(searchTerm)}`}
              css={{
                label: "back-to-search",
                height: screenIs({ default: "36px", mobile: "25px" }),
                border: `1px solid ${theme.mainColor}`,
                borderRadius: "4px",
                marginLeft: screenIs({ default: "20px", mobile: "10px" }),
              }}
              innerCss={{
                label: "back-to-search-inner",
                ...addFlex({ x: "center", y: "center" }),
                ...addPadding({
                  left: screenIs({ default: "6px", mobile: "4px" }),
                  right: "8px",
                }),
                height: screenIs({ default: "36px", mobile: "25px" }),
                whiteSpace: "nowrap",
                fontWeight: "bold",
                fontSize: screenIs({ default: "14px", mobile: "10px" }),
                lineHeight: "20px",
                color: theme.mainColor,
              }}
            >
              <BasicIcon
                name="BackArrow"
                color={theme.mainColor}
                size={screenIs({ default: 20, mobile: 16 })}
                css={{
                  marginRight: screenIs({ default: "4px", mobile: "3px" }),
                }}
              />
              {isMobile ? "Back" : "Back to all results"}
            </BasicButton>
            {searchTerm && (
              <div
                css={{
                  ...addFlex({ x: "center", y: "center" }),
                  ...addPadding({
                    horizontal: screenIs({ default: "10px", mobile: "4px" }),
                  }),
                  background: theme.softBackground,
                  borderRadius: "4px",
                  height: screenIs({ default: "36px", mobile: "25px" }),
                  marginLeft: screenIs({ default: "15px", mobile: "7px" }),
                  // desktop paragraph (small)
                  fontWeight: "bold",
                  fontSize: screenIs({ default: "14px", mobile: "10px" }),
                  color: "#3D3D3D",
                }}
              >
                {`“${searchTerm}”`}
              </div>
            )}
          </Fragment>
        )}
      </div>
      {process.env.REACT_APP_TENANT === "monashUni" && (
        <img
          css={{
            width: isMobile ? 200 : "auto",
          }}
          src={researchCapabilityFinder}
          alt="research capability finder logo"
        />
      )}
      {hasFeature("facilitiesDirectory") &&
        hasFeature("aboutPage") &&
        hasFeature("ncrisPrograms") && <TopNavigation />}
    </div>
  );
});

export default Header;
