import { gql } from "apollo-boost";
import Api from "services/api";
import { escapeQuotesAndBacklashes } from "utils/formatters";

type GetAutoComplete = {
  autocomplete: string[];
};

const getAutoComplete = (searchTerms: string) =>
  Api.query<GetAutoComplete>({
    query: gql`
      {
        autocomplete(input: "${escapeQuotesAndBacklashes(searchTerms)}")
      }
    `,
  }).then((result: any) => result.data);

export default getAutoComplete;
