import { create } from "zustand";
import createdHistory from "utils/history";
import { PATHS } from "utils/routes";
import { sanitiseSearchTerm } from "utils/formatters";
import { useSearchResultsStore } from "./searchResults";

type MeasuredSearchBar = {
  width: number;
  left: number;
  bottom: number;
};

type SearchBarStoreState = {
  isFocused: boolean;
  searchText: string;
  searchHash: string;
  timeLastEdited: number; // timestamp (Date.now())
  timeLastEditedHash: number; // timestamp
  timeLastPressedSearch: number; // timestamp
  timeLastCleared: number; // timestamp
  timeLastFocused: number; // timestamp
  submittableHintText: string;
  measuredSearchBar: MeasuredSearchBar;
};

type SearchBarStore = SearchBarStoreState & {
  updateSearchText: (newSearchText: string) => void;
  updateSearchHash: (newSearchHash: string) => void;
  updateSearchFocus: (newIsFocused: boolean) => void;
  submitSearch: () => void;
  setMeasuredSearchBar: (newValues: Partial<MeasuredSearchBar>) => void;
};

const useSearchBarStore = create<SearchBarStore>((set: any, get: any) => ({
  isFocused: false,
  searchText: "",
  searchHash: "",
  timeLastEdited: 0,
  timeLastEditedHash: 0,
  timeLastPressedSearch: 0,
  timeLastCleared: 0,
  timeLastFocused: 0,
  submittableHintText: "",
  measuredSearchBar: {
    width: 400,
    left: 0,
    bottom: 0,
  },
  updateSearchText: (newSearchText: string) => {
    const isClearing = sanitiseSearchTerm(newSearchText) === "";
    const currentTime = Date.now();
    set((state: any) => ({
      ...state,
      searchText: newSearchText,
      timeLastEdited: currentTime,
      timeLastCleared: isClearing ? currentTime : state.timeLastCleared,
    }));
  },
  updateSearchHash: (newSearchHash: string) => {
    const isClearing = sanitiseSearchTerm(newSearchHash) === "";
    const currentTime = Date.now();
    set((state: any) => ({
      ...state,
      searchText: newSearchHash,
      searchHash: newSearchHash,
      timeLastEdited: currentTime,
      timeLastEditedHash: currentTime,
      timeLastCleared: isClearing ? currentTime : state.timeLastCleared,
    }));
  },
  updateSearchFocus: (newIsFocused: boolean) => {
    set((state: any) => ({
      ...state,
      isFocused: newIsFocused,
      timeLastFocused: newIsFocused ? Date.now() : state.timeLastFocused,
    }));
  },
  submitSearch: () => {
    const searchText = sanitiseSearchTerm(get().searchText);

    set((state: any) => ({
      ...state,
      searchText,
    }));

    if (searchText === "") return;
    useSearchResultsStore.getState().fetchSearchResults(searchText);
    createdHistory.push(`${PATHS.search}#${encodeURI(searchText)}`);
    set((state: any) => ({
      ...state,
      timeLastPressedSearch: Date.now(),
    }));
  },
  setMeasuredSearchBar: (newValues) => {
    set((state: any) => ({
      measuredSearchBar: { ...state.measuredSearchBar, ...newValues },
    }));
  },
}));

export { useSearchBarStore };
