/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { Fragment, useEffect } from "react";
import {
  useScreenSizeStore,
  userOrganisationStore,
  useSearchResultsStore,
  useClickedCapabilitiesStore,
} from "stores";
import ContactCard from "components/ContactCard";
import NoContacts from "components/NoContacts";
import { Contact } from "utils/types";
import { addFlex, addPadding } from "utils";
import BasicButton from "components/BasicButton";
import { hasFeature } from "services/featureToggle";
import useStackedModals from "stores/modals";

type Props = {
  contacts: Contact[] | undefined;
  shouldFormatPhoneNumbers: boolean;
};

const ContactCardsHolder: React.FC<Props> = ({
  contacts,
  shouldFormatPhoneNumbers,
}) => {
  const theme = useTheme();
  const setShowContactInfo = userOrganisationStore(
    (state: any) => state.setShowContactInfo
  );

  const { openNewModal } = useStackedModals();

  const searchTerm = useSearchResultsStore((state: any) => state.searchTerm);

  const [hasClickedMisMatchedCapabilities] = useClickedCapabilitiesStore(
    ({ hasClickedMisMatchedCapabilities }: any) => [
      hasClickedMisMatchedCapabilities,
    ]
  );

  useEffect(() => {
    return () => setShowContactInfo(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { screenIs } = useScreenSizeStore();
  return (
    <div
      css={{
        background: "#fff",
        marginBottom: "20px",
        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.08)",
        borderRadius: "4px",
      }}
    >
      <div
        css={{
          ...addPadding(
            screenIs({
              default: { horizontal: "32px", vertical: "28px" },
              tablet: { horizontal: "25px", vertical: "20px" },
              mobile: { horizontal: "20px", vertical: "15px" },
            })
          ),
        }}
      >
        <h2>Key contacts</h2>
      </div>
      <div
        css={{
          label: "key-contacts-cards-holder",
          ...addFlex({ x: "left", y: "top", wrap: "wrap" }),
          ...addPadding(
            screenIs({
              default: { horizontal: "32px", bottom: "28px" },
              tablet: { horizontal: "25px", bottom: "20px" },
              mobile: { horizontal: "20px", bottom: "15px" },
            })
          ),
          borderTop: "1px solid #D5D5D5",
        }}
      >
        {!contacts ? (
          <NoContacts />
        ) : (
          <Fragment>
            {contacts.map((contact, index) => {
              const isOnFirstColumn = screenIs({
                default: true,
                tablet: index % 2 === 0,
              });

              return (
                <ContactCard
                  key={contact.id + index}
                  contact={contact}
                  isOnFirstColumn={isOnFirstColumn}
                  shouldFormatPhoneNumbers={shouldFormatPhoneNumbers}
                />
              );
            })}
            {hasFeature("submitEnquiry") && (
              <BasicButton
                css={{
                  border: `1px solid ${theme.mainColor}`,
                  backgroundColor: theme.mainColor,
                  height: "50px",
                  borderRadius: "8px",
                  width: "100%",
                  marginTop: screenIs({
                    default: "28px",
                    tablet: "20px",
                    mobile: "15px",
                  }),
                }}
                innerCss={{
                  whiteSpace: "nowrap",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: "bold",
                  color: "white",
                  paddingLeft: "6px",
                  paddingRight: "8px",
                  fontSize: "18px",
                  lineHeight: "20px",
                }}
                onClick={() => {
                  if (!!searchTerm && hasClickedMisMatchedCapabilities()) {
                    openNewModal({
                      modalContentProps: {
                        formName: "searchTermToTag",
                        nextAction: "submitEnquiry",
                      },
                      modalContentName: "sendableForm",
                      modalType: "popup",
                    });
                  } else {
                    openNewModal({
                      modalContentProps: {
                        formName: "submitEnquiry",
                      },
                      modalContentName: "sendableForm",
                      modalType: "popup",
                    });
                  }
                }}
                label={`Enquiry`}
              >
                Submit an enquiry
              </BasicButton>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default ContactCardsHolder;
