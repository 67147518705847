/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { Fragment, ReactElement } from "react";
import { useScreenSizeStore } from "stores/screenSize";
import { addFlex, addMargin } from "utils";
import Paragraph from "components/Paragraph";
import HyperLink from "components/HyperLink";

const blueCircleStyle = css`
  width: 100px;
  height: 100px;
  border: 3px solid #006dae;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #006dae;
  font-size: 24px;
  font-weight: bold;
  background-color: transparent;
`;

type LearnMoreLink = {
  linkText: string;
  href: string;
  label: string;
  type: "hyperLink" | "mailTo";
};

type Props = {
  headline: string;
  text: string;
  learnMore: LearnMoreLink;
  icon: ReactElement;
};

const DescriptionColumn: React.FC<Props> = ({
  headline,
  text,
  learnMore,
  icon,
}) => {
  const { screenIs, isDesktop } = useScreenSizeStore();

  const LEARN_MORE_LINK_STYLES = isDesktop
    ? css({
        position: "absolute",
        bottom: 0,
        fontWeight: "bold",
      })
    : css({
        fontWeight: "bold",
      });

  return (
    <Fragment>
      <div
        css={{
          width: "100%",
          position: "relative",
          height: screenIs({
            desktop: "auto",
            tablet: "auto",
            mobile: "auto",
          }),
          ...addFlex(
            screenIs({
              default: { x: "center", y: "top", direction: "down" },
              mobile: { x: "left", y: "top", direction: "down" },
            })
          ),
          ...addMargin({
            top: screenIs({
              default: "70px",
              tablet: "26px",
              mobile: "12px",
            }),
            bottom: screenIs({
              default: "100px",
              tablet: "26px",
              mobile: "4px",
            }),
          }),
        }}
      >
        <div
          css={{
            ...addFlex({ x: "left", y: "top" }),
            margin: screenIs({ default: "0", tablet: "0 12.5px" }),
            alignSelf: "start",
          }}
        >
          {icon}
        </div>
        <div
          css={{
            ...addFlex({ x: "left", y: "top", direction: "down" }),
            width: "100%",
            paddingLeft: "10px",
          }}
        >
          <Paragraph
            bold
            css={{
              marginBottom: screenIs({ default: "12px", mobile: "10px" }),
              marginTop: "25px",
              color: "#006DAE",
            }}
          >
            {headline}
          </Paragraph>
          <Paragraph
            css={{
              marginBottom: 0,
              width: "100%",
              height: "auto",
              paddingBottom: "50px",
            }}
          >
            {text}
          </Paragraph>
        </div>
      </div>
    </Fragment>
  );
};

export default DescriptionColumn;
