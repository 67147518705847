/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { Fragment, useRef, useEffect } from "react";
import { useScreenSizeStore } from "stores/screenSize";
import ResultTitle from "components/ResultTitle";
import BasicIcon from "components/BasicIcon";
import TooltipWrapper from "components/TooltipWrapper";
import MatchesSummaryCount from "./MatchesSummaryCount";
import { SearchResultFacilityCapability, Program } from "utils/types";
import { addFlex, addPadding, addMargin, stripHtml } from "utils";
import { useThrottledResizeObserver } from "hooks";
import { useSearchResultsStore } from "stores";
import PATHS from "../../utils/routes";

type Props = {
  matches?: SearchResultFacilityCapability;
  title: string;
  location: string;
  id: string;
  institutionName: string;
  institutionId: string;
  position: string;
  program: Program | null;
};

const NO_MATCHES = {
  services: [],
  equipment: [],
};

const SearchResult: React.FC<Props> = React.memo(
  ({
    matches = NO_MATCHES,
    institutionName,
    institutionId,
    id,
    title,
    location,
    position,
    program,
  }) => {
    const theme = useTheme();
    const hasSearchResults: boolean =
      !!matches.equipment.length || !!matches.services.length;

    const updateSearchResultMeta = useSearchResultsStore(
      (state: any) => state.updateSearchResultMeta
    );

    const { screenIs } = useScreenSizeStore();
    const isMobile = useScreenSizeStore((state: any) => state.isMobile);

    const contentHolderRef = useRef<HTMLDivElement>(null);

    const { height } = useThrottledResizeObserver({
      ref: contentHolderRef,
      wait: 500,
    });

    useEffect(() => {
      updateSearchResultMeta({
        resultId: id,
        newMeta: { measuredHeight: height },
      });
    }, [height, id, updateSearchResultMeta]);

    const sidePadding = screenIs({
      default: "32px",
      tablet: "25px",
      mobile: "20px",
    });

    return (
      <div
        ref={contentHolderRef}
        css={{
          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.08)",
          borderRadius: "4px",
          backgroundColor: "white",
        }}
        data-automation={`search-result-${position}`}
        id={institutionId}
      >
        <div
          css={{
            borderBottom: hasSearchResults ? "1px solid #EEF4F6" : "none",
            ...addPadding({
              horizontal: sidePadding,
              top: screenIs({
                desktop: "30px",
                default: "20px",
              }),
              bottom: screenIs({
                desktop: location ? "30px" : "23px",
                default: location ? "20px" : "13px",
              }),
            }),
          }}
        >
          <ResultTitle
            linkTo={`/facility/${id}`}
            title={title}
            institutionName={institutionName}
          />
          {location && (
            <div
              css={{
                display: "flex",
                fontSize: "14px",
                color: "#3D3D3D",
              }}
            >
              <BasicIcon
                name={`LocationPin`}
                color={theme.locationPinColor}
                size={11}
                height={16}
                css={{ margin: "0 5px 0 0" }}
              />
              <div>{location}</div>
            </div>
          )}
          {program?.name && (
            <div
              css={{
                display: "flex",
                fontSize: "14px",
                color: "#3D3D3D",
                ...addMargin({ top: "15px" }),
              }}
            >
              <BasicIcon
                name={`Blocks`}
                color={theme.mainColor}
                size={15}
                height={18}
                css={{ margin: "0 5px 0 0" }}
              />
              <div
                css={{
                  display: "flex",
                  fontWeight: "bold",
                  color: theme.mainColor,
                }}
              >
                <a
                  href={PATHS.program.replace(":id", program.id)}
                  css={{
                    color: theme.mainColor,
                    textDecoration: "none",
                  }}
                >
                  {program.name}
                </a>

                {program?.summary && (
                  <TooltipWrapper
                    texts={[stripHtml(program.summary)]}
                    title={program.name}
                    facilityId={id}
                    noWrap={false}
                  >
                    {/* @ts-ignore */}
                    <BasicIcon
                      name={`Info`}
                      color={`#949494`}
                      size={13}
                      height={13}
                      css={{
                        margin: "0 10px",
                        display: "inline-block",
                      }}
                    />
                  </TooltipWrapper>
                )}
              </div>
            </div>
          )}
        </div>
        {hasSearchResults && (
          <div
            css={{
              ...addFlex(
                screenIs({
                  default: { x: "left", y: "center", direction: "right" },
                  mobile: { x: "left", y: "center", direction: "down" },
                })
              ),
              ...addPadding({
                horizontal: sidePadding,
                vertical: screenIs({
                  default: "28px",
                  tablet: "20px",
                  mobile: "15px",
                }),
              }),
              fontSize: "14px",
            }}
          >
            <strong
              css={{
                lineHeight: isMobile ? 0 : 1,
                ...addPadding(
                  screenIs({
                    mobile: { vertical: "15px", left: "0", right: "12px" },
                    default: { vertical: "0", left: "5px", right: "23px" },
                  })
                ),
              }}
            >
              Matches:
            </strong>
            <div
              css={{
                ...addFlex({ x: "left", y: "center" }),
              }}
            >
              {!!matches.equipment.length && (
                <MatchesSummaryCount
                  title="Equipment"
                  count={matches.equipment.length}
                  facilityId={id}
                  tooltipTexts={matches.equipment.map(
                    (equipmentItem) => equipmentItem.title
                  )}
                ></MatchesSummaryCount>
              )}
              {!!matches.equipment.length && !!matches.services.length && (
                <div
                  css={{
                    width: "1px",
                    height: "30px",
                    marginLeft: "22px",
                    marginRight: "16px",
                    background: "#EEF4F6",
                  }}
                ></div>
              )}
              {!!matches.services.length && (
                <Fragment>
                  <MatchesSummaryCount
                    title={screenIs({
                      default: "Services offered",
                      mobile: "Services",
                    })}
                    count={matches.services.length}
                    tooltipTexts={matches.services.map(
                      (serviceItem) => serviceItem.title
                    )}
                    facilityId={id}
                  ></MatchesSummaryCount>
                </Fragment>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default SearchResult;
