import { gql } from "apollo-boost";

import Api from "services/api";
import { ProgramMeta } from "utils/types";

type GetProgramResponse = {
  getProgram: ProgramMeta;
};

const getProgram = (programId?: string | undefined | null) =>
  Api.query<GetProgramResponse>({
    query: gql`
      {
        getProgram(programId: "${programId}") {
          program {
            id
            name
            logo
            summary
            keyContacts {
              name
              role
              address
              phone
              email
              url
            }
            moreInfo {
              name
              address
              phone
              email
              url
            }
            attributionTitle
            attributionBody
            description {
              title
              body
              tags {
                label
              }
            }
            banner
          }
          programType {
            id
            externalId
            name
            logo
          }
          facilities {
            facility {
              id
              title
              description
            }
            institution {
              id
              location
              name
            }
          }
        }
      }
    `,
  }).then((result: any) => result);

export default getProgram;
