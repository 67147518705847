/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { Fragment, useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import { useScreenSizeStore, useModalStore } from "stores";
import LinkUnderline from "components/LinkUnderline";
import PATHS from "utils/routes";
import BasicIcon from "components/BasicIcon";
import NavigatorDivider from "./NavigatorDivider";
import HamburgerMenu from "components/HamburgerMenu";
import { NavigationLink } from "utils/types";
import scrollIntoView from "scroll-into-view-if-needed";
import getTenantConfig, { TenantConfig } from "services/api/tenantConfig";

import tenantConfig from "tenants";

type Props = {};

const ICON_SIZE = 22 as const;
const ICON_COLOUR = "#228192" as const;

const ICON_CSS = css({
  width: `${ICON_SIZE}px`,
  marginTop: "5px",
});
const LINK_CSS = css({
  fontWeight: "bold",
  margin: "0 10px",
});

const NAVIGATION_ROUTES: NavigationLink[] = [
  {
    icon: "Info",
    linkTo: PATHS.about,
    linkText: "About",
    label: "Learn more about Arin",
  },
  {
    icon: "Blocks",
    linkTo: PATHS.programType.replace(
      ":id",
      tenantConfig.ncrisProgramTypeId || ""
    ),
    linkText: "NCRIS programs",
    label: "See all NCRIS programs",
  },
  {
    icon: "Building",
    linkTo: PATHS.facilities,
    linkText: `${
      tenantConfig.text.usePlatfrom ? "Platform" : "Facility"
    } directory`,
    label: "See all facilities",
  },
];

const TopNavigation: React.FC<Props> = () => {
  const { isMobile } = useScreenSizeStore();
  const theme = useTheme();

  const [openModal, closeModal] = useModalStore(
    ({ openModal, closeModal }) => [openModal, closeModal],
    shallow
  );

  const [tenantConfig, setTenantConfig] = useState(null as null | TenantConfig);
  useEffect(() => {
    async function fetchTenantConfig() {
      const res = await getTenantConfig();
      setTenantConfig(res.data.getTenantConfig);
    }
    fetchTenantConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isMobile) {
      closeModal();
    }
  }, [closeModal, isMobile]);
  return (
    <nav
      css={{
        display: "flex",
      }}
    >
      {isMobile ? (
        <HamburgerMenu
          onClick={(isMenuOpen) => {
            scrollIntoView(document.body, {
              scrollMode: "if-needed",
              block: "start",
              behavior: "auto",
            });

            if (isMenuOpen) {
              openModal({
                modalContentProps: {
                  navigationLinks: NAVIGATION_ROUTES,
                },
                modalContentName: "mobileNavigation",
                modalType: "sideWithTopBar",
              });
            } else {
              closeModal();
            }
          }}
        />
      ) : (
        <Fragment>
          {process.env.REACT_APP_TENANT === "arin" && (
            <Fragment>
              <div
                css={{
                  display: "flex",
                }}
              >
                <BasicIcon
                  name={NAVIGATION_ROUTES[0].icon}
                  size={ICON_SIZE}
                  color={theme.mainColor}
                  css={ICON_CSS}
                />
                <LinkUnderline
                  linkTo={NAVIGATION_ROUTES[0].linkTo}
                  label={NAVIGATION_ROUTES[0].label}
                  css={LINK_CSS}
                  automationId={`top-nav-about`}
                >
                  {NAVIGATION_ROUTES[0].linkText}
                </LinkUnderline>
              </div>
              <NavigatorDivider />
            </Fragment>
          )}

          {process.env.REACT_APP_TENANT === "arin" &&
            tenantConfig?.programs?.enabled &&
            tenantConfig?.programs?.ncrisInTopNav && (
              <Fragment>
                <div
                  css={{
                    display: "flex",
                  }}
                >
                  <BasicIcon
                    name={NAVIGATION_ROUTES[1].icon}
                    size={ICON_SIZE}
                    color={theme.mainColor}
                    css={ICON_CSS}
                  />
                  <LinkUnderline
                    linkTo={NAVIGATION_ROUTES[1].linkTo}
                    label={NAVIGATION_ROUTES[1].label}
                    css={LINK_CSS}
                    automationId={`top-nav-ncris`}
                  >
                    {NAVIGATION_ROUTES[1].linkText}
                  </LinkUnderline>
                </div>
                <NavigatorDivider />
              </Fragment>
            )}

          <div
            css={{
              display: "flex",
            }}
          >
            <BasicIcon
              name={NAVIGATION_ROUTES[2].icon}
              size={ICON_SIZE}
              color={theme.mainColor}
              css={ICON_CSS}
            />
            <LinkUnderline
              linkTo={NAVIGATION_ROUTES[2].linkTo}
              label={NAVIGATION_ROUTES[2].label}
              css={LINK_CSS}
              automationId={`top-nav-facility-directory`}
            >
              {NAVIGATION_ROUTES[2].linkText}
            </LinkUnderline>
          </div>
        </Fragment>
      )}
    </nav>
  );
};

export default TopNavigation;
