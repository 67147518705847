/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React, { useEffect, useRef } from "react";
import { shallow } from "zustand/shallow";
import { useSearchBarStore, useScreenSizeStore } from "stores";
import { addFlex, addPadding, addMargin } from "utils";

import BasicButton from "components/BasicButton";
import Paragraph from "components/Paragraph";

type Props = {
  activeItem: string;
  hintsScrollRef?: React.RefObject<HTMLDivElement>;
  autoComplete: string[] | null;
};

function truncateAutoCompleteForScreenSize(
  trends: string[],
  maxTerms: number
): string[] {
  return trends.slice(0, maxTerms);
}

const AutocompleteSearches: React.FC<Props> = React.memo(
  ({ autoComplete, activeItem, hintsScrollRef }) => {
    const { screenIs, isMobile } = useScreenSizeStore();

    const activeItemRef = useRef<HTMLLIElement>(null);

    useEffect(() => {
      if (activeItemRef.current !== null && activeItem !== "") {
        activeItemRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
      }
    }, [activeItem, hintsScrollRef]);

    const [updateSearchText, submitSearch] = useSearchBarStore(
      (state: any) => [state.updateSearchText, state.submitSearch],
      shallow
    );

    const ROW_HEIGHT = screenIs({
      desktop: "80px",
      tablet: "65px",
      mobile: "47px",
    });

    const ROW_MARGIN = addMargin({
      horizontal: screenIs({ desktop: "30px", tablet: "25px", mobile: "20px" }),
    });

    const MAX_NUMBERS_OF_TERMS_TO_SHOW = screenIs({
      desktop: 15,
      tablet: 10,
      mobile: 8,
    });

    return (
      <ul
        id="TopFocusBox-SearchContent-popupContainer-currentListbox"
        css={{
          ...addFlex({
            x: "left",
            y: "top",
            direction: "down",
          }),
          ...addMargin({ default: "0" }),
          ...addPadding({ default: "0" }),
          background: "#fff",
          boxSizing: "border-box",
          boxShadow: "0 2px 16px rgba(253, 128, 84, 0.16)",
          borderRadius: "8px",
          display: "flex",
          width: "100%",
          overflow: "hidden",
          listStyleType: "none",
        }}
      >
        {autoComplete &&
          truncateAutoCompleteForScreenSize(
            autoComplete,
            MAX_NUMBERS_OF_TERMS_TO_SHOW
          ).map((term) => {
            const isActiveItem = term === activeItem;

            return (
              <li
                key={term}
                ref={isActiveItem ? activeItemRef : undefined}
                role="option"
                aria-selected={isActiveItem ? "true" : "false"}
                id={`TopFocusBox-SearchContent-popupContainer-currentListbox-item-${term}`}
                css={{
                  width: "100%",
                  height: ROW_HEIGHT,
                }}
              >
                <BasicButton
                  label={term}
                  onClick={() => {
                    updateSearchText(term);
                    submitSearch();
                  }}
                  allowFocus={false}
                  css={{
                    position: "relative",
                    width: "100%",
                    height: ROW_HEIGHT,
                    background: isActiveItem
                      ? "rgba(235, 255, 251, 1)"
                      : "rgba(255, 255, 255, 0)",
                    ...addFlex({ x: "left", y: "center" }),
                  }}
                  innerCss={{
                    width: "100%",
                    height: ROW_HEIGHT,
                    ...addFlex({ x: "left", y: "center" }),
                    flexGrow: 1,
                  }}
                >
                  <div
                    css={{
                      ...ROW_MARGIN,
                      width: "100%",
                      height: ROW_HEIGHT,
                      ...addFlex({ x: "left", y: "center" }),
                      borderBottom: "1px solid #D5D5D5",
                      textAlign: "left",
                    }}
                  >
                    {isMobile ? (
                      <Paragraph bold>{term}</Paragraph>
                    ) : (
                      <h3>{term}</h3>
                    )}
                  </div>
                </BasicButton>
              </li>
            );
          })}
      </ul>
    );
  }
);

export default AutocompleteSearches;
