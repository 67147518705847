/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { ThemeProvider } from "@emotion/react";

import tenantConfig from "tenants";

import React, { useRef, useCallback, useState, useEffect } from "react";

import { useLocation, useHistory } from "react-router-dom";
import { useTransition, animated } from "react-spring";

import { initialiseAnalytics } from "services/analytics";
import {
  useScreenSizeStore,
  useSearchResultsStore,
  useModalStore,
  useSearchBarStore,
  useModalSecondStore,
} from "stores";

import { isValidRoute, getRouteInfo, PATHS } from "utils/routes";
import { addFlex, addPadding, addSize } from "utils";
import { MAIN_PADDING } from "utils/constants";

import {
  HomeContent,
  SearchContent,
  FacilityContent,
  PrivacyContent,
  CookiesContent,
  PageNotFoundContent,
  AllFacilitiesContent,
  AboutContent,
  ProgramTypeContent,
  ProgramContent,
} from "screens";

import Modal from "components/Modal";
import Header from "components/Header";
import TopFocusBox from "components/TopFocusBox";
import Footer from "components/Footer";
import AutoHeightAnimator from "components/AutoHeightAnimator";
import BasicIcon from "components/BasicIcon";
import LoadingResultsOverlay from "components/LoadingResultsOverlay";
import NotificationsOverlay from "components/NotificationsOverlay";
import SearchHintsOverlay from "components/SearchHintsOverlay";
import ExternalTenantNavigation from "components/ExternalTenantNavigation";
import AdminReportContent from "screens/AdminReportContent";
import { PositionStyle } from "utils/styles";

function getRouteContent(
  currentLocationPathname: string,
  urlParams: string | undefined
): React.ReactNode {
  const { resetSearchResults } = useSearchResultsStore.getState();
  const routeInfo = getRouteInfo(currentLocationPathname);

  switch (routeInfo.name) {
    case "index":
      return <HomeContent />;
    case "search":
      if (!urlParams) {
        return <HomeContent />;
      }
      return <SearchContent />;
    case "facility":
      return <FacilityContent />;
    case "privacy":
      resetSearchResults();
      if (process.env.REACT_APP_TENANT === "monashUni") {
        return <CookiesContent />;
      } else {
        return <PrivacyContent />;
      }
    case "termsOfService":
      resetSearchResults();
      return <PrivacyContent />;
    case "cookies":
      resetSearchResults();
      return <CookiesContent />;
    case "facilities":
      resetSearchResults();
      return <AllFacilitiesContent />;
    case "about":
      window.location.href = "/";
      return "";
    case "programType":
      resetSearchResults();
      return <ProgramTypeContent />;
    case "program":
      resetSearchResults();
      return <ProgramContent />;
    case "adminReport":
      resetSearchResults();
      return <AdminReportContent />;
    default:
      return <PageNotFoundContent />;
  }
}
const fullWidthStyle = {
  position: "relative" as PositionStyle,
  width: "100%",
  overflow: "hidden",
};

initialiseAnalytics();

const App: React.FC<{}> = () => {
  const contentHolderRef = useRef<HTMLDivElement>(null);
  const { isWideDesktop, screenIs } = useScreenSizeStore();
  const isShowingLoadingOverlay = useSearchResultsStore(
    (state: any) => state.isShowingLoadingOverlay
  );
  const [modalAnimationState, savedBodyScrolltop, modalType] = useModalStore(
    (state: any) => [
      state.animationState,
      state.savedBodyScrolltop,
      state.modalType,
    ]
  );
  const [modalSecondAnimationState, modalSecondType] = useModalSecondStore(
    (state: any) => [state.animationState, state.modalType]
  );

  const updateSearchText = useSearchBarStore(
    (state: any) => state.updateSearchText
  );

  const location = useLocation();

  const history = useHistory();
  useEffect(() => {
    switch (process.env.REACT_APP_TENANT) {
      case "arin":
        if (location.pathname.toLowerCase() === PATHS.ncris) {
          history.push(
            PATHS.programType.replace(
              ":id",
              tenantConfig.ncrisProgramTypeId || ""
            )
          );
        }
        if (location.pathname.indexOf("/programType/") === 0) {
          history.push(location.pathname.replace("/programType/", "/pt/"));
        }
        break;
      case "singascope":
        if (location.pathname === "/") {
          history.push(PATHS.facilities);
        }
        break;
      case "monashUni":
        break;
    }
  }, [history, location.pathname]);

  useEffect(() => {
    // disable animations for cypress
    if ((window as any).Cypress) {
      // Globals.assign({
      //   skipAnimation: true,
      // });
    }
  }, []);

  const [validRoute, setValidRoute] = useState<boolean>(
    isValidRoute(location.pathname)
  );
  const transitions = useTransition(location, {
    key: (location: any) => location.pathname,
    from: {
      opacity: 0,
      position: "absolute" as PositionStyle,
      top: 0,
      left: 0,
    },
    enter: { opacity: 1, position: "relative" as PositionStyle },
    leave: {
      opacity: 0,
      position: "absolute" as PositionStyle,
      top: 0,
      left: 0,
    },
  });

  useEffect(() => {
    if (location.pathname === PATHS.index) {
      updateSearchText("");
    }
  }, [location, updateSearchText]);

  useEffect(() => {
    setValidRoute(isValidRoute(location.pathname));
  }, [contentHolderRef, location.pathname]);

  const getContentHorizontalPadding = useCallback(
    () =>
      `${screenIs({
        default: MAIN_PADDING.default,
        tablet: MAIN_PADDING.tablet,
        mobile: MAIN_PADDING.mobile,
      })}px`,
    [screenIs]
  );

  const isShowingModal = ["opened", "opening"].includes(modalAnimationState);
  const isShowingModalSecond = ["opened", "opening"].includes(
    modalSecondAnimationState
  );

  const shouldBlur =
    isShowingLoadingOverlay || (isShowingModal && modalType === "popup");
  const blurAmount = isShowingModal ? 0.5 : 10;

  return (
    <ThemeProvider theme={tenantConfig.theme}>
      <div css={{ ...addFlex({ x: "center", y: "top", direction: "down" }) }}>
        {tenantConfig.externalNavigation && <ExternalTenantNavigation />}
        <div
          css={{
            label: "AppInner",
            position: "relative",
            ...addFlex({ x: "center", y: "edges", direction: "down" }),
            ...addPadding({
              horizontal: screenIs({
                default: "32px",
                tablet: "25px",
                mobile: "0px",
              }),
            }),
            width: "100%",
            backgroundColor: "#ffffff",
            minHeight: "100vh",
            filter: shouldBlur ? `blur(${blurAmount}px)` : undefined,
            transition: "blur 0.35s ease-in-out",
            willChange: "filter, transform",
            transform: `translateY(${
              isShowingModal ? -savedBodyScrolltop : 0
            }px)`,
          }}
        >
          <div
            css={{
              width: "100%",
              ...addFlex({ x: "center", y: "top", direction: "down" }),
              flexGrow: 1,
            }}
          >
            <div css={fullWidthStyle}>
              <AutoHeightAnimator initialHeight={60}>
                {validRoute && <Header />}
              </AutoHeightAnimator>
            </div>
            <TopFocusBox />
            <div
              css={{
                ...fullWidthStyle,
                overflow: "visible",
                maxWidth: isWideDesktop ? "1800px" : "1440px",
                flexGrow: 1,
                display: "flex",
              }}
            >
              <AutoHeightAnimator initialHeight={window.innerHeight * 0.7}>
                {transitions((props, currentLocation) => (
                  <animated.div
                    css={{
                      position: "relative",
                      ...addSize({ width: "100%" }),
                      ...addPadding({
                        horizontal: getContentHorizontalPadding(),
                      }),
                      flexGrow: 1,
                    }}
                    style={props}
                  >
                    {getRouteContent(currentLocation.pathname, location?.hash)}
                  </animated.div>
                ))}
              </AutoHeightAnimator>
            </div>
          </div>
          <div css={fullWidthStyle}>
            <AutoHeightAnimator>{validRoute && <Footer />}</AutoHeightAnimator>
          </div>
          <SearchHintsOverlay />
        </div>

        <LoadingResultsOverlay />
        <Modal useModalStoreProp={useModalStore} />
        <Modal useModalStoreProp={useModalSecondStore} />
        <NotificationsOverlay />
      </div>
    </ThemeProvider>
  );
};

export default App;
