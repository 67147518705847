import { SearchResultResponse, GeoLocation, Coords } from "utils/types";

function distanceBetweenLatLongs(
  firstPosition: GeoLocation,
  secondPosition: GeoLocation
): number {
  if (
    firstPosition.latitude === secondPosition.latitude &&
    firstPosition.longitude === secondPosition.longitude
  ) {
    return 0;
  }
  const radlat1 = (Math.PI * firstPosition.latitude) / 180;
  const radlat2 = (Math.PI * secondPosition.latitude) / 180;
  const theta = firstPosition.longitude - secondPosition.longitude;
  const radtheta = (Math.PI * theta) / 180;

  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  if (dist > 1) {
    dist = 1;
  }

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;

  return dist * 1.609344;
}

function formatPositionForFacility(coords?: Coords): GeoLocation {
  if (coords === undefined || coords === null) {
    throw `Missing lat lng`;
  }
  const { lat, long } = coords;
  return {
    latitude: lat,
    longitude: long,
  };
}

function splitSearchResultWithAndWithoutCoords(searchResults: {
  [facilityId: string]: SearchResultResponse;
}): {
  searchResultsWithCords: SearchResultResponse[];
  searchResultsWithoutCords: SearchResultResponse[];
} {
  const searchResultsWithCords: SearchResultResponse[] = [];
  const searchResultsWithoutCords: SearchResultResponse[] = [];

  Object.entries(searchResults).forEach(([, searchResults]) => {
    if (!!searchResults.facility.coords) {
      searchResultsWithCords.push(searchResults);
    } else {
      searchResultsWithoutCords.push(searchResults);
    }
  });
  return { searchResultsWithCords, searchResultsWithoutCords };
}

function sortSearchResultsByDistance(
  searchResultsWithCords: SearchResultResponse[],
  location: GeoLocation
): SearchResultResponse[] {
  return searchResultsWithCords.sort((aSearchResults, bSearchResults) => {
    return (
      distanceBetweenLatLongs(
        location,
        formatPositionForFacility(aSearchResults.facility.coords)
      ) -
      distanceBetweenLatLongs(
        location,
        formatPositionForFacility(bSearchResults.facility.coords)
      )
    );
  });
}
export function sortByScore(searchResults: {
  [facilityId: string]: SearchResultResponse;
}): SearchResultResponse[] {
  return Object.entries(searchResults)
    .sort((a: any, b: any) => b[1].score - a[1].score)
    .map((sortedValue) => sortedValue[1]);
}

export function sortByDistance(
  searchResults: {
    [facilityId: string]: SearchResultResponse;
  },
  location: GeoLocation
): SearchResultResponse[] {
  const { searchResultsWithCords, searchResultsWithoutCords } =
    splitSearchResultWithAndWithoutCoords(searchResults);

  return [
    ...sortSearchResultsByDistance(searchResultsWithCords, location),
    ...searchResultsWithoutCords.sort((a: any, b: any) => b.score - a.score),
  ];
}
