import { gql } from "apollo-boost";

import Api from "services/api";
import { FacilityResponse, Program, ProgramType } from "utils/types";

type GetFacilityResultResponse = {
  facility: FacilityResponse;
  institution: {
    id: string;
    name: string;
  };
  program: Program;
  programType: ProgramType;
};

type GetFacilityResult = {
  facility: GetFacilityResultResponse;
};

const getFacility = (id: string) =>
  Api.query<GetFacilityResult>({
    query: gql`
      {
        facility(id: "${id}") {
          facility {
            description
            id
            externalId
            location
            title
            services {
              description
              id
              title
              alternatives {
                property
                value
                id
              }
            }
            equipment {
              description
              id
              title
              images
              alternatives {
                property
                value
                id
              }
            }
            contacts {
              id
              email
              firstName
              lastName
              phone
              location
              role
            }
          }
          institution {
            id
            name
          }
          program {
            id
            name
            logo
            summary
            description {
              title
              body
            }
          }
          programType {
            id
            name
            logo
          }
          relationshipType
        }
      }
    `,
  }).then((result: any) => result);

export default getFacility;
