/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import BasicIcon from "components/BasicIcon";
import { useScreenSizeStore } from "stores";
import { addFlex, addMargin, addPadding } from "../../utils";
import { useTheme } from "@emotion/react";
import { useProgramStore } from "stores/program";
import { useHistory, useLocation } from "react-router";
import SearchResult from "components/SearchResult";
import LoadingSpinner from "components/LoadingSpinner";
import MoreInfoRow from "components/MoreInfoRow";
import addressIcon from "assets/images/address.png";
import phoneIcon from "assets/images/phone.png";
import emailIcon from "assets/images/email.png";
import websiteIcon from "assets/images/website.png";
import parse from "html-react-parser";
import { getParseHTMLOptions } from "../../utils/parseHtml";
import PATHS from "../../utils/routes";
import KeyContactRow from "../../components/KeyContactRow";
import { MoreInfoItem, MoreInfoFieldName } from "../../utils/types";
import scrollIntoView from "../SearchContent";
import HyperLink from "components/HyperLink";

type Props = {};

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <BasicIcon name="SelectArrow" size={8} height={14} />
  </components.DropdownIndicator>
);

const ProgramsContent: React.FC<Props> = () => {
  const { screenIs, isWideDesktop } = useScreenSizeStore();

  const theme = useTheme();
  const customParseHTMLOptions = getParseHTMLOptions(theme.mainColor);
  const history = useHistory();
  const { pathname, hash } = useLocation();
  const [tabIndex, setTabIndex] = useState(0);

  scrollIntoView(document.body, {
    scrollMode: "if-needed",
    block: "start",
    behavior: "smooth",
  });

  const [fetchProgram, program, isLoadingProgram, resetIsLoadingProgram] =
    useProgramStore(
      ({ fetchProgram, program, isLoadingProgram, resetIsLoadingProgram }) => [
        fetchProgram,
        program,
        isLoadingProgram,
        resetIsLoadingProgram,
      ]
    );

  const pathElements = pathname.split("/");
  const expectedElements = PATHS.program.split("/");
  const [pathBase, pathRoute, programId] = pathElements;
  const [expectedBase, expectedRoute] = expectedElements;

  useEffect(() => {
    fetchProgram(pathRoute, expectedRoute, programId);
  }, [fetchProgram, pathRoute, expectedRoute, programId]);

  const keyContacts = program?.program.keyContacts;
  const moreInfo = program?.program.moreInfo;
  const moreInfoFields: MoreInfoFieldName[] = [
    "address",
    "phone",
    "email",
    "url",
  ];
  const moreInfoDeco = {
    address: { label: "Address", icon: addressIcon },
    phone: { label: "Phone", icon: phoneIcon },
    email: { label: "Email", icon: emailIcon },
    url: { label: "Website", icon: websiteIcon },
  };
  function hasField(fieldName: MoreInfoFieldName, moreInfo: MoreInfoItem) {
    return moreInfo && moreInfo[fieldName] && moreInfo[fieldName] !== "";
  }

  const filteredDescription = program?.program.description?.filter(
    (d) =>
      (d.body !== undefined && d.body !== null && d.body !== "") ||
      (d.tags !== undefined && d.tags !== null && d.tags.length > 0)
  );
  const description = filteredDescription ? filteredDescription : [];

  const formatTitleForId = (title: string) =>
    title.replaceAll(" ", "-").toLowerCase();
  const [jumpToSection, setJumpToSection] = useState<string>(
    description[0]?.title
  );

  useEffect(() => {
    console.log(jumpToSection);
    if (jumpToSection) {
      document
        .getElementById(jumpToSection)
        ?.scrollIntoView({ behavior: "smooth" });
    }
  }, [jumpToSection]);

  if (isLoadingProgram) {
    return <LoadingSpinner height="calc(100vh - 390px)" />;
  }

  function logo(src: string) {
    if (src) {
      return (
        <div
          css={{
            ...addFlex({
              x: screenIs({
                default: "right",
                mobile: "left",
                tablet: "left",
              }),
              y: "center",
            }),
            width: "100%",
            marginLeft: screenIs({
              default: "50px",
              tablet: "0px",
              mobile: "0px",
            }),
            marginRight: screenIs({
              default: "0px",
              mobile: "20px",
            }),
            marginBottom: screenIs({
              default: "0px",
              tablet: "20px",
              mobile: "20px",
            }),
          }}
        >
          <img
            css={{
              maxHeight: "120px",
              maxWidth: screenIs({
                default: "200px",
                mobile: "100%",
              }),
            }}
            src={src}
          />
        </div>
      );
    }
    return null;
  }

  return program ? (
    <div
      css={{
        position: "relative",
        top: screenIs({
          default: "-130px",
          mobile: "-50px",
          tablet: "-50px",
        }),
        left: "0",
        marginBottom: "200px",
      }}
    >
      <div
        css={{
          background: "#ffffff",
          borderRadius: "4px",
          flexDirection: screenIs({
            default: "row-reverse",
            mobile: "column",
            tablet: "column",
          }),
          ...addMargin({ top: "20px", bottom: "60px" }),
          ...addPadding(
            screenIs({
              default: { horizontal: "50px", vertical: "50px" },
              mobile: { horizontal: "20px", vertical: "20px" },
              tablet: { horizontal: "20px", vertical: "20px" },
            })
          ),
          display: "flex",
          width: "100%",
          boxShadow: "0 8px 16px rgba(0,0,0,0.08)",
        }}
      >
        <div
          css={{
            ...addFlex({
              x: "left",
              y: "center",
            }),
            width: "100%",
          }}
        >
          {logo(program?.programType.logo)}
          {logo(program?.program.logo)}
        </div>
        <div
          css={{
            ...addFlex({ x: "left", y: "top" }),
            lineHeight: "30px",
            fontSize: screenIs({
              default: "18px",
              mobile: "14px",
              tablet: "14px",
            }),
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            css={{
              margin: "0 0 10px",
            }}
          >
            {parse(program?.program.summary, customParseHTMLOptions)}
          </div>
          <HyperLink
            label={`Explore all ${program?.programType.name} programs`}
            linkTo={PATHS.programType.replace(":id", program?.programType.id)}
            fontSize={"18px"}
            css={{
              lineHeight: "50px",
            }}
          >
            {`Explore all ${program?.programType.name} programs`}
          </HyperLink>
        </div>
      </div>

      <div
        css={{
          display: "flex",
          flexDirection: screenIs({
            default: "row",
            mobile: "column",
            tablet: "column",
          }),
        }}
      >
        <div
          css={{
            width: screenIs({
              default: "780px",
              tablet: "100%",
              mobile: "100%",
            }),
            marginRight: screenIs({
              default: "37px",
              tablet: "100%",
              mobile: "100%",
            }),
          }}
        >
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            <TabList css={{ border: "none" }}>
              <Tab>
                <span className={tabIndex == 0 ? "selected" : ""}>About</span>
              </Tab>
              {program.facilities && program.facilities.length > 0 ? (
                <Tab>
                  <span className={tabIndex == 1 ? "selected" : ""}>
                    {process.env.REACT_APP_TENANT === "arin"
                      ? "Facilities on Arin"
                      : "Participating facilities"}
                  </span>
                </Tab>
              ) : null}
            </TabList>

            <TabPanel>
              <div
                css={{
                  display: "flex",
                  flexDirection: screenIs({
                    default: "row",
                    mobile: "column",
                  }),
                }}
              >
                <div
                  css={{
                    ...addFlex({ y: "top" }),
                    width: screenIs({
                      default: "780px",
                      tablet: "100%",
                      mobile: "100%",
                    }),
                    marginRight: "37px",
                    marginBottom: "50px",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <div css={{ display: "flex", alignItems: "center" }}>
                      <div css={{ fontSize: "14px", marginRight: 10 }}>
                        Jump to section
                      </div>
                      {program && (
                        <Select
                          options={description}
                          defaultInputValue={jumpToSection}
                          getOptionLabel={(option) => option.title}
                          getOptionValue={(option) => option.title}
                          onChange={(val) => {
                            if (val) {
                              setJumpToSection(formatTitleForId(val.title));
                            }
                          }}
                          onMenuOpen={() => {
                            setJumpToSection("");
                          }}
                          components={{ DropdownIndicator }}
                          styles={{
                            menu: (provided, state) => ({
                              ...provided,
                              width: "100%",
                              fontSize: 14,
                            }),
                            control: (base, state) => ({
                              ...base,
                              minWidth: "200px",
                              width: screenIs({
                                default: "350px",
                                tablet: "100%",
                                mobile: "100%",
                              }),
                              fontSize: 14,
                            }),
                            indicatorSeparator: () => ({
                              display: "none",
                            }),
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <div css={{ width: "100%" }}>
                    {description.map(({ title, body, tags }, index) => (
                      <div css={{ width: "100%" }} id={formatTitleForId(title)}>
                        <div
                          css={{
                            fontSize: "26px",
                            fontWeight: "bold",
                            lineHeight: "34px",
                            marginTop: index > 0 ? "30px" : "0px",
                            paddingTop: "40px",
                            marginBottom: "10px",
                            borderTop: index > 0 ? "1px solid #D4D4D4" : "auto",
                            width: "100%",
                          }}
                        >
                          {title}
                        </div>

                        {tags.length > 0 ? (
                          <div
                            css={{
                              ...addMargin({ vertical: "15px" }),
                              ...addFlex({ x: "left", wrap: "wrap" }),
                            }}
                          >
                            {tags.map((t) => (
                              <span
                                css={{
                                  ...addMargin({
                                    top: "5px",
                                    bottom: "15px",
                                    right: "10px",
                                  }),
                                  ...addPadding({
                                    vertical: "5px",
                                    horizontal: "15px",
                                  }),
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                  lineHeight: "15px",
                                  color: "white",
                                  background: "#228192",
                                  borderRadius: "100px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {t.label}
                              </span>
                            ))}
                          </div>
                        ) : null}

                        <div
                          className={"align-ul"}
                          css={{
                            fontSize: "18px",
                            lineHeight: "30px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {parse(body, customParseHTMLOptions)}
                        </div>
                      </div>
                    ))}

                    {program.program.attributionTitle &&
                    program.program.attributionBody ? (
                      <div
                        css={{
                          background: "#EEF4F6",
                          borderRadius: "8px",
                          ...addPadding({
                            horizontal: "27px",
                            vertical: "27px",
                          }),
                          marginTop: "40px",
                        }}
                      >
                        <div
                          css={{
                            fontSize: "26px",
                            fontWeight: "bold",
                            lineHeight: "34px",
                            color: "#2F2F2F",
                            marginBottom: "15px",
                          }}
                        >
                          {program.program.attributionTitle}
                        </div>
                        <div
                          css={{
                            fontSize: "18px",
                            lineHeight: "30px",
                            color: "#2F2F2F",
                          }}
                        >
                          {parse(
                            program.program.attributionBody,
                            customParseHTMLOptions
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </TabPanel>

            {program.facilities && program.facilities.length ? (
              <TabPanel>
                {program.facilities.map(({ facility, institution }, index) => (
                  <div key={facility.id}>
                    <SearchResult
                      institutionName={institution.name}
                      title={facility.title}
                      location={facility.location}
                      position={index.toString()}
                      institutionId={institution.id}
                      id={facility.id}
                      key={facility.id}
                      program={null}
                    ></SearchResult>
                  </div>
                ))}
              </TabPanel>
            ) : null}
          </Tabs>
        </div>

        <div css={{ ...addFlex({ y: "top" }) }}>
          <div
            css={{
              width: screenIs({
                default: "392px",
                tablet: "calc(100% - 37px)",
                mobile: "100%",
              }),
            }}
          >
            {keyContacts && keyContacts.length > 0 ? (
              <div
                css={{
                  width: screenIs({
                    default: "392px",
                    tablet: "100%",
                    mobile: "100%",
                  }),
                  lineHeight: "36px",
                  boxShadow: "0 8px 16px rgba(0,0,0,0.08)",
                  paddingBottom: "2px",
                  marginBottom: "10px",
                }}
              >
                <h2
                  css={{
                    lineHeight: "36px",
                    padding: "28px",
                    borderBottom: "1px solid #D4D4D4",
                  }}
                >
                  Key contacts
                </h2>

                {keyContacts.map((contact, index) => (
                  <KeyContactRow
                    firstLine={index == 0}
                    contact={contact}
                  ></KeyContactRow>
                ))}
              </div>
            ) : null}

            {moreInfo && moreInfo.length > 0 ? (
              <div
                css={{
                  width: screenIs({
                    default: "392px",
                    tablet: "100%",
                    mobile: "100%",
                  }),
                  lineHeight: "36px",
                  boxShadow: "0 8px 16px rgba(0,0,0,0.08)",
                  paddingBottom: "2px",
                }}
              >
                <h2
                  css={{
                    lineHeight: "36px",
                    padding: "28px",
                    borderBottom: "1px solid #D4D4D4",
                  }}
                >
                  {keyContacts && keyContacts.length > 0
                    ? "More info"
                    : "Contacts"}
                </h2>

                {moreInfo.map((moreInfoItem, itemIndex) => {
                  const itemFields = moreInfoFields.filter((f) =>
                    hasField(f, moreInfoItem)
                  );
                  return (
                    <div
                      css={{
                        ...addMargin({ horizontal: "28px", vertical: "20px" }),
                        ...addPadding({ top: "25px" }),
                        borderTop: itemIndex > 0 ? "1px solid #D4D4D4" : "auto",
                      }}
                    >
                      {moreInfoItem.name && moreInfoItem.name !== "" ? (
                        <div css={{ fontWeight: "bold", fontSize: "18px" }}>
                          {moreInfoItem.name}
                        </div>
                      ) : null}
                      {itemFields.map((f, fieldIndex) => (
                        <MoreInfoRow
                          firstLine={fieldIndex == 0}
                          title={moreInfoDeco[f].label}
                          icon={moreInfoDeco[f].icon}
                          content={moreInfoItem[f]}
                          type={f}
                        ></MoreInfoRow>
                      ))}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ProgramsContent;
