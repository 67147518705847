/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React from "react";
import Paragraph from "components/Paragraph";
import BasicButton from "components/BasicButton";
import { useSearchBarStore, useScreenSizeStore } from "stores";
import { shallow } from "zustand/shallow";

type Props = {
  searchTerm: string;
  results: number;
  isActive?: boolean;
  allowFocus?: boolean;
  className?: string;
};

const SearchTermSummary: React.FC<Props> = ({
  searchTerm,
  results,
  isActive = false,
  allowFocus = true,
  className,
}) => {
  const { isDesktop, isLandscapeTablet } = useScreenSizeStore();
  const [updateSearchText, submitSearch] = useSearchBarStore(
    (state: any) => [state.updateSearchText, state.submitSearch],
    shallow
  );
  return (
    <BasicButton
      onClick={() => {
        updateSearchText(searchTerm);
        submitSearch();
      }}
      label={`search for ${searchTerm}`}
      allowFocus={allowFocus}
      css={{
        color: isActive ? "#339eb1" : "#228192",
        display: "inline-flex",
        border: isActive ? "2px solid #339eb1" : "2px solid #228192",
        padding: "5px 10px",
        borderRadius: "10px",
        cursor: "pointer",
        transition:
          "background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out",
        backgroundColor: isActive
          ? "rgba(235, 254, 255, 0.9)"
          : "rgba(235, 254, 255, 0)",
        boxShadow: isActive
          ? "0px 0px 5px rgba(0,0,0,0.2)"
          : "0px 0px 5px rgba(0,0,0,0)",
        ":hover": {
          background: "#228192",
          color: "#fff",
          span: {
            color: "#fff",
          },
        },
      }}
      className={className}
    >
      <Paragraph type={`small`} bold css={{ textAlign: "left" }}>
        {searchTerm}
        {(isDesktop || isLandscapeTablet) && (
          <span
            css={{
              color: "#949494",
              marginLeft: "10px",
              display: "inline-block",
            }}
          >
            {results} results
          </span>
        )}
      </Paragraph>
    </BasicButton>
  );
};

export default SearchTermSummary;
