/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React, { Fragment, useEffect } from "react";
import Paragraph from "components/Paragraph";
import { useScreenSizeStore, useSummaryStore } from "stores";
import { numberWithCommas } from "utils/formatters";
type Props = {
  descriptionContent: React.ReactNode;
  imageWidth: number;
  imageMargin: number;
};

const SearchIndexSummary: React.FC<Props> = ({
  descriptionContent,
  imageWidth,
  imageMargin,
}) => {
  const { isMobile, screenIs } = useScreenSizeStore();
  const [getSummary, totalCount, summary] = useSummaryStore((state: any) => [
    state.getSummary,
    state.totalCount,
    state.summary,
  ]);

  useEffect(() => {
    getSummary();
  }, []);

  return (
    <Fragment>
      {isMobile && descriptionContent}
      {totalCount && summary && (
        <Paragraph
          css={{
            label: "info-text",
            color: "#FFFFFF",
            paddingBottom: screenIs({
              default: "164px",
              tablet: "45px",
              mobile: "29px",
            }),
            width: screenIs({
              default: `calc(100% - ${imageWidth + imageMargin}px)`,
              mobile: "100%",
            }),
          }}
        >
          Indexing{" "}
          <span style={{ fontWeight: "bold" }}>
            {numberWithCommas(totalCount.equipmentCount)} equipment
          </span>
          ,{" "}
          <span style={{ fontWeight: "bold" }}>
            {numberWithCommas(totalCount.servicesCount)} services
          </span>{" "}
          at Monash University.
        </Paragraph>
      )}
    </Fragment>
  );
};

export default SearchIndexSummary;
