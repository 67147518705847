/** @jsxImportSource @emotion/react */
import { FacilityLinkListItem } from "components/FacilityLinksList";

const MINIUM_ITEMS_FOR_USED_FOR_SPLIT = 4;
const MINIUM_SPLIT_INTO_EQUAL_COLUMN = 8;

export function splitItemsToColumns(
  linkItems: Array<FacilityLinkListItem>
): [Array<FacilityLinkListItem>, Array<FacilityLinkListItem>] {
  let leftCol: Array<FacilityLinkListItem> = [];
  let rightCol: Array<FacilityLinkListItem> = [];

  if (linkItems.length <= MINIUM_ITEMS_FOR_USED_FOR_SPLIT) {
    leftCol = linkItems.slice(0, linkItems.length);
    rightCol = [];
  }

  if (
    linkItems.length > MINIUM_ITEMS_FOR_USED_FOR_SPLIT &&
    linkItems.length <= MINIUM_SPLIT_INTO_EQUAL_COLUMN
  ) {
    leftCol = linkItems.slice(0, MINIUM_ITEMS_FOR_USED_FOR_SPLIT);
    rightCol = linkItems.slice(
      MINIUM_ITEMS_FOR_USED_FOR_SPLIT,
      linkItems.length
    );
  }

  if (linkItems.length > MINIUM_SPLIT_INTO_EQUAL_COLUMN) {
    rightCol = linkItems.slice(0, linkItems.length);
    leftCol = rightCol.splice(0, Math.ceil(rightCol.length / 2));
  }

  return [leftCol, rightCol];
}

export default { splitItemsToColumns };
