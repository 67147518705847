import { ALLOWED_HTML_TAGS } from "utils/constants";
import { DOMNode, domToReact, Element } from "html-react-parser";
import React, { CSSProperties, Fragment } from "react";

type ReplaceHtmlReturn = React.ReactElement | object | undefined | false;

type CustomPropsToElement = {
  src: string;
  style: CSSProperties;
};

function addCustomPropsToElement(
  domNode: any,
  customColor?: string
): CustomPropsToElement | {} {
  const src = domNode.attribs?.src;
  const href = domNode.attribs?.href;
  switch (domNode.name) {
    case "img":
      return {
        src,
        style: { maxWidth: "100%", display: "block", margin: "10px 0" },
      };
    case "td":
      return {
        src,
        style: { width: "100%", display: "block" },
      };
    case "a":
      return {
        href,
        style: { color: customColor || "white" },
      };
    default:
      return {};
  }
}

export function getParseHTMLOptions(customColor?: string) {
  return {
    replace(domNode: any): ReplaceHtmlReturn {
      if (
        domNode.type === "tag" &&
        domNode.name &&
        ALLOWED_HTML_TAGS.includes(domNode.name)
      ) {
        const newProps = addCustomPropsToElement(domNode, customColor);

        return React.createElement(
          domNode.name,
          newProps as Partial<typeof newProps>,
          domNode.children && domNode.children.length > 0
            ? domToReact(domNode.children, getParseHTMLOptions(customColor))
            : undefined
        );
      }
      if (domNode.type === "text" && domNode.data) {
        return domNode.data;
      }
      return <Fragment />;
    },
  };
}

export const parseHTMLOptions = getParseHTMLOptions();
