/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { useEffect, useCallback, Ref } from "react";
import { useSearchBarStore, useScreenSizeStore } from "stores";
import { useFocus } from "hooks";
import BasicButton from "components/BasicButton";
import BasicIcon from "components/BasicIcon";
import { addFlex, addSize } from "utils";
import { shallow } from "zustand/shallow";

type Props = {
  ref?: Ref<HTMLFormElement>;
  activeHintTerm?: string;
};

const SearchBar: React.FC<Props> = React.forwardRef<HTMLFormElement, Props>(
  ({ activeHintTerm = "" }, theRef) => {
    const [
      searchText,
      updateSearchText,
      updateSearchFocus,
      submitSearch,
      timeLastPressedSearch,
    ] = useSearchBarStore(
      (state: any) => [
        state.searchText,
        state.updateSearchText,
        state.updateSearchFocus,
        state.submitSearch,
        state.timeLastPressedSearch,
      ],
      shallow
    );

    const [focusRef, isFocused] = useFocus();

    const { screenIs, isMobile } = useScreenSizeStore();
    const theme = useTheme();
    useEffect(() => {
      updateSearchFocus(isFocused);
      if (isFocused && focusRef && focusRef.current && isMobile) {
        focusRef.current.scrollIntoView(true);
        window.scrollBy(0, -10);
      }
    }, [focusRef, isFocused, updateSearchFocus, isMobile]);

    useEffect(() => {
      focusRef.current?.blur();
    }, [focusRef, timeLastPressedSearch]);

    const handleSubmit = useCallback(
      (event: React.FormEvent) => {
        event.preventDefault();
        if (searchText.length > 0) {
          submitSearch();
        }
      },
      [searchText, submitSearch]
    );

    const searchBarHeight = screenIs({
      default: "90px",
      tablet: "70px",
      mobile: "50px",
    });

    return (
      <form
        ref={theRef}
        onSubmit={(event) => {
          handleSubmit(event);
        }}
        css={{
          position: "relative",
          width: "100%",
          ...addFlex({ x: "center", y: "center" }),
          borderRadius: `calc(${searchBarHeight}/2)`,
          overflow: "hidden",
          // set to fix issue with slight gap between border and content, with borderRadius
          backgroundColor: "white",
          transition:
            "box-shadow 0.35s ease-in-out, border-color 0.3s ease-in-out",
          boxShadow: isFocused
            ? "0px 0px 25px rgba(0,0,0,0.25)"
            : "0px 0px 25px rgba(0,0,0,0)",
        }}
      >
        <div
          css={{
            ...addFlex({ x: "center", y: "center" }),
            ...addSize({
              width: { default: searchBarHeight, min: searchBarHeight },
              height: { default: searchBarHeight, min: searchBarHeight },
            }),
          }}
        >
          <BasicIcon
            name="Search"
            color={theme.mainColor}
            size={screenIs({ default: 34, tablet: 30, mobile: 24 })}
          />
        </div>

        <input
          aria-autocomplete="both"
          aria-controls="TopFocusBox-SearchContent-popupContainer-currentListbox"
          aria-activedescendant={`TopFocusBox-SearchContent-popupContainer-currentListbox-item-${activeHintTerm}`}
          css={{
            position: "relative",
            width: "100%",
            height: searchBarHeight,
            minWidth: "100px",
            color: "#3D3D3D",
            border: "none",
            borderRadius: "0",
            fontWeight: "bold",
            fontSize: screenIs({
              default: "26px",
              tablet: "22px",
              mobile: "16px",
            }),
            lineHeight: "34px",
            ":focus": {
              outline: "none",
            },
          }}
          ref={focusRef}
          type="search"
          aria-label="search for equipment or services"
          data-automation={`searchBar`}
          value={searchText}
          onChange={(event) => {
            updateSearchText(event.target.value);
          }}
        />
        <div
          css={{
            backgroundColor: "white",
            position: "relative",
          }}
        >
          <BasicButton
            label="clear"
            css={{
              position: "absolute",
              top: 0,
              right: 0,
              transform: "translateY(-50%)",
              minWidth: searchBarHeight,
              minHeight: searchBarHeight,
              width: searchBarHeight,
              height: searchBarHeight,
              transition: "opacity 0.3s ease-in-out",
              opacity: searchText.length > 0 ? 1 : 0,
              backgroundColor: "white",
              pointerEvents: searchText.length === 0 ? "none" : "all",
            }}
            onClick={() => {
              updateSearchText("");
            }}
            disabled={searchText.length === 0}
          >
            <BasicIcon
              name="Close"
              color="#949494"
              size={screenIs({ default: 40, tablet: 34, mobile: 26 })}
            />
          </BasicButton>
        </div>
      </form>
    );
  }
);

export default SearchBar;
