/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

const Transparency: React.FC = () => {
  return (
    <svg
      width="77"
      height="63"
      viewBox="0 0 77 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7811_4367)">
        <mask
          id="mask0_7811_4367"
          maskUnits="userSpaceOnUse"
          x="0"
          y="-7"
          width="77"
          height="77"
        >
          <path d="M0 -7H77V70H0V-7Z" fill="white" />
        </mask>
        <g mask="url(#mask0_7811_4367)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 46.8834C0 49.157 1.86102 51 4.15675 51H63.9013C65.919 51 67.5986 49.5773 67.978 47.6905C68.1002 47.0829 67.7018 46.4922 67.0883 46.3712C66.4747 46.2502 65.8783 46.6447 65.7561 47.2523C65.5836 48.1102 64.8173 48.7564 63.9013 48.7564H4.15675C3.11222 48.7564 2.26551 47.9179 2.26551 46.8834V8.11657C2.26551 7.08214 3.11222 6.24361 4.15675 6.24361H5.48102C6.10662 6.24361 6.61377 5.74136 6.61377 5.12181C6.61377 4.50225 6.10662 4 5.48102 4H4.15675C1.86102 4 0 5.84303 0 8.11657V46.8834Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M66.865 39C67.4918 39 68 38.501 68 37.8854V8.09014C68 5.83123 66.1352 4 63.8349 4H26.135C25.5082 4 25 4.49904 25 5.11464C25 5.73024 25.5082 6.22928 26.135 6.22928H63.8349C64.8816 6.22928 65.7299 7.0625 65.7299 8.09014V37.8854C65.7299 38.501 66.2381 39 66.865 39Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 42.4998C0 43.3282 0.507517 43.9998 1.13357 43.9998H61.8664C62.4925 43.9998 63 43.3282 63 42.4998C63 41.6713 62.4925 40.9998 61.8664 40.9998H1.13357C0.507517 40.9998 0 41.6713 0 42.4998Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M26.2347 62.5101C26.4532 62.8187 26.7939 63 27.1555 63H40.8445C41.2061 63 41.5468 62.8187 41.7653 62.5102C41.9837 62.2016 42.0547 61.8013 41.9571 61.4285C41.5477 59.8653 41.0011 56.7969 40.5499 54.0601C40.3263 52.7044 40.1293 51.4497 39.9881 50.5337C39.9176 50.0758 39.861 49.7029 39.8221 49.4446C39.8027 49.3155 39.7876 49.2151 39.7775 49.1471L39.7622 49.0443L39.7622 49.044C39.7622 49.044 39.7622 49.044 38.6209 49.2374L39.7622 49.044C39.6733 48.4428 39.1893 48 38.6209 48H29.3793C28.8108 48 28.3268 48.4427 28.238 49.044L29.3793 49.2374C28.238 49.044 28.238 49.0439 28.238 49.044L28.2227 49.1471C28.2125 49.2151 28.1975 49.3155 28.1781 49.4446C28.1392 49.7029 28.0826 50.0758 28.012 50.5337C27.8709 51.4497 27.6738 52.7044 27.4503 54.0601C26.999 56.7969 26.4523 59.8652 26.0429 61.4284C25.9453 61.8013 26.0163 62.2016 26.2347 62.5101ZM30.363 50.4748H37.6371C37.6588 50.6169 37.6825 50.7713 37.708 50.9367C37.8501 51.8589 38.0486 53.1231 38.2741 54.4904C38.6062 56.5047 39.0039 58.787 39.3692 60.5252H28.6308C28.9962 58.787 29.3939 56.5047 29.726 54.4904C29.9515 53.1231 30.15 51.8589 30.2922 50.9367C30.3177 50.7713 30.3413 50.6169 30.363 50.4748Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24 61.5C24 62.3284 24.497 63 25.11 63H41.89C42.503 63 43 62.3284 43 61.5C43 60.6716 42.503 60 41.89 60H25.11C24.497 60 24 60.6716 24 61.5Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5 14.9552C5 16.0845 5.86835 17 6.93947 17H25.0607C26.132 17 27 16.0842 27 14.9552V2.04478C27 0.915751 26.132 0 25.0607 0H6.93947C5.86835 0 5 0.915506 5 2.04478V14.9552ZM7.16121 14.7214V2.27856H24.8388V14.7214H7.16121Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 7C10 7.55228 10.4793 8 11.0706 8H13.9294C14.5207 8 15 7.55228 15 7C15 6.44772 14.5207 6 13.9294 6H11.0706C10.4793 6 10 6.44772 10 7Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 11C10 11.5523 10.5203 12 11.1622 12H20.8378C21.4797 12 22 11.5523 22 11C22 10.4477 21.4797 10 20.8378 10H11.1622C10.5203 10 10 10.4477 10 11Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6 34.9416C6 36.0784 6.91385 37 8.04119 37H11.3912C12.0151 37 12.5209 36.4899 12.5209 35.8607C12.5209 35.2315 12.0151 34.7214 11.3912 34.7214H8.25945V22.2786H26.7406V34.7214H16.6705C16.0466 34.7214 15.5408 35.2315 15.5408 35.8607C15.5408 36.4899 16.0466 37 16.6705 37H26.9591C28.0867 37 29 36.0781 29 34.9416V22.0584C29 20.922 28.0867 20 26.9591 20H8.04119C6.91385 20 6 20.9216 6 22.0584V34.9416Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 27C12 27.5523 12.4793 28 13.0706 28H15.9294C16.5207 28 17 27.5523 17 27C17 26.4477 16.5207 26 15.9294 26H13.0706C12.4793 26 12 26.4477 12 27Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 30.5C12 31.3284 12.5203 32 13.1622 32H22.8378C23.4797 32 24 31.3284 24 30.5C24 29.6716 23.4797 29 22.8378 29H13.1622C12.5203 29 12 29.6716 12 30.5Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M32 23.9552C32 25.0845 32.8683 26 33.9395 26H38.5018C39.0986 26 39.5824 25.4899 39.5824 24.8607C39.5824 24.2315 39.0986 23.7214 38.5018 23.7214H34.1612V11.2786H51.8388V14.6039C51.8388 15.2331 52.3226 15.7432 52.9194 15.7432C53.5162 15.7432 54 15.2331 54 14.6039V11.0448C54 9.9155 53.1316 9 52.0605 9H33.9395C32.8684 9 32 9.9155 32 11.0448V23.9552Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M36 16C36 16.5523 36.6712 17 37.4992 17H39.5008C40.3288 17 41 16.5523 41 16C41 15.4477 40.3288 15 39.5008 15H37.4992C36.6712 15 36 15.4477 36 16Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M36 19C36 19.5523 36.5215 20 37.1648 20H39.8352C40.4785 20 41 19.5523 41 19C41 18.4477 40.4785 18 39.8352 18H37.1648C36.5215 18 36 18.4477 36 19Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M61.387 42.0002C61.6756 42.0002 61.9524 41.8874 62.1565 41.6866L64.6813 39.2017C65.1062 38.7835 65.1062 38.1054 64.6813 37.6871L60.2378 33.3139C59.8128 32.8957 59.1238 32.8957 58.6988 33.3139C58.2739 33.7322 58.2739 34.4103 58.6988 34.8285L62.3729 38.4444L61.387 39.4146L57.8577 35.941C57.4327 35.5227 56.7437 35.5227 56.3187 35.941C55.8938 36.3592 55.8938 37.0373 56.3187 37.4556L60.6175 41.6865C60.8216 41.8874 61.0984 42.0002 61.387 42.0002Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M69.7887 51.7737C71.4236 53.4084 74.074 53.4085 75.7088 51.7737L75.7738 51.7087C75.7738 51.7087 75.7738 51.7087 75.7738 51.7087C77.4087 50.0739 77.4088 47.4232 75.7738 45.7884C75.7738 45.7884 75.7739 45.7885 75.7738 45.7884L66.421 36.4354C65.8395 35.8545 64.8976 35.8545 64.3161 36.4354L60.4356 40.3157C59.855 40.897 59.8544 41.8394 60.4361 42.4211L69.7887 51.7737ZM71.3929 50.1693C71.3929 50.1693 71.3929 50.1693 71.3929 50.1693L62.5919 41.3682L65.3685 38.5916L74.1695 47.3928C74.9183 48.1415 74.9184 49.3555 74.1695 50.1042L74.1045 50.1693C73.3558 50.918 72.1418 50.9181 71.3929 50.1693Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M39.9541 35.1924C45.2262 40.2692 53.774 40.2692 59.0461 35.1924C63.0015 31.3835 63.9876 25.8011 62.0116 21.1086C61.7728 20.5415 61.1017 20.2682 60.5128 20.4982C59.9239 20.7282 59.64 21.3743 59.8789 21.9415C61.5197 25.838 60.6975 30.468 57.4188 33.6253C53.0454 37.8367 45.9548 37.8367 41.5815 33.6253C37.2081 29.414 37.2081 22.5861 41.5815 18.3747C45.8283 14.2853 52.6378 14.1669 57.0322 18.0193C57.5021 18.4313 58.2299 18.3985 58.6577 17.9459C59.0856 17.4934 59.0514 16.7926 58.5815 16.3806C53.2825 11.7351 45.0741 11.8773 39.9541 16.8077C34.682 21.8845 34.682 30.1156 39.9541 35.1924Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M44 25C44 25.5523 44.5752 26 45.2847 26H48.7153C49.4248 26 50 25.5523 50 25C50 24.4477 49.4248 24 48.7153 24H45.2847C44.5752 24 44 24.4477 44 25Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M44 28C44 28.5523 44.477 29 45.0653 29H53.9347C54.523 29 55 28.5523 55 28C55 27.4477 54.523 27 53.9347 27H45.0653C44.477 27 44 27.4477 44 28Z"
            fill="#006DAE"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_7811_4367">
          <rect width="77" height="63" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Transparency;
