/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { Fragment } from "react";
import { addMargin, addPadding } from "../../utils";
import { MoreInfoFieldName } from "../../utils/types";
import { renderByType, IconRow } from "../IconRow";
import HyperLink from "../HyperLink";

type Props = {
  firstLine?: boolean | null | undefined;
  title: string;
  icon: string;
  content: string;
  type?: MoreInfoFieldName;
};

const hyperlinkTypes: MoreInfoFieldName[] = ["email", "phone", "url"];

const MoreInfoRow: React.FC<Props> = ({
  firstLine,
  title,
  icon,
  content,
  type,
}) => {
  return content ? (
    <div
      css={{
        borderTop: !firstLine ? "1px solid #D4D4D4" : "auto",
        ...addPadding({ top: "25px" }),
      }}
    >
      <IconRow icon={icon} content={title} bold={true} />
      <div css={{ fontSize: "14px", lineHeight: "24px", marginBottom: "20px" }}>
        {renderByType(content, type)}
      </div>
    </div>
  ) : null;
};

export default MoreInfoRow;
