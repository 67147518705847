import { create } from "zustand";
import { Service, Equipment } from "utils/types";

type ClickedCapabilitiesStoreState = {
  clickedMatchingService: Service[];
  clickedMismatchedService: Service[];
  clickedMatchingEquipment: Equipment[];
  clickedMismatchedEquipment: Equipment[];
};

type ClickedCapabilitiesStore = ClickedCapabilitiesStoreState & {
  addClickedService: (service: Service, IsMatchingSearchTerm: boolean) => void;
  addClickedEquipment: (
    equipment: Equipment,
    IsMatchingSearchTerm: boolean
  ) => void;
  resetClickedCapabilities: () => void;
  getClickedCapabilities: () => {
    clickedServices: {
      matched: Service[];
      missMatched: Service[];
    };
    clickedEquipment: {
      matched: Equipment[];
      missMatched: Equipment[];
    };
  };
  getAllClickedCapabilities: () => (Equipment | Service)[];
  hasClickedMisMatchedCapabilities: () => boolean;
};

const useClickedCapabilitiesStore = create<ClickedCapabilitiesStore>(
  (set: any, get: any) => ({
    clickedMatchingService: [],
    clickedMismatchedService: [],
    clickedMatchingEquipment: [],
    clickedMismatchedEquipment: [],
    addClickedService: (service: Service, IsMatchingSearchTerm: boolean) =>
      set(() =>
        IsMatchingSearchTerm
          ? {
              clickedMatchingService: [
                ...get().clickedMatchingService,
                ...[service],
              ],
            }
          : {
              clickedMismatchedService: [
                ...get().clickedMismatchedService,
                ...[service],
              ],
            }
      ),
    addClickedEquipment: (
      equipment: Equipment,
      IsMatchingSearchTerm: boolean
    ) =>
      set(() =>
        IsMatchingSearchTerm
          ? {
              clickedMatchingEquipment: [
                ...get().clickedMatchingEquipment,
                ...[equipment],
              ],
            }
          : {
              clickedMismatchedEquipment: [
                ...get().clickedMismatchedEquipment,
                ...[equipment],
              ],
            }
      ),
    resetClickedCapabilities: () =>
      set(() => ({
        clickedMatchingService: [],
        clickedMismatchedService: [],
        clickedMatchingEquipment: [],
        clickedMismatchedEquipment: [],
      })),
    getClickedCapabilities: () => ({
      clickedServices: {
        matched: get().clickedMatchingService,
        missMatched: get().clickedMismatchedService,
      },
      clickedEquipment: {
        matched: get().clickedMatchingEquipment,
        missMatched: get().clickedMismatchedEquipment,
      },
    }),
    getAllClickedCapabilities: () => [
      ...get().clickedMatchingEquipment,
      ...get().clickedMismatchedService,
      ...get().clickedMatchingEquipment,
      ...get().clickedMismatchedEquipment,
    ],
    hasClickedMisMatchedCapabilities: () =>
      get().clickedMismatchedEquipment.length > 0 ||
      get().clickedMismatchedService.length > 0,
  })
);

export { useClickedCapabilitiesStore };
