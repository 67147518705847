/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { Fragment } from "react";
import Paragraph from "components/Paragraph";
import { useScreenSizeStore } from "stores";
import { Program, ProgramType } from "../../utils/types";
import { addFlex, addMargin, addPadding } from "../../utils";
import { useTheme } from "@emotion/react";
import HyperLink from "../HyperLink";
import { getParseHTMLOptions } from "../../utils/parseHtml";
import parse from "html-react-parser";

type Props = {
  program: Program;
};

const ProgramRow: React.FC<Props> = ({ program }) => {
  const { screenIs } = useScreenSizeStore();
  const theme = useTheme();
  const customParseHTMLOptions = getParseHTMLOptions(theme.mainColor);

  return program ? (
    <div
      css={{
        ...addMargin({
          vertical: screenIs({
            default: "40px",
            mobile: "20px",
          }),
        }),
        ...addPadding({
          top: screenIs({
            default: "40px",
            mobile: "20px",
          }),
        }),
        borderTop: "1px solid #D5D5D5",
      }}
    >
      <h3
        css={{
          ...addMargin({ bottom: "15px" }),
          fontSize: screenIs({
            default: "22px",
            mobile: "18px",
          }),
          fontWeight: "bold",
        }}
      >
        {program.name}
      </h3>
      <div
        css={{
          fontSize: screenIs({
            default: "auto",
            mobile: "14px",
          }),
          ...addMargin({ vertical: "5px" }),
          lineHeight: screenIs({
            default: "30px",
            mobile: "20px",
          }),
          ...addMargin({ bottom: "15px" }),
        }}
      >
        {parse(program.summary || "", customParseHTMLOptions)}
      </div>
      <HyperLink
        label={`More information about ${program.name}`}
        linkTo={`/program/${program.id}`}
        fontSize={screenIs({
          default: "18px",
          mobile: "14px",
        })}
      >
        More information
      </HyperLink>
    </div>
  ) : null;
};

export default ProgramRow;
