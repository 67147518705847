import { gql } from "apollo-boost";

import Api from "services/api";

export type AdminReportItem = {
  title: string;
  content: string;
  __typename: string;
};

type GetAdminReportResponse = { getAdminReport: AdminReportItem[] };

const getAdminReport = ({
  email,
  timestamp,
  hash,
  captcha,
  tenantId,
}: {
  email: string;
  timestamp: string;
  hash: string;
  captcha: string;
  tenantId: string;
}) =>
  Api.query<GetAdminReportResponse>({
    query: gql`
    {
        getAdminReport(
        		email: "${email}",
            timestamp: ${timestamp},
        		hash: "${hash}",
            captcha: "${captcha}"
            tenantId: "${tenantId}"
        	) {
        title
        content
      }
    }
    `,
  }).then((result: any) => result);

export default getAdminReport;
