import { gql } from "apollo-boost";
import Api from "services/api";

type SubmitEnquiryResponse = {
  submitEnquiry: null;
};

type submitEnquiryInputType = {
  firstName: string;
  lastName: string;
  userType: string;
  organisation: string;
  email: string;
  phone: string;
  comment: string;
  institutionId: string;
  facilityId: string;
  captcha: string;
  searchTerm?: string;
};

const submitEnquiry = ({
  firstName,
  lastName,
  userType,
  organisation,
  email,
  phone,
  comment,
  institutionId,
  facilityId,
  captcha,
  searchTerm,
}: submitEnquiryInputType) =>
  Api.mutate<SubmitEnquiryResponse>({
    variables: {
      input: {
        firstName,
        lastName,
        userType,
        organisation,
        email,
        phone,
        comment,
        institutionId,
        facilityId,
        searchTerm,
      },
      captcha,
    },
    mutation: gql`
      mutation submitEnquiry($input: InputEnquiry!, $captcha: String!) {
        submitEnquiry(input: $input, captcha: $captcha) {
          comment
        }
      }
    `,
  }).then((result: any) => result.data);

export default submitEnquiry;
