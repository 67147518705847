/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { useScreenSizeStore } from "stores";
import ProgramRow from "components/ProgramRow";
import LoadingSpinner from "components/LoadingSpinner";
import { addFlex, addMargin, addPadding } from "../../utils";
import { useTheme } from "@emotion/react";
import { useProgramTypeStore } from "../../stores/programType";
import { useHistory, useLocation } from "react-router";
import tenantConfig from "../../tenants";
import scrollIntoView from "../FacilityContent";
import parse from "html-react-parser";
import { getParseHTMLOptions } from "../../utils/parseHtml";
import PATHS from "../../utils/routes";
import { Program } from "../../utils/types";

type Props = {};

function sortPrograms(a: Program, b: Program) {
  if (a.name < b.name) {
    return -1;
  } else {
    return 1;
  }
}

const ProgramTypeContent: React.FC<Props> = () => {
  const { screenIs, isWideDesktop } = useScreenSizeStore();

  const theme = useTheme();
  const customParseHTMLOptions = getParseHTMLOptions(theme.mainColor);
  const history = useHistory();
  const { pathname, hash } = useLocation();

  const [
    fetchProgramType,
    programType,
    isLoadingProgramType,
    resetIsLoadingProgramType,
  ] = useProgramTypeStore(
    ({
      fetchProgramType,
      programType,
      isLoadingProgramType,
      resetIsLoadingProgramType,
    }) => [
      fetchProgramType,
      programType,
      isLoadingProgramType,
      resetIsLoadingProgramType,
    ]
  );

  const pathElements = pathname.split("/");
  const expectedElements = PATHS.programType.split("/");
  const [pathBase, pathRoute, programTypeId] = pathElements;
  const [expectedBase, expectedRoute] = expectedElements;

  useEffect(() => {
    fetchProgramType(pathRoute, expectedRoute, programTypeId);
  }, [fetchProgramType, pathRoute, expectedRoute, programTypeId]);

  if (isLoadingProgramType) {
    return <LoadingSpinner height="calc(100vh - 390px)" />;
  }

  return (
    <div
      css={{
        position: "relative",
        top: screenIs({
          default: "-130px",
          mobile: "-50px",
        }),
        left: "0",
        marginBottom: "200px",
      }}
    >
      <div
        css={{
          background: "#ffffff",
          borderRadius: "4px",
          flexDirection: screenIs({
            default: "row-reverse",
            mobile: "column",
          }),
          ...addMargin({ top: "20px", bottom: "60px" }),
          ...addPadding(
            screenIs({
              default: { horizontal: "50px", vertical: "50px" },
              mobile: { horizontal: "20px", vertical: "20px" },
            })
          ),
          display: "flex",
          width: "100%",
          boxShadow: "0 8px 16px rgba(0,0,0,0.08)",
        }}
      >
        <div
          css={{
            ...addFlex({
              x: screenIs({
                default: "right",
                mobile: "left",
              }),
              y: "center",
            }),
            marginLeft: screenIs({
              default: "75px",
              mobile: "0px",
            }),
            marginBottom: screenIs({
              default: "0px",
              mobile: "20px",
            }),
          }}
        >
          <img
            css={{
              height: screenIs({
                default: "120px",
                mobile: "88px",
              }),
            }}
            src={programType?.logo}
          />
        </div>
        <div
          css={{
            ...addFlex({ x: "left", y: "top" }),
            lineHeight: "30px",
            fontSize: screenIs({
              default: "18px",
              mobile: "14px",
            }),
            flexDirection: "column",
          }}
        >
          {parse(programType?.summary || "", customParseHTMLOptions)}
        </div>
      </div>

      {programType ? (
        <div>
          <h2
            css={{
              fontSize: screenIs({
                default: "auto",
                mobile: "18px",
              }),
            }}
          >
            {programType.name} funded programs
          </h2>

          {programType.programs?.sort(sortPrograms).map((program) => {
            return (
              <div key={program.id}>
                <ProgramRow program={program} />
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default ProgramTypeContent;
