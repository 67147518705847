/** @jsxImportSource @emotion/react */
import { Alternative } from "utils/types";
import { SuggestionCollection } from "stores/capabilitySuggestion/types";

function getAlternativesByType(
  alternatives: Alternative[]
): SuggestionCollection {
  return {
    images: alternatives.filter(
      (alternative) => alternative.property === "image"
    ),
    descriptions: alternatives.filter(
      (alternative) => alternative.property === "description"
    ),
    title: alternatives.filter(
      (alternative) => alternative.property === "title"
    ),
  };
}
export { getAlternativesByType };
export default { getAlternativesByType };
