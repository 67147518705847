/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { Fragment } from "react";
import Paragraph from "components/Paragraph";
import { useScreenSizeStore } from "stores";
import { ProgramMeta } from "../../utils/types";
import { addFlex, addMargin } from "../../utils";
import HyperLink from "components/HyperLink";
import PATHS from "../../utils/routes";
import parse from "html-react-parser";
import { getParseHTMLOptions } from "../../utils/parseHtml";
import { useTheme } from "@emotion/react";

type Props = {
  programMeta: ProgramMeta | null;
};

const SelectedProgram: React.FC<Props> = ({ programMeta }) => {
  const { screenIs, isDesktop } = useScreenSizeStore();
  const theme = useTheme();
  const customParseHTMLOptions = getParseHTMLOptions(theme.mainColor);

  const LEARN_MORE_LINK_STYLES = isDesktop
    ? css({
        marginTop: "10px",
        fontWeight: "bold",
        lineHeight: "30px",
      })
    : css({
        fontWeight: "bold",
      });

  return programMeta && programMeta.programType ? (
    <Fragment>
      <div
        css={{
          width: "100%",
          position: "relative",
          backgroundColor: "#EEF4F6",
          borderRadius: "8px",
          height: screenIs({
            desktop: "auto",
            tablet: "auto",
            mobile: "auto",
          }),
          flexDirection: screenIs({
            default: "row",
            tablet: "column",
            mobile: "column",
          }),
          ...addFlex(
            screenIs({
              default: { x: "center", y: "top", direction: "down" },
              mobile: { x: "left", y: "top", direction: "down" },
            })
          ),
          ...addMargin({
            bottom: "40px",
          }),
          padding: "24px",
        }}
      >
        <div
          css={{
            flexDirection: screenIs({
              default: "row",
              tablet: "column",
              mobile: "column",
            }),
            ...addFlex(
              screenIs({
                default: { x: "center", y: "top" },
                mobile: { x: "left", y: "top", direction: "down" },
              })
            ),
            width: "100%",
          }}
        >
          <div
            css={{
              ...addFlex({ x: "left", y: "center" }),
              width: "100%",
            }}
          >
            <span
              css={{
                fontSize: "24px",
              }}
            >
              <strong
                css={{
                  fontWeight: "bold",
                  color: "#2F5673",
                }}
              >
                Sponsored
              </strong>{" "}
              &bull; {programMeta.program?.name || programMeta.programType.name}
            </span>
          </div>
          <div
            css={{
              ...addFlex({ x: "right", y: "center" }),
            }}
          >
            {programMeta.program?.logo && (
              <img
                css={{
                  maxHeight: "40px",
                  maxWidth: "100px",
                }}
                alt="logo"
                src={programMeta.program.logo}
              />
            )}
            <img
              css={{
                maxHeight: "40px",
                maxWidth: "100px",
                marginLeft: "20px",
              }}
              alt="logo"
              src={programMeta.programType.logo}
            />
          </div>
        </div>
        <div
          css={{
            width: "100%",
          }}
        >
          <Paragraph
            css={{
              fontSize: "14px",
              lineHeight: "20px",
              marginTop: "10px",
            }}
          >
            {programMeta.program
              ? parse(programMeta.program.summary || "", customParseHTMLOptions)
              : parse(
                  programMeta.programType.summary || "",
                  customParseHTMLOptions
                )}
          </Paragraph>
          {programMeta.program && (
            <HyperLink
              label={"Learn more"}
              linkTo={PATHS.program.replace(":id", programMeta.program.id)}
              css={LEARN_MORE_LINK_STYLES}
              fontSize={screenIs({ default: "14px" })}
            >
              Learn more
            </HyperLink>
          )}
        </div>
      </div>
    </Fragment>
  ) : null;
};

export default SelectedProgram;
