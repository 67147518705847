/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { addPadding, addMargin } from "utils";

const SHARED_DESCRIPTION_CSS = {
  width: "100%",
  color: "#fff",
  fontFamily: "'Roboto Mono', monospace",
  fontSize: "14px",
  lineHeight: "24px",
  wordBreak: "break-word",
} as const;

export const SUGGESTED_DESCRIPTION_CSS = css({
  ...SHARED_DESCRIPTION_CSS,
  ...{
    // vertical padding is 30 - ""<p/> margin"(14px)
    ...addPadding({ vertical: "16px", horizontal: "34px" }),
    ...addMargin({ top: "12px" }),
    background: "rgba(153, 153, 153, 0.15)",
    border: "2px dashed #979797",
    borderRadius: "16px",
  },
});

export const DESCRIPTION_CSS = css({
  ...SHARED_DESCRIPTION_CSS,
  ...{
    ...addPadding({ right: "10px" }),
  },
});
