import { create } from "zustand";

type ScreenSizeState = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isLargeMobile: boolean;
  isLandscapeTablet: boolean;
  isWideDesktop: boolean;
};

type ScreenSizeHelperOptionsFlexible<T_ValueType> = {
  mobile?: T_ValueType;
  tablet?: T_ValueType;
  desktop?: T_ValueType;
  default: T_ValueType;
};

type ScreenSizeHelperOptionsDefined<T_ValueType> = {
  mobile: T_ValueType;
  tablet: T_ValueType;
  desktop: T_ValueType;
};

export type ScreenSizeHelperOptions<T_ValueType> =
  | ScreenSizeHelperOptionsFlexible<T_ValueType>
  | ScreenSizeHelperOptionsDefined<T_ValueType>;

type ScreenSizeStore = ScreenSizeState & {
  updateValues: () => void;
  screenIs: <T_ValueType>(
    options: ScreenSizeHelperOptions<T_ValueType>
  ) => T_ValueType; // helper
};

const useScreenSizeStore = create<ScreenSizeStore>((set: any, get: any) => ({
  isMobile: window.innerWidth < 768,
  isTablet: window.innerWidth >= 768 && window.innerWidth < 992,
  isDesktop: window.innerWidth >= 992,
  isLargeMobile: window.innerWidth >= 576 && window.innerWidth < 768,
  isLandscapeTablet: window.innerWidth >= 992 && window.innerWidth < 1025,
  isWideDesktop: window.innerWidth >= 2100,
  screenIs: <T_ValueType>(
    options: ScreenSizeHelperOptions<T_ValueType>
  ): T_ValueType => {
    const { isMobile, isTablet, isDesktop } = get();

    const aScreenSizeIsDefined = isMobile || isTablet || isDesktop;

    if (aScreenSizeIsDefined) {
      if (isMobile && "mobile" in options && options.mobile !== undefined) {
        return options.mobile;
      }
      if (isTablet && "tablet" in options && options.tablet !== undefined) {
        return options.tablet;
      }
      if (isDesktop && "desktop" in options && options.desktop !== undefined) {
        return options.desktop;
      }
    }

    if ("default" in options) {
      return options.default;
    } else {
      if ("mobile" in options || "tablet" in options || "desktop" in options) {
        return options.mobile || options.tablet || options.desktop;
      }
    }
    throw new Error("Shouldn't be reachable");
  },
  updateValues: () => {
    set((state: any) => {
      const windowWidth = window.innerWidth;
      // console.log("isMobile", windowWidth < 768);
      return {
        screenIs: state.screenIs,
        isMobile: windowWidth < 768,
        isTablet: windowWidth >= 768 && windowWidth < 992,
        isDesktop: windowWidth >= 992,
        isLargeMobile: windowWidth >= 576 && windowWidth < 768,
        isLandscapeTablet: windowWidth >= 992 && windowWidth < 1025,
        isWideDesktop: windowWidth >= 2100,
      };
    });
  },
}));

export { useScreenSizeStore };
