import { useEffect } from "react";
import { SendableFormFormName } from "stores/utils/storeMakers/makeModalStore/types";
import { SuggestionTypes } from "stores/capabilitySuggestion/types";
import { Equipment, Service, Facility } from "utils/types";
import validateAlternative from "services/api/validateAlternative";
import { stackedModalsApi } from "stores/modals";
import { useCapabilitySuggestionStore, useFacilityStore } from "stores";

function getComponentNameFromAction(
  nextAction: SuggestionTypes
): SendableFormFormName {
  switch (nextAction) {
    case "description":
      return "submitDescription";
    case "images":
      return "submitImage";
    case "title":
      return "submitTitle";
    default:
      throw new Error(
        `Next action ${nextAction} is not  mapped to a form in getComponentNameFromAction`
      );
  }
}

function runNextAction() {
  const { replaceTopModal } = stackedModalsApi;
  const { getNextAction } = useCapabilitySuggestionStore.getState();
  const nextAction = getNextAction();
  if (nextAction) {
    // allow enough time for the previous modal to close
    // and show the new modal
    replaceTopModal({
      modalContentProps: {
        formName: getComponentNameFromAction(nextAction),
      },
      modalContentName: "sendableForm",
      modalType: "popup",
    });
  }
}

function showFacilityThankyouMessage() {
  const { facility } = useFacilityStore.getState();
  const { replaceTopModal } = stackedModalsApi;

  replaceTopModal({
    modalContentName: "formSentMessage",
    modalContentProps: {
      title: "Thanks for your contribution!",
      text: `This suggestion will be sent off to the Facility Manager ${
        facility ? `at  ${facility?.title} ` : ""
      }for review.`,
    },
    modalType: "popup",
  });
}

export function useShowThankyouAfterSubmittingEffect({
  isFormSubmittedSuccessfully,
  title,
  text,
}: {
  isFormSubmittedSuccessfully: boolean;
  title?: string;
  text?: string;
}) {
  useEffect(() => {
    if (isFormSubmittedSuccessfully) {
      const { replaceTopModal } = stackedModalsApi;

      replaceTopModal({
        modalContentName: "formSentMessage",
        modalContentProps: {
          title: title ?? "Thanks for your feedback",
          text: text,
        },
        modalType: "popup",
      });
    }
  }, [isFormSubmittedSuccessfully, text, title]);
}

export function useShowNextModalAfterSubmittingEffect(
  isFormSubmittedSuccessfully: boolean
) {
  useEffect(() => {
    if (isFormSubmittedSuccessfully) {
      const { getNextAction } = useCapabilitySuggestionStore.getState();
      if (!!getNextAction() === true) {
        runNextAction();
      } else {
        showFacilityThankyouMessage();
      }
    }
  }, [isFormSubmittedSuccessfully]);
}

function getCapability(
  currentId: string,
  facility?: Facility | null
):
  | (Service & {
      images?: string[];
    })
  | Equipment {
  if (!facility) {
    throw new Error(`No facility set in getCapability`);
  }
  const equipment = facility.equipment.find(
    (equipment) => equipment.id === currentId
  );

  const services = facility.services.find(
    (services) => services.id === currentId
  );
  if (equipment) {
    return equipment;
  }
  if (services) {
    return services;
  }
  throw new Error(`Couldn't find equipment or service with id: ${currentId}`);
}
async function submitValidateAlternative(
  {
    id,
    captcha,
  }: {
    id: string;
    captcha: string;
  },
  success: (success: boolean) => void
): Promise<void> {
  await validateAlternative({ id, captcha });
  success(true);
}

export {
  showFacilityThankyouMessage,
  getCapability,
  submitValidateAlternative,
};
