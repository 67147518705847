import { create } from "zustand";

// NOTE: Could use immer for easier object editing

export type NotificationType = "success" | "error" | "warning" | "info";

export const NOTIFICATION_HEIGHT = 85;
export const NOTIFICATION_SPACING = 10;
export const NOTIFICATION_Y_OFFSET = NOTIFICATION_HEIGHT + NOTIFICATION_SPACING;

export type NotificationItem = {
  id: string;
  title: string; // (if left blank, uses the default for the type) "Successfully registered"
  text: string;
  type: NotificationType;
  timeOpened: number; // timestamp
  setTimeoutId: number; // used for clearing the auto close timeout if closed manually
  measuredHeight: number;
};

const defaultTitlesByType = {
  success: "Success",
  error: "Error",
  warning: "Warning",
  info: "Info",
};

type SearchBarStoreState = {
  options: {
    maxAmount: number;
    defaultDuration: number;
  };
  notificationItems: { [notificationItemId: string]: NotificationItem };
  notificationIds: string[];
};

type SearchBarStore = SearchBarStoreState & {
  addNotification: (options: {
    title?: string;
    text: string;
    type?: NotificationType;
  }) => void;
  closeNotification: (notificationId: string) => void;
  updateNotificationHeight: (
    notificationId: string,
    measuredHeight: number
  ) => void;
};

function getStateWithRemovedNotification(
  state: SearchBarStore,
  notificationId: string
) {
  const editedNotificationIds = state.notificationIds.filter(
    (loopedId) => loopedId !== notificationId
  );

  if (state.notificationItems[notificationId]) {
    clearTimeout(state.notificationItems[notificationId].setTimeoutId);
  }

  const editedNotificationItems = { ...state.notificationItems };
  delete editedNotificationItems[notificationId];

  return {
    ...state,
    notificationIds: editedNotificationIds,
    notificationItems: editedNotificationItems,
  };
}

const useNotificationsStore = create<SearchBarStore>((set: any, get: any) => ({
  options: {
    maxAmount: 6,
    defaultDuration: 10000,
  },
  notificationItems: {},
  notificationIds: [],
  //
  addNotification: ({
    title,
    text,
    type = "info",
  }: {
    title?: string;
    text: string;
    type?: NotificationType;
  }) => {
    set((state: any) => {
      const timeOpened = Date.now();

      const newNotificationId = `${type}_${timeOpened}`;

      const editedNotificationIds = [
        ...state.notificationIds,
        newNotificationId,
      ];

      // maxAmount

      const setTimeoutId = window.setTimeout(() => {
        get().closeNotification(newNotificationId);
      }, state.options.defaultDuration);

      const editedNotificationItems = {
        ...state.notificationItems,
        [newNotificationId]: {
          id: newNotificationId,
          title: title || defaultTitlesByType[type],
          text,
          type,
          timeOpened,
          setTimeoutId,
          measuredHeight: NOTIFICATION_HEIGHT,
        },
      };

      if (editedNotificationIds.length > state.options.maxAmount) {
        return getStateWithRemovedNotification(
          {
            ...state,
            notificationIds: editedNotificationIds,
            notificationItems: editedNotificationItems,
          },
          editedNotificationIds[0]
        );
      }

      return {
        ...state,
        notificationIds: editedNotificationIds,
        notificationItems: editedNotificationItems,
      };
    });
  },
  closeNotification: (notificationId: string) => {
    set((state: any) => {
      return getStateWithRemovedNotification(state, notificationId);
    });
  },
  updateNotificationHeight: (notificationId: string, measuredHeight) => {
    set((state: any) => {
      const editedItem = {
        ...state.notificationItems[notificationId],
        measuredHeight: measuredHeight,
      };

      return {
        ...state,
        notificationItems: {
          ...state.notificationItems,
          [notificationId]: editedItem,
        },
      };
    });
  },
}));

export { useNotificationsStore };
