/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React from "react";

import LinkUnderline from "components/LinkUnderline";
import { FacilityLinkListItem } from "../";
import { addFlex, addMargin } from "utils";
import {
  useScreenSizeStore,
  useFacilityStore,
  userOrganisationStore,
} from "stores";
import trackUserEvent, { UserEventTypes } from "services/api/trackUserEvent";
import { FacilityCapabilitiesType } from "utils/types";
import { getValidationToken } from "services/validationToken";
import { ORGANISATION_TYPES } from "utils/constants";
import { createStyleSheet } from "utils/styles";

type Props = {
  linkItems: FacilityLinkListItem[];
  type: FacilityCapabilitiesType;
};

function getUserEventType(type: FacilityCapabilitiesType): UserEventTypes {
  switch (type) {
    case "Service":
      return "ServiceClick";
    case "Equipment":
      return "EquipmentClick";
    default:
      throw new Error(
        `Unexpected type in getUserEventType ${typeof type} ${type}`
      );
  }
}

const ColumnWithoutMemo: React.FC<Props> = ({ linkItems, type }) => {
  const { screenIs } = useScreenSizeStore();
  const facility = useFacilityStore((state: any) => state.facility);
  const providedOrganisationType = userOrganisationStore(
    (state: any) => state.providedOrganisationType
  );
  return (
    <div css={styles.container}>
      {linkItems.map(({ text, link, label, id }, index) => {
        const isLastItem = linkItems.length - 1 === index;

        return (
          <div
            key={id + label + link + index}
            css={{
              display: "inline-block",
              fontSize: screenIs({ default: "14px", mobile: "12px" }),
              fontWeight: "bold",
              ...addMargin({
                bottom: screenIs({
                  default: isLastItem ? "3px" : "15px",
                  mobile: isLastItem ? "3px" : "10px",
                }),
              }),
              maxWidth: "100%",
            }}
          >
            <LinkUnderline
              linkTo={link}
              label={label}
              automationId={`facility-capability-${index.toString()}`}
              onClick={async () => {
                if (facility) {
                  trackUserEvent({
                    type: getUserEventType(type),
                    institutionId: facility.institutionId || "",
                    facilityId: facility.id,
                    entityId: id,
                    userType:
                      providedOrganisationType || ORGANISATION_TYPES.none,
                    captcha:
                      (await getValidationToken(getUserEventType(type))) || "",
                  });
                }
              }}
            >
              {text}
            </LinkUnderline>
          </div>
        );
      })}
    </div>
  );
};

const styles = createStyleSheet({
  container: {
    ...addFlex({ x: "left", y: "top", direction: "down" }),
    flexGrow: 1,
    width: "100%",
    maxWidth: "100%",
  },
});

const Column: React.FC<Props> = React.memo(ColumnWithoutMemo);
export default Column;
