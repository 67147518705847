/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { useState, useCallback } from "react";
import Paragraph from "components/Paragraph";
import BasicButton from "components/BasicButton";
import FeedbackRadioInput from "components/inputs/FeedbackRadioInput";
import { FeedbackType } from "stores/utils/storeMakers/makeModalStore/types";
import useStackedModals from "stores/modals";
import tenantConfig from "tenants";

const FEEDBACK_OPTIONS_BY_VALUE = {
  notRelevant: {
    value: "Results not relevant",
    label: "Results not relevant",
  },
  insufficient: {
    value: "Insufficient results",
    label: "Insufficient results",
  },
  other: {
    value: "Other",
    label: "Other…",
  },
  profileIncomplete: {
    value: "Profile looks incomplete",
    label: "Profile looks incomplete",
  },
  doesNotMatchSearch: {
    value: "Does not match my search",
    label: "Doesn’t match my search",
  },
};

type LimitedFeedbackType = keyof typeof FEEDBACK_OPTIONS_BY_VALUE;

type Props = {
  headline: string;
  buttonText: string;
  className?: string;
  feedbackTypeOptions: LimitedFeedbackType[];
};

const FeedBack: React.FC<Props> = ({
  headline,
  buttonText,
  className,
  feedbackTypeOptions,
}) => {
  const { openNewModal } = useStackedModals();
  const theme = useTheme();

  const [activeOption, setActiveOption] = useState(
    undefined as LimitedFeedbackType | undefined
  );

  const handleFeedbackSubmission = useCallback(() => {
    openNewModal({
      modalContentProps: {
        formName: "sendFeedback" as "sendFeedback",
        feedBackTypeText: activeOption
          ? FEEDBACK_OPTIONS_BY_VALUE[activeOption]?.label
          : "",
        feedBackType: activeOption as FeedbackType,
      },
      modalContentName: "sendableForm",
      modalType: "popup",
    });
  }, [activeOption, openNewModal]);

  const noActiveOption = activeOption === undefined;

  return (
    <div
      css={{
        background: theme.softBackground,
        borderRadius: "8px",
        padding: "30px 40px",
      }}
      className={className}
    >
      <p
        css={{
          fontWeight: 800,
          textTransform: "uppercase",
          letterSpacing: ".5px",
          color: "#2f2f2f",
          fontSize: "12px",
          lineHeight: "15px",
          margin: 0,
        }}
      >
        {process.env.REACT_APP_TENANT === "monashUni"
          ? tenantConfig.text.feedback.titleTenant
          : tenantConfig.text.feedback.title}
      </p>
      <Paragraph bold css={{ margin: "4px 0 10px 0 " }}>
        {headline}
      </Paragraph>
      <FeedbackRadioInput
        inputId="feedback"
        value={activeOption as LimitedFeedbackType}
        onChange={(newValue) =>
          setActiveOption(newValue as LimitedFeedbackType)
        }
        options={feedbackTypeOptions.map(
          (feedbackType) => FEEDBACK_OPTIONS_BY_VALUE[feedbackType]
        )}
      />
      <BasicButton
        label="Submit an enquiry"
        onClick={() => {
          handleFeedbackSubmission();
        }}
        css={{
          label: "submit-an-enquiry",
          border: `2px solid ${
            noActiveOption
              ? theme.feedbackButtonInactive
              : theme.feedbackButtonActive
          }`,
          color: `${
            noActiveOption
              ? theme.feedbackButtonInactive
              : theme.feedbackButtonActive
          }`,
          width: "100%",
          padding: "10px 0",
          fontWeight: "bold",
          borderRadius: "8px",
          marginTop: "10px",
        }}
        disabled={noActiveOption}
      >
        {buttonText}
      </BasicButton>
    </div>
  );
};

export default FeedBack;
