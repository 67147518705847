/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React from "react";

type Props = {
  type: "description" | "title";
  children: React.ReactNode | string;
};

const SHARED_CSS = {
  background: "#1D1D1D",
  padding: "12px",
  color: "#FFFFFF",
  overflowY: "auto",
  height: "325px",
} as const;

const DataWrapper: React.FC<Props> = ({ children, type }: any) => {
  const theme = useTheme();
  const wrapperCss = css(
    type === "description"
      ? {
          ...SHARED_CSS,
          ...{
            fontFamily: "'Roboto Mono', monospace",
            fontSize: "14px",
            lineHeight: "24px",
          },
        }
      : {
          ...SHARED_CSS,
          ...{
            fontFamily: theme.fonts.main,
            fontSize: "28px",
            lineHeight: "36px",
            fontWeight: "bold",
          },
        }
  );
  return <div css={wrapperCss}>{children}</div>;
};

export default DataWrapper;
