/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React from "react";
import { useHistory } from "react-router-dom";
import BasicIcon from "components/BasicIcon";
import LinkUnderline from "components/LinkUnderline";
import { useModalStore } from "stores";
import { shallow } from "zustand/shallow";
import { addFlex, addPadding } from "utils";
import BasicButton from "components/BasicButton";
import { MobileNavigationProps } from "stores/utils/storeMakers/makeModalStore/types";

type Props = {} & MobileNavigationProps;

const MobileNavigation: React.FC<Props> = ({ navigationLinks }) => {
  const [closeModal] = useModalStore(
    (state: any) => [state.closeModal],
    shallow
  );

  const history = useHistory();
  return (
    <nav
      css={{
        width: "100%",
        height: "100%",
        ...addFlex({ x: "left", y: "top" }),
        flexDirection: "column",
      }}
    >
      {navigationLinks &&
        navigationLinks.map(({ icon, linkText, label, linkTo }, index) => (
          <BasicButton
            automationId={`top-nav-${linkText
              .replace(/\s/g, "-")
              .toLowerCase()}`}
            label={label}
            onClick={() => {
              history.push(linkTo);
              closeModal();
            }}
            innerCss={{
              width: "100%",
              borderBottom: `1px solid ${
                index === navigationLinks.length - 1 ? "transparent" : "#949494"
              }`,
              ...addPadding({ vertical: "20px", horizontal: "25px" }),
              ...addFlex({ x: "left", y: "center" }),
            }}
            css={{
              width: "100%",
              ...addFlex({ x: "left", y: "center" }),
            }}
          >
            <BasicIcon name={icon} size={22} color={`#fff`} />
            <div
              css={{
                color: "#fff",
                display: "inline-flex",
                maxWidth: "100%",
                fontSize: "20px",
                fontWeight: "bold",
                padding: "0 0 0 15px",
              }}
            >
              {linkText}
            </div>
          </BasicButton>
        ))}
    </nav>
  );
};

export default MobileNavigation;
