/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { useState, useEffect } from "react";
import BasicIcon from "components/BasicIcon";
import BasicButton from "components/BasicButton";
import { useModalStore } from "stores";
import { shallow } from "zustand/shallow";
import { addFlex, addSize } from "utils";

type Props = {
  onClick: (menuOpen: boolean) => void;
};

const HamburgerMenu: React.FC<Props> = ({ onClick }) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [animationState] = useModalStore(
    (state: any) => [state.animationState],
    shallow
  );
  useEffect(() => {
    if (animationState === "closed" || animationState === "closing") {
      setMenuOpen(false);
    }
  }, [animationState]);

  return (
    <BasicButton
      onClick={() => {
        setMenuOpen(!menuOpen);
        onClick(!menuOpen);
      }}
      aria-haspopup="true"
      aria-expanded={menuOpen}
      label={`${menuOpen ? `close` : `open`} menu`}
      css={{
        cursor: "pointer",
        marginRight: "0px",
        ...addSize({ size: "45px" }),
      }}
      innerCss={{
        cursor: "pointer",
        ...addSize({ size: "45px" }),
        ...addFlex({ x: "center", y: "center" }),
      }}
    >
      <BasicIcon
        name={menuOpen ? `Cross` : `Hamburger`}
        color={`#000`}
        size={menuOpen ? 16 : 24}
        css={{
          ...addFlex({ x: "center", y: "center" }),
        }}
      />
    </BasicButton>
  );
};

export default HamburgerMenu;
