import { gql } from "apollo-boost";
import Api from "services/api";

export type Trend = {
  searchTerm: string;
  numberOfResults: number;
};

type Trends = {
  trends: Trend[];
};

const LIMIT_RETURNED_TRENDS = 15;

const getTrends = () =>
  Api.query<Trends>({
    query: gql`
      {
        trends(limit: ${LIMIT_RETURNED_TRENDS}) {
          searchTerm
          numberOfResults
        }
      }
    `,
  }).then((result: any) => result.data);

export default getTrends;
