/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { useEffect, useState, useCallback } from "react";
import keyBy from "lodash.keyby";
import getAllFacilities from "services/api/allFacilities";
import { useScreenSizeStore } from "stores";
import LoadingSpinner from "components/LoadingSpinner";
import FacilitiesFilter from "components/FacilitiesFilter";
import SearchResult from "components/SearchResult";
import SearchSummary from "components/SearchSummary";
import { AllFacilitiesResponse } from "utils/types";
import StaticSearchBar from "components/StaticSearchBar";

type Props = {};

const AllFacilitiesContent: React.FC<Props> = () => {
  const [facilities, setFacilities] = useState<null | AllFacilitiesResponse[]>(
    null
  );
  const [activeInstitutionIds, setActiveInstitutionIds] = useState<string[]>(
    []
  );

  const [activeFacilitiesIds, setActiveFacilitiesIds] = useState<string[]>([]);

  const [textSearch, setTextSearch] = useState<string>("");

  const { screenIs, isWideDesktop } = useScreenSizeStore();

  const toggleActiveInstitution = useCallback(
    (toggledId: string, newIsActiveValue: boolean) => {
      const isCurrentlyActive = activeInstitutionIds.includes(toggledId);

      if (isCurrentlyActive && newIsActiveValue === false) {
        setActiveInstitutionIds(
          activeInstitutionIds.filter((id) => id !== toggledId)
        );
      }

      if (!isCurrentlyActive && newIsActiveValue === true) {
        setActiveInstitutionIds([...activeInstitutionIds, toggledId]);
      }
    },
    [activeInstitutionIds]
  );
  useEffect(() => {
    if (!facilities) return;
    setActiveFacilitiesIds(
      textSearch.length === 0
        ? facilities.map(({ facility }) => facility.id)
        : facilities
            .filter(
              ({ facility }) =>
                facility.title
                  .toUpperCase()
                  .indexOf(textSearch.toUpperCase()) !== -1
            )
            .map(({ facility }) => facility.id)
    );
  }, [textSearch, facilities]);

  useEffect(() => {
    getAllFacilities().then((result: any) => {
      setFacilities(
        result.allFacilities.sort((a: any, b: any) =>
          a.facility.title.localeCompare(b.facility.title)
        )
      );
      setActiveInstitutionIds(
        result.allFacilities.map(({ institution }: any) => institution.id)
      );
    });
  }, []);

  if (facilities === null) {
    return <LoadingSpinner height="calc(100vh - 390px)" />;
  }

  return (
    <div
      css={{
        position: "relative",
        width: "100%",
        display: "flex",
        flexDirection: screenIs({ default: "column", desktop: "row" }),
        justifyContent: "space-between",
        padding: "32px 0",
      }}
    >
      {process.env.REACT_APP_TENANT !== "monashUni" && (
        <div
          css={{
            width: screenIs({
              default: "100%",
              desktop: "392px",
            }),
            marginRight: screenIs({
              default: "32px",
            }),
          }}
        >
          <FacilitiesFilter
            activeInstitutionIds={activeInstitutionIds}
            toggleActiveInstitution={toggleActiveInstitution}
            searchResultIds={facilities.map(({ facility }) => facility.id)}
            facilityCollection={keyBy(
              facilities,
              ({ facility }) => facility.id
            )}
          ></FacilitiesFilter>
        </div>
      )}
      <div
        data-automation={`facility-links`}
        css={{
          position: "relative",
          width: screenIs({
            default: "100%",
            desktop: isWideDesktop ? "1155px" : "100%",
          }),
        }}
      >
        <StaticSearchBar
          setTextSearch={setTextSearch}
          textSearch={textSearch}
        />
        <SearchSummary
          numberOfSearchResults={
            facilities.filter(
              ({ facility, institution }) =>
                activeInstitutionIds.indexOf(institution.id) !== -1 &&
                activeFacilitiesIds.indexOf(facility.id) !== -1
            ).length
          }
        />
        {facilities.map(({ facility, institution }, index) =>
          activeInstitutionIds.indexOf(institution.id) !== -1 &&
          activeFacilitiesIds.indexOf(facility.id) !== -1 ? (
            <div key={facility.id}>
              <SearchResult
                institutionName={institution.name}
                title={facility.title}
                location={facility.location}
                position={index.toString()}
                institutionId={institution.id}
                id={facility.id}
                key={facility.id}
                program={null}
              ></SearchResult>
            </div>
          ) : (
            ``
          )
        )}
      </div>
    </div>
  );
};

export default AllFacilitiesContent;
