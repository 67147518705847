/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { useEffect } from "react";
import Paragraph from "components/Paragraph";
import BasicButton from "components/BasicButton";
import {
  useFacilityStore,
  useClickedCapabilitiesStore,
  useSearchResultsStore,
  useCapabilitySuggestionStore,
} from "stores";
import { addFlex, addPadding } from "utils";
import useStackedModals from "stores/modals";
import { SearchResultsStore } from "stores/searchResults";

type Props = {};

const SubmitEnquiry: React.FC<Props> = () => {
  const facility = useFacilityStore((state: any) => state.facility);
  const { openNewModal } = useStackedModals();
  const theme = useTheme();
  const searchTerm = useSearchResultsStore(
    (state: SearchResultsStore) => state.searchTerm
  );

  const [hasClickedMisMatchedCapabilities] = useClickedCapabilitiesStore(
    ({ hasClickedMisMatchedCapabilities }: any) => [
      hasClickedMisMatchedCapabilities,
    ]
  );
  const { resetCapabilitySuggestion } = useCapabilitySuggestionStore();
  useEffect(() => {
    return () => {
      resetCapabilitySuggestion();
    };
  }, [resetCapabilitySuggestion]);

  return (
    <div
      css={{
        ...addFlex({ x: "center", y: "center", direction: "down" }),
        background: theme.mainColor,
        textAlign: "center",
        color: "#fff",
        width: "100%",
        padding: "20px",
        flexShrink: 0,
      }}
    >
      {facility && <Paragraph bold>Located at: {facility.title}</Paragraph>}
      <BasicButton
        onClick={() => {
          if (!!searchTerm && hasClickedMisMatchedCapabilities()) {
            openNewModal({
              modalContentProps: {
                formName: "searchTermToTag",
                nextAction: "submitEnquiry",
              },
              modalContentName: "sendableForm",
              modalType: "popup",
            });
          } else {
            openNewModal({
              modalContentProps: {
                formName: "submitEnquiry",
              },
              modalContentName: "sendableForm",
              modalType: "popup",
            });
          }
        }}
        label={`Submit an enquiry`}
        css={{
          border: "2px solid #fff",
          borderRadius: "8px",
          maxWidth: "312px",
          width: "100%",
          marginTop: "10px",
          height: "50px",
        }}
        innerCss={{
          ...addFlex({ x: "center", y: "center" }),
          ...addPadding({ horizontal: "5px" }),
          borderRadius: "8px",
          whiteSpace: "nowrap",
          fontWeight: "bold",
          color: "white",
          width: "100%",
        }}
      >
        Submit an enquiry
      </BasicButton>
    </div>
  );
};

export default SubmitEnquiry;
