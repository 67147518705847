/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

const Calendar: React.FC = () => {
  return (
    <svg
      width="64"
      height="70"
      viewBox="0 0 64 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7837_5011)">
        <mask
          id="mask0_7837_5011"
          maskUnits="userSpaceOnUse"
          x="-3"
          y="0"
          width="70"
          height="70"
        >
          <path d="M-3 0H67V70H-3V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_7837_5011)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 64.8579C0 67.6977 2.33219 70 5.20892 70H58.7911C61.6678 70 64 67.6977 64 64.8579V48.0284C64 47.4604 63.5336 47 62.9582 47C62.3829 47 61.9164 47.4604 61.9164 48.0284V64.8579C61.9164 66.5618 60.5171 67.9432 58.7911 67.9432H5.20892C3.48291 67.9432 2.08357 66.5618 2.08357 64.8579V59.1354C2.08357 58.5674 1.61715 58.1069 1.04178 58.1069C0.466423 58.1069 0 58.5674 0 59.1354V64.8579Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 60.884C0 63.7095 2.33524 66 5.21586 66H47.4545C48.8379 66 50.1645 65.461 51.1428 64.5015L62.4722 53.3891C63.4505 52.4297 64 51.1283 64 49.7714V10.116C64 7.2905 61.6647 5 58.784 5H5.21586C2.33524 5 0 7.29052 0 10.116V60.884ZM5.21586 63.9563C3.48597 63.9563 2.08357 62.5808 2.08357 60.884V10.116C2.08357 8.4192 3.48597 7.04366 5.21586 7.04366H58.784C60.5141 7.04366 61.9164 8.41922 61.9164 10.116V49.7714C61.9164 50.5863 61.5864 51.3678 60.999 51.9439L60.9989 51.9439L49.6694 63.0564C49.0819 63.6327 48.2853 63.9563 47.4545 63.9563H5.21586Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M45 64.9743C45 65.5408 45.4592 66 46.0257 66C48.4519 66 50.4188 64.0333 50.4188 61.6069V54.7603C50.4188 53.467 51.4671 52.4186 52.7603 52.4186H59.6071C62.0333 52.4186 64 50.4519 64 48.0257C64 47.4592 63.5408 47 62.9743 47C62.4078 47 61.9486 47.4592 61.9486 48.0257C61.9486 49.3189 60.9003 50.3672 59.6071 50.3672H52.7603C50.334 50.3672 48.3673 52.3341 48.3673 54.7603V61.6069C48.3673 62.9003 47.3189 63.9486 46.0257 63.9486C45.4592 63.9486 45 64.4078 45 64.9743Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 20C0 20.5523 0.48583 21 1.08513 21H13.9149C14.5142 21 15 20.5523 15 20C15 19.4477 14.5142 19 13.9149 19H1.08513C0.48583 19 0 19.4477 0 20Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17 20C17 20.5523 17.4614 21 18.0305 21H62.9695C63.5386 21 64 20.5523 64 20C64 19.4477 63.5386 19 62.9695 19H18.0305C17.4614 19 17 19.4477 17 20Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 9.8503C10 11.5899 11.457 13 13.2544 13C13.8454 13 14.3245 12.5363 14.3245 11.9642C14.3245 11.3922 13.8454 10.9285 13.2544 10.9285C12.6391 10.9285 12.1404 10.4458 12.1404 9.8503V3.1497C12.1404 2.55422 12.6391 2.07154 13.2544 2.07154H13.7456C14.3609 2.07154 14.8596 2.55422 14.8596 3.1497V5.76909C14.8596 6.34113 15.3388 6.80486 15.9298 6.80486C16.5209 6.80486 17 6.34113 17 5.76909V3.1497C17 1.41015 15.543 0 13.7456 0H13.2544C11.457 0 10 1.41015 10 3.1497V9.8503Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M47 9.8503C47 11.5899 48.457 13 50.2544 13C50.8454 13 51.3245 12.5363 51.3245 11.9642C51.3245 11.3922 50.8454 10.9285 50.2544 10.9285C49.6391 10.9285 49.1404 10.4458 49.1404 9.8503V3.1497C49.1404 2.55422 49.6391 2.07154 50.2544 2.07154H50.7456C51.3609 2.07154 51.8596 2.55422 51.8596 3.1497V5.76909C51.8596 6.34113 52.3388 6.80486 52.9298 6.80486C53.5209 6.80486 54 6.34113 54 5.76909V3.1497C54 1.41015 52.543 0 50.7456 0H50.2544C48.457 0 47 1.41015 47 3.1497V9.8503Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 31.9725C7 33.0923 7.80687 34 8.80221 34H13.1978C14.1931 34 15 33.0923 15 31.9725V27.0275C15 25.9077 14.1931 25 13.1978 25H8.80221C7.80687 25 7 25.9077 7 27.0275V31.9725ZM8.84615 31.9231V27.0769H13.1538V31.9231H8.84615Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21 31.9725C21 33.0923 21.9077 34 23.0275 34H27.9725C29.0923 34 30 33.0923 30 31.9725V27.0275C30 25.9077 29.0923 25 27.9725 25H23.0275C21.9077 25 21 25.9077 21 27.0275V31.9725ZM23.0769 31.9231V27.0769H27.9231V31.9231H23.0769Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M34 31.9725C34 33.0923 34.9077 34 36.0275 34H40.9725C42.0923 34 43 33.0923 43 31.9725V27.0275C43 25.9077 42.0923 25 40.9725 25H36.0275C34.9077 25 34 25.9077 34 27.0275V31.9725ZM36.0769 31.9231V27.0769H40.9231V31.9231H36.0769Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M49 31.9728C49 33.0925 49.8069 34.0002 50.8022 34.0002H55.1978C56.1931 34.0002 57 33.0925 57 31.9728V27.0277C57 25.908 56.1931 25.0002 55.1978 25.0002H50.8022C49.8069 25.0002 49 25.908 49 27.0277V31.9728ZM50.8462 31.9233V27.0772H55.1538V31.9233H50.8462Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 44.9725C7 46.0923 7.80687 47 8.80221 47H13.1978C14.1931 47 15 46.0923 15 44.9725V40.0275C15 38.9077 14.1931 38 13.1978 38H8.80221C7.80687 38 7 38.9077 7 40.0275V44.9725ZM8.84615 44.9231V40.0769H13.1538V44.9231H8.84615Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21 44.9725C21 46.0923 21.9077 47 23.0275 47H27.9725C29.0923 47 30 46.0923 30 44.9725V40.0275C30 38.9077 29.0923 38 27.9725 38H23.0275C21.9077 38 21 38.9077 21 40.0275V44.9725ZM23.0769 44.9231V40.0769H27.9231V44.9231H23.0769Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M34 44.9725C34 46.0923 34.9077 47 36.0275 47H40.9725C42.0923 47 43 46.0923 43 44.9725V40.0275C43 38.9077 42.0923 38 40.9725 38H36.0275C34.9077 38 34 38.9077 34 40.0275V44.9725ZM36.0769 44.9231V40.0769H40.9231V44.9231H36.0769Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M49 44.9723C49 46.092 49.8069 46.9998 50.8022 46.9998H55.1978C56.1931 46.9998 57 46.092 57 44.9723V40.0272C57 38.9075 56.1931 37.9998 55.1978 37.9998H50.8022C49.8069 37.9998 49 38.9075 49 40.0272V44.9723ZM50.8462 44.9228V40.0767H55.1538V44.9228H50.8462Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 57.9725C7 59.0923 7.80687 60 8.80221 60H13.1978C14.1931 60 15 59.0923 15 57.9725V53.0275C15 51.9077 14.1931 51 13.1978 51H8.80221C7.80687 51 7 51.9077 7 53.0275V57.9725ZM8.84615 57.9231V53.0769H13.1538V57.9231H8.84615Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21 57.9725C21 59.0923 21.9077 60 23.0275 60H27.9725C29.0923 60 30 59.0923 30 57.9725V53.0275C30 51.9077 29.0923 51 27.9725 51H23.0275C21.9077 51 21 51.9077 21 53.0275V57.9725ZM23.0769 57.9231V53.0769H27.9231V57.9231H23.0769Z"
            fill="#006DAE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M34 57.9725C34 59.0923 34.9077 60 36.0275 60H40.9725C42.0923 60 43 59.0923 43 57.9725V53.0275C43 51.9075 42.0922 51 40.9725 51H36.0275C34.9078 51 34 51.9075 34 53.0275V54.4535C34 55.027 34.4649 55.492 35.0385 55.492C35.612 55.492 36.0769 55.027 36.0769 54.4535V53.0769H40.9231V57.9231H36.0758C36.05 57.3725 35.5954 56.934 35.0385 56.934C34.4649 56.934 34 57.399 34 57.9725Z"
            fill="#006DAE"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_7837_5011">
          <rect width="64" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Calendar;
