/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, {
  useEffect,
  useCallback,
  useRef,
  useState,
  useLayoutEffect,
} from "react";
import MonashCustomSearchBar from "components/MonashCustomSearchBar";
import { animated, useSpring } from "react-spring";
import {
  useSearchBarStore,
  useScreenSizeStore,
  useSearchResultsStore,
} from "stores";
import AutoHeightAnimator from "components/AutoHeightAnimator";
import { useLocation } from "react-router-dom";
import SearchIndexSummary from "./SearchIndexSummary";

import PATHS from "utils/routes";
import { addFlex, addPadding, addSize } from "utils";
import { shallow } from "zustand/shallow";
import { useThrottledResizeObserver } from "hooks";
import { hasFeature } from "services/featureToggle";

type Props = {};

const canShowSearchHints =
  hasFeature("trendingSearches") || hasFeature("autoCompleteSearch");

const SearchContent: React.FC<Props> = () => {
  const { pathname, hash } = useLocation();
  const theme = useTheme();

  const [showCollapsedSearch, setShowCollapsedSearch] = useState(
    pathname === PATHS.search
  );

  const { isDesktop, isMobile, screenIs } = useScreenSizeStore();
  const [
    isFocused,
    searchText,
    updateSearchText,
    updateSearchHash,
    setMeasuredSearchBar,
    submittableHintText,
  ] = useSearchBarStore(
    (state: any) => [
      state.isFocused,
      state.searchText,
      state.updateSearchText,
      state.updateSearchHash,
      state.setMeasuredSearchBar,
      state.submittableHintText,
    ],
    shallow
  );
  const [searchResultIds, fetchSearchResults] = useSearchResultsStore(
    (state: any) => [state.searchResultIds, state.fetchSearchResults],
    shallow
  );

  const allContentRef = useRef<HTMLDivElement>(null);
  const searchBarHolderRef = useRef<HTMLDivElement>(null);
  const searchBarRef = useRef<HTMLFormElement>(null);

  const { width, height } = useThrottledResizeObserver({
    ref: allContentRef,
    wait: 500,
  });

  const { width: documentWidth } = useThrottledResizeObserver({
    ref: { current: window.document.body },
    wait: 1500,
  });

  const remeasureSearchBar = useCallback(() => {
    if (
      canShowSearchHints &&
      searchBarHolderRef.current &&
      searchBarRef.current
    ) {
      const outerBoundingRect =
        searchBarHolderRef.current.getBoundingClientRect();
      const innerBoundingRect = searchBarRef.current.getBoundingClientRect();

      setMeasuredSearchBar({
        width: outerBoundingRect.width,
        left: innerBoundingRect.left,
        bottom: innerBoundingRect.bottom + window.pageYOffset,
      });
    }
  }, [setMeasuredSearchBar]);

  useLayoutEffect(remeasureSearchBar, [
    width,
    height,
    documentWidth,
    setMeasuredSearchBar,
    isFocused,
  ]);

  const [springProps, setSpringProps] = useSpring(() => ({
    searchExpandAmount: 1,
    config: { mass: 2, tension: 700, friction: 75 },
    from: {
      searchExpandAmount: 1,
    },
  }));

  const homeScreenImageOpacity = isFocused || searchText.length > 0 ? 0.7 : 1;

  useEffect(() => {
    if (pathname === PATHS.search) {
      const editedHash = decodeURI(hash.replace("#", ""));
      updateSearchHash(editedHash);
      if (!searchResultIds.length) {
        fetchSearchResults(editedHash);
      }
    }
  }, [
    fetchSearchResults,
    hash,
    pathname,
    searchResultIds.length,
    updateSearchHash,
    updateSearchText,
  ]);

  useEffect(() => {
    if (pathname === PATHS.search) {
      setShowCollapsedSearch(true);
    } else if (pathname === PATHS.index) {
      setShowCollapsedSearch(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (isDesktop && pathname === PATHS.index) {
      setSpringProps({
        searchExpandAmount: isFocused || searchText.length > 0 ? 1 : 0.7,
      });
    } else {
      setSpringProps({
        searchExpandAmount: 1,
      });
    }
  }, [isDesktop, isFocused, pathname, searchText, setSpringProps]);

  const imageWidth = screenIs({ default: 274, tablet: 210, mobile: 118 });
  const imageHeight = screenIs({ default: 350, tablet: 177, mobile: 123 });
  const imageMargin = screenIs({ default: 86, tablet: 50, mobile: 50 });

  const descriptionContent = (
    <h3
      css={{
        label: "subtitle",
        position: "relative",
        color: "#D4D4D4",
        marginTop: 0,
        marginBottom: 0,
        paddingTop: isMobile ? "5px" : "22px",
        paddingBottom: isMobile ? "20px" : "5px",
        width: screenIs({
          default: `calc(100% - ${imageWidth + imageMargin * 0.8}px)`,
          mobile: "100%",
        }),
      }}
    >
      Discover Research Equipment and Services
    </h3>
  );

  const horizontalPadding = screenIs({
    default: "76px",
    tablet: "25px",
    mobile: "15px",
  });

  return (
    <div
      ref={allContentRef}
      css={{
        label: "TopFocusBox-SearchContent",
        ...addFlex({ x: "left", y: "center", direction: "down" }),
        ...addPadding({ horizontal: horizontalPadding, vertical: "0" }),
        width: "100%",
        fontFamily: `${theme.fonts.heading}`,
      }}
    >
      <div css={{ width: "100%", position: "relative" }}>
        <AutoHeightAnimator
          initialHeight={0}
          faster={true} // needs to finish before the TopFocusBox measures height
          manualUpdateKey={
            showCollapsedSearch ? "showCollapsedSearch" : "notOnSearchResults"
          }
        >
          {!showCollapsedSearch && (
            <div
              css={{
                label: "home-topContent",
                width: "100%",
                position: "relative",
                paddingTop: screenIs({
                  default: "189px",
                  tablet: "81px",
                  mobile: "45px",
                }),
              }}
            >
              <h1
                css={{
                  label: "home-title",
                  position: "relative",
                  color: "#FFFFFF",
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Welcome to Monash Capability Finder
              </h1>
              {!isMobile && descriptionContent}
            </div>
          )}
        </AutoHeightAnimator>
      </div>
      <div
        ref={searchBarHolderRef}
        css={{ position: "relative", width: "100%" }}
      >
        <animated.div
          role="combobox"
          aria-haspopup="listbox"
          aria-owns="TopFocusBox-SearchContent-popupContainer-currentListbox"
          aria-expanded={isFocused ? "true" : "false"}
          css={{
            label: "search-bar-holder",
            position: "relative",
            paddingTop: screenIs({
              default: "45px",
              tablet: "27px",
              mobile: "20px",
            }),
            paddingBottom: screenIs({
              default: "45px",
              tablet: "27px",
              mobile: "20px",
            }),
          }}
          style={{
            width: springProps.searchExpandAmount.to(
              (theValue) => theValue * 100 + "%"
            ),
          }}
        >
          {/* <SearchBar
            ref={searchBarRef}
            activeHintTerm={submittableHintText || undefined}
          /> */}
          <MonashCustomSearchBar
            ref={searchBarRef}
            activeHintTerm={submittableHintText || undefined}
          />
        </animated.div>
      </div>
      {!showCollapsedSearch && (
        <SearchIndexSummary
          descriptionContent={descriptionContent}
          imageWidth={imageWidth}
          imageMargin={imageMargin}
        />
      )}
    </div>
  );
};

export default SearchContent;
