/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React, { useEffect, useState } from "react";
import scrollIntoView from "scroll-into-view-if-needed";
import { addFlex, addMargin, addPadding, addSize } from "utils";
import { useScreenSizeStore } from "stores";
import getAdminReport, { AdminReportItem } from "services/api/adminReport";
import { getValidationToken } from "services/validationToken";
import { useLocation } from "react-router-dom";
import LoadingSpinner from "components/LoadingSpinner";
import FadeDiv from "components/FadeDiv";
import SearchTerm from "./SearchTerm";
import NumberCount from "./NumberCount";
import PageNotFound from "screens/PageNotFoundContent";
import { createStyleSheet } from "utils/styles";
import CaptchaNotice from "components/CaptchaNotice";
import queryParam from "query-string";

type Props = {};

const SEARCH_TERM_TITLES = ["Top 3 trends", "Top 3 searches without result"];
const HEADER_WITH_FOOTER_SIZES = {
  default: 311,
  mobile: 191,
};

const AdminReportContent: React.FC<Props> = () => {
  // NOTE: if title is meant as the top focus box title then it might need to be saved to a zustand
  const [adminReport, setAdminReport] = useState(
    null as null | AdminReportItem[]
  );
  const [isLoading, setIsLoading] = useState(true);
  const { search: urlParameters } = useLocation();

  useEffect(() => {
    // scroll to the top when opening the admin report page
    scrollIntoView(document.body, {
      scrollMode: "if-needed",
      block: "start",
      behavior: "smooth",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function updateAdminReport() {
      try {
        const queryObject = queryParam.parse(urlParameters) as {
          email: string;
          hash: string;
          timestamp: string;
          tenantId: string;
        };

        if (!queryObject.timestamp || !queryObject.email || !queryObject.hash) {
          throw new Error("No or incorrect query string");
        }

        const validationToken = await getValidationToken(`getAdminReport`);

        const result = await getAdminReport({
          email: queryObject.email,
          hash: queryObject.hash,
          timestamp: queryObject.timestamp,
          captcha: validationToken || "",
          tenantId: queryObject.tenantId,
        });

        const resultAdminReport = result.data.getAdminReport;

        const reorderedAdminReport = resultAdminReport.sort(
          (a: any, b: any) => {
            return SEARCH_TERM_TITLES.includes(a.title) ? -1 : 1;
          }
        );

        setAdminReport(reorderedAdminReport);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
    updateAdminReport();
  }, [urlParameters]);

  const { screenIs } = useScreenSizeStore();

  const show404 = !isLoading && !adminReport;

  return (
    <div
      css={{
        ...styles.container,
        ...addPadding(
          screenIs({
            default: { default: "50px" },
            mobile: { horizontal: "0px", vertical: "30px" },
          })
        ),
        minHeight: `calc(100vh - ${screenIs({
          default: HEADER_WITH_FOOTER_SIZES.default,
          mobile: HEADER_WITH_FOOTER_SIZES.mobile,
        })}px)`,
      }}
    >
      <div css={styles.widthLimiter}>
        <FadeDiv isVisible={show404} css={styles.noResultHolder}>
          <PageNotFound description={""} />
        </FadeDiv>
        <LoadingSpinner height="calc(100vh - 390px)" isLoading={isLoading} />
        <FadeDiv isVisible={!!adminReport} css={styles.adminReportMainContent}>
          {adminReport?.map((item) => {
            if (SEARCH_TERM_TITLES.includes(item.title)) {
              return (
                <div css={styles.searchResultsRow} key={item.title}>
                  <div css={styles.searchResultsRowTitle}>{item.title}</div>
                  <div css={styles.searchResultsRowSearchItems}>
                    {item.content.split(", ").map((searchTerm) => (
                      <div
                        css={styles.searchResultsRowSearchTermHolder}
                        key={searchTerm}
                      >
                        <SearchTerm searchTerm={searchTerm} />
                      </div>
                    ))}
                  </div>
                </div>
              );
            }

            return (
              <div css={styles.numberRow} key={item.title}>
                <div css={styles.numberRowTitle}>{item.title}</div>
                <NumberCount count={item.content} />
              </div>
            );
          })}
        </FadeDiv>
      </div>
      <CaptchaNotice
        css={{
          textAlign: "center",
          ...addMargin({
            top: "30px",
            bottom: screenIs({ default: "-30px", mobile: "-20px" }),
          }),
        }}
      />
    </div>
  );
};

const styles = createStyleSheet({
  container: {
    ...addFlex({ x: "center", y: "center", direction: "down" }),
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    justifyContent: "space-between", // so recaptcha notice is at the bottom
  },
  widthLimiter: {
    ...addSize({ width: { default: "100%", max: "800px" } }),
  },
  noResultHolder: {
    ...addFlex({ x: "center", y: "center", direction: "down" }),
    transform: "translateY(-50px)",
  },
  adminReportMainContent: {
    ...addFlex({ x: "left", y: "top", direction: "down" }),
    backgroundColor: "#fff",
    boxShadow: "0 4px 16px rgba(0,0,0,0.08)",
    borderRadius: "10px",
    padding: "20px",
    paddingTop: "30px",
    paddingBottom: "20px",
    marginTop: "-15px",
    marginBottom: "20px",
  },
  searchResultsRow: {
    ...addFlex({ x: "left", y: "top", direction: "down" }),
    marginBottom: "20px",
    width: "100%",
    justifyContent: "space-between",
  },
  searchResultsRowTitle: {
    fontWeight: "bold",
    fontSize: "16px",
    marginBottom: "5px",
  },
  searchResultsRowSearchItems: {
    ...addFlex({ x: "left", y: "top", direction: "right" }),
    flexWrap: "wrap",
  },
  searchResultsRowSearchTermHolder: { marginRight: "10px", marginTop: "5px" },
  numberRow: {
    ...addFlex({ x: "left", y: "center", direction: "right" }),
    marginBottom: "12px",
    width: "100%",
    justifyContent: "space-between",
  },
  numberRowTitle: { fontWeight: 500, fontSize: "16px" },
});

export default AdminReportContent;
