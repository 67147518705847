/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { useCallback, useRef } from "react";
import { shallow } from "zustand/shallow";

import {
  useScreenSizeStore,
  useClickedCapabilitiesStore,
  useModalSecondStore,
  useSearchResultsStore,
  useModalStore,
  useFacilityStore,
} from "stores";

import { addPadding } from "utils";

import {
  FeedbackType,
  OpenModalOptions,
} from "stores/utils/storeMakers/makeModalStore/types";

import SingleTerm from "./SingleTerm";
import MultiTerm from "./MultiTerm";
import { getUniqueCapabilities } from "./utils/getUniqueCapabilities";
import FormHeader from "components/SendableForm/FormHeader";
import useStackedModals from "stores/modals";
import { NextFormAction } from "../types";

type Props = {
  feedBackTypeText?: string;
  feedBackType?: FeedbackType;
  nextAction?: NextFormAction;
};

const SearchTermToTagForm: React.FC<Props> = ({ nextAction }) => {
  const { screenIs, isMobile, isDesktop } = useScreenSizeStore();
  const { replaceTopModal, closeTopModal } = useStackedModals();
  const theme = useTheme();

  const searchTerm = useSearchResultsStore((state: any) => state.searchTerm);

  const [getClickedCapabilities, resetClickedCapabilities] =
    useClickedCapabilitiesStore(
      ({ getClickedCapabilities, resetClickedCapabilities }) => [
        getClickedCapabilities,
        resetClickedCapabilities,
      ]
    );

  const facility = useFacilityStore((state: any) => state.facility);

  const { clickedEquipment, clickedServices } = getClickedCapabilities();

  // usign ref so missMatchedUniqueCapabilities doesn't change while the modal is open
  // (before the mismatches capabilities would disspaear while the modal was closing)
  const { current: localContext } = useRef({
    missMatchedUniqueCapabilities: {
      clickedEquipment: getUniqueCapabilities(clickedEquipment.missMatched),
      clickedServices: getUniqueCapabilities(clickedServices.missMatched),
    },
  });

  const openNextModalIfNeeded = useCallback(() => {
    // decide what modal to show next
    if (nextAction) {
      // has another action, show the next action modal
      switch (nextAction) {
        case "submitEnquiry":
          replaceTopModal({
            modalContentProps: {
              formName: "submitEnquiry",
            },
            modalContentName: "sendableForm",
            modalType: "popup",
          });
          break;
        case "questions":
          replaceTopModal({
            modalContentProps: { shouldShowThankyou: true },
            modalContentName: "questions",
            modalType: "popup",
          });
          break;
        default:
      }
    } else {
      closeTopModal();
    }
    // };
  }, [closeTopModal, nextAction, replaceTopModal]);

  const onSubmit = useCallback(() => {
    // Runs when pressing a submit button like "yes" or  "next"
    resetClickedCapabilities();
    openNextModalIfNeeded();
  }, [openNextModalIfNeeded, resetClickedCapabilities]);

  const onCancelOrNo = useCallback(() => {
    // Runs when pressing a no button like "note sure" or "no"
    resetClickedCapabilities();
    openNextModalIfNeeded();
  }, [openNextModalIfNeeded, resetClickedCapabilities]);

  return (
    <div
      css={{
        width: isDesktop ? "600px" : "100%",
        overflow: "auto",
        height: "100%",
      }}
    >
      <FormHeader>
        <h4>
          Does this listing match your search term{" "}
          <span css={{ color: theme.searchBarBorder, fontStyle: "italic" }}>
            {searchTerm}
          </span>
          ?
        </h4>
      </FormHeader>
      <div
        css={{
          width: isMobile ? "100%" : "600px",
          height: "auto",
          ...addPadding(
            screenIs({
              default: {
                top: "36px",
                bottom: "0",
                horizontal: "50px",
              },
              tablet: {
                top: "36px",
                bottom: "0",
                horizontal: "40px",
              },
              mobile: { top: "39px", horizontal: "20px", bottom: "0" },
            })
          ),
        }}
      >
        {[
          ...localContext.missMatchedUniqueCapabilities.clickedEquipment,
          ...localContext.missMatchedUniqueCapabilities.clickedServices,
        ].length <= 1 ? (
          <SingleTerm
            searchTerm={searchTerm}
            facility={facility}
            missMatchedCapabilities={localContext.missMatchedUniqueCapabilities}
            onSubmit={onSubmit}
            onCancelButton={onCancelOrNo}
          />
        ) : (
          <MultiTerm
            searchTerm={searchTerm}
            facility={facility}
            missMatchedCapabilities={localContext.missMatchedUniqueCapabilities}
            onSubmit={onSubmit}
            onCancelButton={onCancelOrNo}
          />
        )}
      </div>
    </div>
  );
};

export default SearchTermToTagForm;
